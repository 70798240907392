
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {DocumentFieldValue} from "@dex/squeeze-client-ts";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useRoute} from "vue-router";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "DocumentLocatorTestingTable",
	components: { DataTable, Column },
	props: {
		locatorValues: {
			type: Array as PropType<DocumentFieldValue[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onMarkWord',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t,
			toast,
			store,
			tableListPagination,
			onMarkWord,
			onChangePage,
		}
	},
});
