
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import Dropdown from "primevue/dropdown";
import {DocumentField, DocumentFieldGroup, DocumentLocator, LookupDefinition} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import DbLookupForm from "@/apps/administration/components/documentclasses/DbLookupForm.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {ClientManager} from "@/singletons/ClientManager";
import {technicalValidation} from "@/util/ValidationHelper";
import LookupFiltersView from "@/apps/administration/views/squeeze/documentclasses/LookupFiltersView.vue";
import {showDropdownOverlay} from "@/util/StylesHelper";
import AmountSeparatorsForm, {AmountSeparator} from "@/apps/administration/components/documentclasses/AmountSeparatorsForm.vue";
import useSqueezeStore from "@/apps/squeeze/store";

interface DataTypes {
	id: string | undefined;
	text: string;
}

export default defineComponent({
	name: "FieldForm",
	components: {
		AmountSeparatorsForm, Checkbox, InputText, InputNumber, Dropdown, DbLookupForm, TabView, TabPanel, LookupFiltersView,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
			required: true,
		},
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
			required: true,
		},
		fieldGroups: {
			type: Array as PropType<DocumentFieldGroup[]>,
			default: () => [],
			required: true,
		},
		tableField: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update', 'onTabChange'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const store = useSqueezeStore();

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Array for Locator-Subfield */
		const subFields = ref<DocumentField[] | null>([]);

		/** Current Object of all input-fields */
		const value = reactive<DocumentField>(props.fieldEntry);

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
			dataType: { required },
		}

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			] as DataTypes[]
		});

		/** List of Truncate-Types */
		const truncateTypes = computed(() => {
			return [
				{
					id: DocumentField.TruncateTypeEnum.None,
					text: t('Squeeze.DocumentClasses.DoNotCutOff'),
				},
				{
					id: DocumentField.TruncateTypeEnum.Start,
					text: t('Squeeze.DocumentClasses.CutOffStartOfField'),
				},
				{
					id: DocumentField.TruncateTypeEnum.End,
					text: t('Squeeze.DocumentClasses.CutOffEndOfField'),
				},
			];
		});

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value, { $scope: false });

		const onTabChange = (activeTabIndex: number) => {
			emit("onTabChange", activeTabIndex);
		}

		/**
		 * Returns the Translation for a subfield
		 * @param subFieldId Subfield-ID of the Subfield to translate
		 * @returns Translated Sub-Field or Subfield-Id if it can't be found
		 */
		const getSubFieldTranslation = (subFieldId: string) => {
			// FIXME: Currently the Translation is taken from i18n. Generally the correct name should be returned from the API

			if (t('Squeeze.Locators.SubFields.' + subFieldId)) {
				return t('Squeeze.Locators.SubFields.' + subFieldId)
			}

			return String(subFieldId)
		}

		/**
		 * Gets the Description of a locator
		 * @param locatorId Id of the Locator to get the Description from
		 * @return Type of the Locator
		 */
		const getTypeFromLocator = (locatorId: number) => {
			if (!props.locators) {
				return 0
			}
			const locator = props.locators.find(i => i.id === locatorId);
			if (!locator) {
				return 0
			}

			return locator.locatorType;
		}

		/**
		 * Sets the list of the Sub-Fields in correlation to the current locator
		 * @param  {number} locatorId Locator-ID of the current locator
		 */
		const setSubFieldList = (locatorId: number) => {
			const locatorType = getTypeFromLocator(locatorId);
			locatorService.getSupportedLocatorSubFields(String(locatorType)).then((data) => {
				data.forEach(subField => {
					if (subField.subFieldName) {
						subField.name = getSubFieldTranslation(subField.subFieldName)
					}
				})

				subFields.value = data
			})
		}

		onMounted(async () => {
			if (props.fieldEntry && props.fieldEntry.locatorId) {
				setSubFieldList(props.fieldEntry.locatorId)
			}

			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/**
		 * Triggered when a Locator is changed
		 * @param {number} locatorId Locator-ID of the current locator
		 */
		const onChangeLocator = (locatorId: number) => {
			setSubFieldList(locatorId)
		}

		/** Triggered on change of any field */
		const update = async (isInvalidLookup?: boolean) => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			if (isInvalidLookup) {
				emit("update", value, true, 1);
			} else{
				emit("update", value, v$.value.$invalid, 0);
			}
		}

		/** Triggered on change of any field lookup */
		const onUpdateFieldLookup = async (lookupField: LookupDefinition, isInvalid: boolean) => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			Object.assign(value.lookup, lookupField)
			await update(isInvalid);
		}

		/**
		 * Set the lookup to inactive if the Type is not text
		 */
		const onChangeDataType = () => {
			if (value.dataType !== "Text") {
				value.lookup!.active = false;
			}
			update();
		}

		/** Triggered on change of input field */
		const onChangeInput = () => {
			update();
		}

		/**
		 * Triggered on change of an amount separator
		 * @param data
		 */
		const onChangeAmountSeparators = (data: AmountSeparator) => {
			value.thousandSeparator = data.thousandSeparator;
			value.decimalSeparator = data.decimalSeparator;
			value.decimalPlaces = data.decimalPlaces;
			update();
		}

		/**
		 * Is triggered when a checkbox from mandatory, forceValidation or hidden is clicked
		 * @param fieldName Name of the field
		 */
		const onChangeCheckBox = (fieldName: string) => {
			switch(fieldName) {
			case 'mandatory':
			case 'forceValidation': {
				// If a field is mandatory or "force Validation", it shouldn't be hidden
				if (value.mandatory === true || value.forceValidation === true) {
					value.hidden = false;
				}
				break;
			}
			case 'hidden': {
				// If a field is hidden, forcing the Validation can cause a falsy behavior
				if (value.hidden) {
					value.mandatory = false;
					value.forceValidation = false;
				}
				break;
			}
			}
		}

		return {
			t,
			store,
			locatorService,
			v$,
			value,
			dataTypes,
			truncateTypes,
			subFields,
			onTabChange,
			onChangeLocator,
			setSubFieldList,
			getTypeFromLocator,
			getSubFieldTranslation,
			onChangeDataType,
			onChangeInput,
			onChangeAmountSeparators,
			onChangeCheckBox,
			update,
			onUpdateFieldLookup,
			showDropdownOverlay,
		}
	},
});
