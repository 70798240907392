/** Triggered when the dropdown overlay is shown and dropdown options have been supplied so that the dropdown options can be sorted alphabetically
 *  The sorting is done by the name
 *  @param options The current dropdown options
 */
export const sortDropdownOptions = function(options: any[]) {
	options.sort((a: any, b: any) => {
		return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
	});
}

/** Triggered when dropdown overlay is shown (use when dropdown filter is true)
 * @param dropdownOptions
 */
export const showDropdownOverlay = function(dropdownOptions?: any[]) {
	const dropdownWrapper = document.querySelector(".p-dropdown-panel .p-dropdown-items-wrapper");
	if (dropdownWrapper) {
		dropdownWrapper.scrollTop = 0;
	}

	// check if dropdown options are given, so that can be sorted
	if (dropdownOptions) {
		sortDropdownOptions(dropdownOptions);
	}
}

//region color picker

/**
 * Validate hex code
 * @param hex
 */
const validateHex = (hex: string) => {
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
	}

	return hex;
}

/**
 * Check Font Color
 * @param hex
 */
const checkFontColor = (hex: string) => {
	const rgb = parseInt(validateHex(hex), 16);   // convert rgb to decimal
	const r = (rgb >> 16) & 0xff;  // extract red
	const g = (rgb >> 8) & 0xff;  // extract green
	const b = (rgb >> 0) & 0xff;  // extract blue

	const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

	return luma > 164 ? 'rgba(0, 0, 0, 0.87)' : '#fff';
}

/**
 * Increase darkness of selected color
 * @param hex
 * @param lum
 */
const increaseDarkness = (hex: string, lum: number) => {
	lum = lum || 0;

	// convert to decimal and change luminosity
	let rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(validateHex(hex).substr(i * 2, 2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00" + c).substr(c.length);
	}
	return rgb;
};

const pad = (n: any) => {
	return (n < 10) ? ("0" + n) : n;
};

/**
 * Increase brightness of selected color
 * @param hex
 * @param lum
 */
const increaseBrightness = (hex: string, lum: number) => {
	// strip the leading # if it's there
	hex = hex.replace(/^\s*#|\s*$/g, '');

	// convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
	if (hex.length == 3) {
		hex = hex.replace(/(.)/g, '$1$1');
	}

	const r = parseInt(hex.substr(0, 2), 16),
		g = parseInt(hex.substr(2, 2), 16),
		b = parseInt(hex.substr(4, 2), 16);
	return '#' +
		pad(((0|(1<<8) + r + (256 - r) * lum).toString(16)).substr(1)) +
		pad(((0|(1<<8) + g + (256 - g) * lum).toString(16)).substr(1)) +
		pad(((0|(1<<8) + b + (256 - b) * lum).toString(16)).substr(1));
};

/**
 * Change the current Palette of current main color
 * Create new shades of selected color
 * @param color
 */
export const changePalette = function(color: string) {
	const numOfShades = 4;
	let shades = [] as any;

	for (let colorIndex = 0; colorIndex < 1; colorIndex++) {
		shades = [];
		const delta = 1.8 / numOfShades;
		let lum = -0.9;

		for (let i = 0; i < numOfShades; i++) {
			if (lum < 0.1) {
				shades.push(increaseDarkness(color, lum));
			} else {
				shades.push(increaseBrightness(color, lum));
			}
			lum += delta;
		}
		shades.push(shades);
	}

	document.documentElement.style.setProperty("--primary-color", color);
	document.documentElement.style.setProperty("--dex-primary-dark-color", shades[0]);
	document.documentElement.style.setProperty("--dex-primary-color", shades[1]);
	document.documentElement.style.setProperty("--dex-primary-light-color", shades[2]);
	document.documentElement.style.setProperty("--dex-secondary-dark-color", shades[3]);

	// set text color
	document.documentElement.style.setProperty("--dex-text-color", checkFontColor(color));
}

// endregion
