import {RouteRecordRaw} from "vue-router";
import App from "../App.vue";
import SetupFormView from "@/apps/system-administration/views/SetupFormView.vue";
/*import ClientListView from "@/apps/system-administration/views/ClientListView.vue";
import ClientLogin from "@/apps/system-administration/views/ClientLogin.vue";*/
import ClientProcessView from "@/apps/system-administration/views/ClientProcessView.vue";
/**
 * @param pathBase URL path that defines this app.
 */
export function createRoutes(pathBase: string): Array<RouteRecordRaw> {
	return [
		{
			path: pathBase,
			name: "System-Administration",
			component: App,
			meta: {
				requiresAuth: false,
			},
			children: [
				/*{
					path: 'client-login',
					name: "ClientLogin",
					component: ClientLogin,
				},
				{
					path: 'client-list',
					name: "ClientListView",
					component: ClientListView,
				},*/
				{
					path: "",
					name: "SetupFormView",
					component: SetupFormView,
					meta: {
						requiresAuth: false,
					},
				},
				{
					path: 'createTenant/:tenant',
					name: "ClientProcessView",
					component: ClientProcessView,
					props: function(route) {
						return {
							tenant: route.params.tenant,
							tenantExists: Boolean(route.params.tenantExists),
						};
					},
					meta: {
						requiresAuth: false,
					},
				},
			],
		},
	];
}
