
import {defineComponent, computed} from "vue";
import TabMenu from "@/components/DexTabMenu.vue";
import {useI18n} from "vue-i18n";
import {MenuItem} from "@/shims-prime-vue";

export default defineComponent({
	name: "BatchClassView",
	components: {
		TabMenu,
	},
	props: {
		batchClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.BatchClasses.General'), to: { name: 'BatchClassSubView', params: { batchClassId: props.batchClassId }}},
			]

			if (props.batchClassId) {
				itemsList.push({
					label: 'OCR',
					to: { name: 'OcrConfigurationInterfaceView', params: { batchClassId: props.batchClassId }}})

				itemsList.push({
					label: t('Squeeze.BatchClasses.Classifications'),
					to: { name: 'ClassificationListView', params: { batchClassId: props.batchClassId }}})

				itemsList.push({
					label: t('Squeeze.BatchClasses.Attributes'),
					to: { name: 'BatchClassAttributesView', params: { batchClassId: props.batchClassId }}})
			}

			return itemsList;
		});

		return {
			TabMenu,items,
		}
	},
});
