
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import Dropdown from "primevue/dropdown";
import FileUpload from "@/components/DexFileUpload.vue";
import {BatchClass, BatchClassClassification} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

// TODO: This is a fix for https://github.com/swagger-api/swagger-codegen/issues/6403
// Remove if fixed
import * as url from "url";
url.URLSearchParams = URLSearchParams;

export default defineComponent({
	name: "DocumentUpload",
	components: {
		Dropdown,
		FileUpload,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** List of all Batch-Class */
		const batchClasses = ref<BatchClass[]>([]);

		/** List of all Classifications of BatchClass */
		const classifications = ref<BatchClassClassification[]>([]);

		/** Currently active Batch-Class */
		const activeBatchClass = ref<number>(0);

		/** Currently active Document-Class */
		const activeDocumentClass = ref<number>(0);

		/** Message that shows the number of the currently uploaded documents  */
		const uploadLabel = ref<string>("");

		/** List of all files to be uploaded */
		const files = ref<any[]>([{
			uploadFinished: false,
			loading: false,
			errorText: '',
			error: false,
		}]);

		/** Current Progress of upload */
		const progress = ref<number>(0);

		/** Show Loading on load batchClass-data */
		const loadingBatchClasses = ref<boolean>(false);

		/** Show Loading on load classification-data */
		const loadingClassifications = ref<boolean>(false);

		/** Service for Batch-Classes */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** Uploads the files from the file-uploader */
		const getBatchClassClassifications = () => {
			loadingClassifications.value = true;
			batchClassService.getBatchClassClassifications(activeBatchClass.value)
				.then(data => {
					classifications.value = data;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})
				.finally(() => {
					loadingClassifications.value = false;
				});
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: File) => {
			// Todo: Once the generated client works, make this function deprecated and use the client function:
			// const result = this.documentService.processDocument(this.activeBatchClass,1, "0", fileName);

			const body = new FormData();
			body.set("batchClassId", String(activeBatchClass.value));
			if (activeDocumentClass.value) {
				body.set("documentClassId", String(activeDocumentClass.value));
			} else {
				body.set("documentClassId", "");
			}
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/documents", {
				method: "POST",
				body: body,
			});

			const data = await response.json();

			if (response.status !== 200) {
				throw new Error(data.message);
			}

			return {
				id: data.id,
			}
		}

		/** Uploads the files from the file-uploader */
		const fileUploader = (event: any) => {
			files.value = event.files;

			progress.value = 0;
			// Calculate progress
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";

			// TODO: Limit the amount of uploads running in parallel (?)
			event.files
				.forEach((file: any, index: number) => {
					if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
						const idx = index;
						files.value[idx].error = false;
						files.value[idx].errorText = "";
						files.value[idx].loading = true;
						files.value = [...files.value];

						manualFileUpload(file)
							.then(data => {
								files.value[idx].uploadFinished = true;
							})
							.catch(err => {
								files.value[idx].error = true;
								files.value[idx].errorText = err.message;
							})
							.finally(() => {
								files.value[idx].loading = false;
								files.value = [...files.value];

								// Calculate progress
								const finished = files.value.filter(file => file.uploadFinished);
								progress.value = Math.round((finished.length * 100) / files.value.length);
								uploadLabel.value = t("Squeeze.General.Upload") + " (" + finished.length + "/" + files.value.length + ")";
							});
					}
				})
		}

		/**
		 * Is triggered when files are selected from the Component
		 * @param event File-Select event
		 */
		const onSelectFiles = (event: any) => {
			files.value= event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/** Is triggered when the "clear" button is pressed in the Upload-Component */
		const clearFiles = () => {
			uploadLabel.value = t("Squeeze.General.Upload");
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		onMounted(() =>{
			loadingBatchClasses.value = true;

			// Get all Batch-Classes
			batchClassService.getAllBatchClasses()
				.then(data => {
					batchClasses.value = data;

					// Pre-Select first valid entry
					if (data[0] && data[0].id) {
						activeBatchClass.value = data[0].id;
					}
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				})
				.finally(() => {
					loadingBatchClasses.value = false;
					getBatchClassClassifications();
				});
		})

		return {
			t,
			toast,
			batchClasses,
			classifications,
			activeBatchClass,
			activeDocumentClass,
			uploadLabel,
			files,
			progress,
			loadingBatchClasses,
			loadingClassifications,
			getBatchClassClassifications,
			fileUploader,
			manualFileUpload,
			onSelectFiles,
			clearFiles,
			removeFile,
		};
	},
});

