
import Message from 'primevue/message';
import Panel from 'primevue/panel';
import { defineComponent } from "vue";

export default defineComponent({
	name: "Home",
	components: {
		Panel, Message,
	},
	props: {
		apps: {
			type: Array,
		},
	},
	computed: {
		showDevDisclaimer() {
			return false;
		},
	},
})

