
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import {BatchClass, BatchClassClassification, FileImportConfig} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";

export default defineComponent({
	name: "FileSystemForm",
	components: {
		InputText, Dropdown,
	},
	props: {
		file: {
			type: Object as PropType<FileImportConfig>,
			required: true,
		},
		batchClassId: {
			type: Number,
			default: 1,
			required: true,
		},
		batchClasses: {
			type: Array as PropType<BatchClass[]>,
			default: () => [],
			required: true,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: () => [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Array with the indexFileType */
		const indexFileType = computed(() => {
			return [
				{
					id: 'SCM',
					description: 'SCM',
				},
				{
					id: 'Workday EIB XML',
					description: 'Workday EIB XML',
				},
			]
		});

		/** Array with the imageFileExtension */
		const imageFileExtension = computed(() => {
			return [
				{
					id: 'TIFF',
					description: 'TIFF',
				},
				{
					id: 'PDF',
					description: 'PDF',
				},
			]
		});

		/** List of active booleans */
		const active = computed(() => {
			return [
				{
					id: true,
					name: t('Squeeze.General.Yes'),
				},
				{
					id: false,
					name: t('Squeeze.General.No'),
				},
			]
		});

		/** Current Object of all input-fields */
		const value = reactive<FileImportConfig>({
			batchClassId: props.batchClassId,
			documentClassId: undefined,
			active: true,
			path: '',
			indexFileType: FileImportConfig.IndexFileTypeEnum.SCM,
			indexFileExtension: '',
			signalFileName: '',
			imageFileExtension: FileImportConfig.ImageFileExtensionEnum.TIFF,
		});

		/** Determines the required rules for validation */
		const rules = {
			path: { required },
			indexFileExtension: { required },
			signalFileName: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.file);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.file, () => {
			Object.assign(value, props.file);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			loading,
			indexFileType,
			imageFileExtension,
			active,
			value,
			props,
			v$,
			InputText,
			Dropdown,
			update,
		}
	},
});

