
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {MailZipPassword} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import PasswordList from "@/apps/administration/components/imports/PasswordList.vue";
import PasswordForm from "@/apps/administration/components/imports/PasswordForm.vue";

export default defineComponent({
	name: "PasswordListView",
	components: {
		DialogDelete,
		EntryDialog,
		BlockUI,
		PasswordList,
		PasswordForm,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref<boolean>(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Api-Clients */
		const apiClients = {
			importApi: ClientManager.getInstance().squeeze.import,
		};

		/** Component of the current form */
		const passwordForm = ref<InstanceType<typeof PasswordForm>>();

		/** Should the Entry-Dialog for passwords be shown? */
		const showDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** All ZIP Passwords */
		const allZipPasswords = ref<MailZipPassword[]>([]);

		/** Currently password */
		const password = reactive<MailZipPassword>({
			id: undefined,
			email: '',
			password: '',
		})

		/** Is the Form of the password invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (password.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Login.Password') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Login.Password') });
		});

		/** Get all ZIP Passwords */
		const getAllZipPasswords = () => {
			apiClients.importApi.getAllZipPasswords()
				.then((data: MailZipPassword[]) => {
					allZipPasswords.value = data;
				})
				.catch((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				});
		}

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: MailZipPassword) => {
			Object.assign(password, row);
			showDialog.value = true;
		}

		/**
		 * Opens the delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MailZipPassword) => {
			Object.assign(password, row);
			deleteDialog.value = true;
		}

		onMounted(() =>{
			getAllZipPasswords();
		});

		/** Deletes a (email ZIP) password */
		const deleteEntry = () => {
			if (password && password.id) {
				loading.value = true;
				apiClients.importApi.deleteZipPasswordById(password.id)
					.then(() => {
						getAllZipPasswords();
					}).catch(response => response.json().then ((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					})).finally(() => {
						loading.value = false;
					})
			}
		}

		/**
		 * Saves a password
		 * @param keepDialogOpen
		 */
		const savePassword = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let passwordChangePromise = null;

			if (!password.id) {
				passwordChangePromise = apiClients.importApi.postZipPassword(password);
			} else {
				passwordChangePromise = apiClients.importApi.putZipPassword(password.id, password);
			}

			passwordChangePromise.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					password.id = undefined;
					password.email = '';
					password.password = '';

					passwordForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}
				getAllZipPasswords();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Triggered on update of attribute-form
		 * @param data
		 * @param isInvalid
		 */
		const onUpdate = (data: MailZipPassword, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(password, data);
		}

		return {
			t,
			toast,
			loading,
			loadingDialog,
			apiClients,
			passwordForm,
			showDialog,
			deleteDialog,
			allZipPasswords,
			password,
			isValidationInvalid,
			showValidationMessage,
			headerText,
			onEntrySelect,
			openDeleteDialog,
			deleteEntry,
			savePassword,
			onUpdate,
		}
	},
})
