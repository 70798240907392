export class BFFClient {

	/** Defines the default route to Freeze */
	public baseURL: string = "";

	public constructor() {
		this.baseURL = "/apis/bff/v0";
	}

	public async getMe(): Promise<unknown> {
		const response = await fetch(`${this.baseURL}/me`);

		if (response.status !== 200) {
			throw await response.text();
		}

		return await response.json();
	}

	public async isLoggedIn(): Promise<boolean> {
		const response = await fetch(`${this.baseURL}/me`);

		if (response.status == 401) {
			return false;
		} else if (response.status == 200) {
			return true;
		} else {
			throw await response.text();
		}
	}

	private static instance: BFFClient;

	public static getInstance() {
		if(this.instance == null) {
			this.instance = new BFFClient();
		}

		return this.instance;
	}

}

export const BFF = BFFClient.getInstance();
