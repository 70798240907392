
import {defineComponent, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import Divider from 'primevue/divider';
import Message from "primevue/message";

export default defineComponent({
	name: "ProcessItemSummary",
	components: {
		Message,
		Divider,
	},
	props: {
		tenant: {
			type: String,
		},
		tenantExists: {
			type: Boolean,
			default: false,
		},
		adminUser: {
			type: Object,
			required: true,
		},
		allTemplates: {
			type: Array,
		},
	},
	emits: ['goToPreviousOrNextStep'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Is loading? */
		const loading = ref<boolean>(false);

		/** Triggered on click to go to the previous step */
		const goToPreviousStep = () => {
			emit('goToPreviousOrNextStep', 1);
		}

		/** Triggered on click to go to the next step */
		const goToNextStep = () => {
			emit('goToPreviousOrNextStep', 3);
		}

		return {
			t,
			loading,
			goToPreviousStep,
			goToNextStep,
		}
	},
})
