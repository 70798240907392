
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive, ref} from "vue";
import {DocumentField, XmlHeadFieldTraining} from "@dex/squeeze-client-ts";
import {DocumentClass} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode, FilterService} from "primevue/api";
import InputText from "primevue/inputtext";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Dropdown from "primevue/dropdown";
import {copyToClipboard, downloadData} from "@/util/Export";

export default defineComponent({
	name: "XmlMapperHeadList",
	methods: {copyToClipboard, downloadData},
	components: {
		DataTable, Column, InputText, Dropdown,
	},
	props: {
		mappingFields: {
			type: Array as PropType<XmlHeadFieldTraining[]>,
			default: () => [],
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "openTraining", "openTesting", "changeShowGlobalMapping",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Sets the default sorting for the table (currently deactivated) */
		const multiSortMeta = ref<any[]>([{field: "company", order: -1}, {field: "creditor", order: -1}, {field: "documentclassid", order: -1}, {field: "readonly", order: 1}]);

		/** Should the button for hiding global Mappings be shown? */
		const showGlobalEntries = ref<boolean>(true);

		/**
		 * Custom Filter for Document-Class. Needed because of different types in table and documentclass-data
		 */
		FilterService.register('customWildCardFilter', (rowValue, selectedValue) => {
			if (String(rowValue) === "*") {
				return true;
			}

			if (!selectedValue) {
				return true;
			}
			return rowValue.includes(selectedValue);
		});


		/**
		 * Custom Filter for Document-Class. Needed because of different types in table and documentclass-data
		 */
		FilterService.register('customFilterDocumentClass', (rowValue, selectedValue) => {
			if (String(rowValue) === "*") {
				return true;
			}

			if (!selectedValue) {
				return true;
			}

			if (String(rowValue) === String(selectedValue)) {
				return true;
			}

			return false;
		});


		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"company": {value: null, matchMode: "customWildCardFilter"},
				"creditor": {value: null, matchMode: "customWildCardFilter"},
				"documentclassid": {value: null, matchMode: "customFilterDocumentClass"},
				"fieldName": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"xpath": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: XmlHeadFieldTraining) => {
			emit("onClickDelete", row);
		}

		/**
		 * Opens the Edit-Dialog
		 * @param {BatchClass} data Data to edit
		 */
		const openEditDialog = (data: XmlHeadFieldTraining) => {
			/**
			 * The model for XmlHeadFieldTraining expects a string for the document class ID,
			 * but the document class ID is normally a number
			 */
			if (data.documentclassid === "*") {
				data.documentclassid = 0 as any;
			} else {
				data.documentclassid = Number(data.documentclassid) as any;
			}
			emit("onEntrySelect", data, true)
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {fieldName: '', documentclassid: 0, creditor: '', readonly: false, xpath: '/', company: '', id: undefined})
		}

		/**
		 * Get the description of a field
		 * @param row
		 */
		const getFieldDescription = (row: XmlHeadFieldTraining) => {
			if (props.documentClassFields) {
				const documentClassFields = props.documentClassFields.filter(field => field.name === row.fieldName);

				if (documentClassFields.length > 0) {
					return row.fieldName + " (" + documentClassFields[0].description + ")";
				}
			}

			return row.fieldName;
		}

		/**
		 * Get the description of a field
		 * @param row
		 */
		const getFieldDescriptionTooltip = (row: XmlHeadFieldTraining) => {
			let fieldDescriptions = row.fieldName;
			if (props.documentClassFields) {
				const documentClassFields = props.documentClassFields.filter(field => field.name === row.fieldName);
				fieldDescriptions = "";
				documentClassFields.forEach(field => {
					const docClass = props.documentClasses!.find(docClass => String(docClass.id) === String(field.documentClassId));
					if (docClass) {
						fieldDescriptions += docClass.description + ": " + field.description! + "\n";
					}
				})
			}

			return fieldDescriptions;
		}

		/**
		 * Get the description of a field (for the dropdown)
		 * @param row
		 */
		const getFieldDescriptionDropDown = (row: any) => {
			if (props.documentClassFields) {
				const documentClassField = props.documentClassFields.find(field => field.name === row.name);

				if (documentClassField) {
					return row.name + " (" + documentClassField.description + ")";
				}
			}

			return row.name;
		}

		/** Get the description of a documentClass instead of number */
		const getDescription = (row: XmlHeadFieldTraining) => {
			if (String(row.documentclassid) === '0') {
				return  "*";
			}

			if (props.documentClasses) {
				const docClass = props.documentClasses.find(docClass => String(docClass.id) === String(row.documentclassid) ||
					(docClass.id === 0 && String(row.documentclassid) === '*'));
				if (docClass) {
					return docClass.description
				}
			}

			return row.documentclassid
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/**
		 * Triggered when the show global Button is clicked
		 * @param showGlobalMapping
		 */
		const onClickShowGlobal = (showGlobalMapping: boolean) => {
			showGlobalEntries.value = !showGlobalEntries.value;
			emit("changeShowGlobalMapping", showGlobalMapping);
		}

		initFilters();

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			if (props.mappingFields) {
				showBtnClearFilter.value = event.filteredValue.length < props.mappingFields.length;
			}
		}

		/**
		 * Tries to create an printable array from an x-path string
		 * @param xPath
		 */
		const createPrintableXpath = (xPath: string) => {
			let indentation = 0;
			const parts = xPath.split('/').filter(Boolean);

			const xPaths = [] as any;

			parts.forEach(part => {
				const xPathFormat = {
					indentation: 0,
					xPathPart: '',
				}

				xPathFormat.xPathPart = "/" + part;
				xPathFormat.indentation = indentation;

				// Increase indentation for nested elements
				indentation += 1;
				xPaths.push(xPathFormat);
			});

			return xPaths;
		}

		return {
			DataTable, Column, store, tableListPagination, filters, multiSortMeta, showGlobalEntries,
			openDeleteDialog, onNewEntryClick, getDescription, onChangePage, openEditDialog,
			getFieldDescription, onFilter, initFilters, showDropdownOverlay,
			getFieldDescriptionDropDown, onClickShowGlobal, createPrintableXpath, getFieldDescriptionTooltip,
		}
	},
});
