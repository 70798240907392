import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import {LicenseClient} from "@/apis/dexp/LicenseClient";
import {FreezeClient} from "@/apis/freeze/FreezeClient";
import {AuthTypes, ClientManager} from "@/singletons/ClientManager";

export interface RootState {
	squeeze: boolean;
	freeze: boolean;
}

const storeDefault = () => {
	return {
		squeeze: true,
		freeze: false,
	}
}

export const productStore = createStore<RootState>({
	state() {
		return storeDefault();
	},
	mutations: {
		setSqueeze(state, active: boolean) {
			state.squeeze = active;
		},
		setFreeze(state, active: boolean) {
			state.freeze = active;
		},
	},
	actions: {
		async fetchProducts({commit}) {
			commit('setSqueeze', true);

			// Check active products. Currently only necessary for Freeze
			const clientManager = ClientManager.getInstance();
			const licenseClient = LicenseClient.getInstance();
			const freezeClient = FreezeClient.getInstance();

			if (clientManager.login.activeAuth === AuthTypes.Bearer) {
				try{
					const licences =  await licenseClient.getAllLicences();
					const freezeSystems = licences.apps.freeze;
					if (freezeSystems[0]) {
						const firstFreezeSystem = freezeSystems[0];
						if (firstFreezeSystem.openApi) {
							if (process.env.NODE_ENV === "development") {
								firstFreezeSystem.openApi = "http://squeeze.docker.localhost:8082/apis/freeze/v0"
							}
							const baseURL = firstFreezeSystem.openApi.split("/apis/freeze/")[0];
							if (baseURL) {
								commit('setFreeze', true);
								freezeClient.freezeUrl = baseURL + "/apis/freeze";
							}
						}
					}
				}
				catch(e) {
					// If no license could be loaded, just show a short notification in the console, since it'll most likely appear when there is no license service
					// eslint-disable-next-line no-console
					console.log("The license information could not be loaded");
				}
			}
		},
	},
	modules: {
	},
})

export const productStoreKey: InjectionKey<Store<RootState>> = Symbol()

// define your own `useStore` composition function
export function useProductStore() {
	return baseUseStore(productStoreKey)
}

export default useProductStore
