
import {defineComponent, onMounted, PropType, reactive, ref, watch} from 'vue';
import EmailForm from "@/components/EmailForm.vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import SplitButton from "primevue/splitbutton";
import DialogDelete from "@/components/DialogDelete.vue";
import {DocumentField, FileDto, MailDto, MailTemplate} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {useVuelidate} from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";
import {required} from "@vuelidate/validators";

export default defineComponent({
	name: "ValidationEmailView",
	components: {
		EmailForm,
		InputText,
		Checkbox,
		SplitButton,
		DialogDelete,
	},
	props: {
		/** ID of Currently active Document */
		documentId: {
			type: Number,
			default: 0,
		},
		/** Fields of document */
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
	},
	emits: ['sendSuccess'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		const value = ref<string>("");

		/** Show loading in Send-Button? */
		const loading = ref<boolean>(false);

		/** Show loading in Save Template Button? */
		const loadingSaveTemplate = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** List of document-files */
		const files = ref<FileDto[]>([]);

		/** List of files to send */
		const activatedFiles = ref<FileDto[]>([]);

		/** List of Email Templates */
		const mailTemplates = ref<MailTemplate[]>([]);

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Document API endpoint */
		const emailApi = ClientManager.getInstance().squeeze.email;

		/** Mail-Body To send */
		const mailBody = reactive<MailDto>({
			body: '',
			attachmentIds: [],
			contentType: 'html',
			to: '',
			subject: '',
		});

		/** Determines the required rules for validation */
		const rules = {
			to: {
				required,
				checkEmail(email: unknown) {
					if (typeof email === 'undefined' || email == null || email === '') {
						return true
					}

					if ((email as string).includes(';') || (email as string).includes(',')) {
						// multiple email validation
						// the standard email validation does not work with multiple email addresses
						const regex = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
						const result = (email as string).replace(/\s/g, "").split(/,|;/);
						result.forEach(e => {
							if(!regex.test(e)) {
								return false;
							}
						})
						return true;
					} else {
						// single email validation
						return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email as string);
					}
				},
			},
			subject: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, mailBody);

		/** Mail-Body where the content has never been replaced */
		const mailBodyUnmodified = reactive<MailDto>({
			body: '',
			attachmentIds: [],
			contentType: 'html',
			to: '',
			subject: '',
		});

		const activeMailTemplate = reactive<MailTemplate>({
			description: '',
			subject: '',
			body: '',
			module: 'Validation',
			language: 'de',
		});

		/** Options of the toolbar. Due to the translations, those are defined in "mounted()" */
		const toolbarOptions = ref<any[]>([]);

		/** Label of the Split-Button */
		const labelSplitButton = ref<string>("");

		/** Should the Button for Edit Template be shown? */
		const showEditTemplate = ref<boolean>(false);

		/** Should the Button for Delete Template be shown? */
		const showDeleteTemplate = ref<boolean>(false);

		/** Should the Button for Save Template be shown? */
		const showSaveTemplate = ref<boolean>(false);

		/** Should the Template Description be shown? */
		const showDescription = ref<boolean>(false);

		/** Should the Dialog for Delete Template be shown? */
		const showDeleteTemplateDialog = ref<boolean>(false);

		/** Set values to false */
		const setValuesToFalse = () => {
			showDescription.value = false;
			showEditTemplate.value = false;
			showSaveTemplate.value = false;
			showDeleteTemplate.value = false;
		}

		/** Get Email-Templates */
		const getEmailTemplates = () => {
			emailApi.getEmailTemplates("Validation")
				.then(data => {
					mailTemplates.value = data;
					toolbarOptions.value = [];

					const toolbarNewEntry = {
						label: t("Squeeze.Validation.Email.NewTemplate"),
						icon: 'mdi mdi-plus',
						command: () => {
							if (activeMailTemplate) {
								activeMailTemplate.id = undefined;
								activeMailTemplate.module = 'Validation';
								activeMailTemplate.language = 'de';
							} else {
								Object.assign(activeMailTemplate, {
									description: '',
									subject: '',
									body: '',
									module: 'Validation',
									language: 'de',
								});
							}

							showDescription.value = true;
							showEditTemplate.value = false;
							showSaveTemplate.value = true;
							showDeleteTemplate.value = false;
							labelSplitButton.value = t("Squeeze.Validation.Email.NewTemplate");
						},
					}
					if (toolbarOptions.value) {
						toolbarOptions.value.push(toolbarNewEntry);
					}

					mailTemplates.value.forEach(template => {
						const toolbarEntry = {
							label: template.description,
							command: () => {
								Object.assign(activeMailTemplate, template);
								labelSplitButton.value = template.description;
								showEditTemplate.value = true;
								showDescription.value = false;
								showSaveTemplate.value = false;
								showDeleteTemplate.value = true;
							},
						}

						if (toolbarOptions.value) {
							toolbarOptions.value.push(toolbarEntry);
						}
					})
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Sends an Email */
		const sendMail = () => {
			if (v$.value.$invalid) {
				showErrorMessage.value = true;
				return;
			}
			showErrorMessage.value = false;
			loading.value = true;

			let body = mailBody.body!;
			// replace paragraph of text with breakpoints
			body = body.replaceAll('<p><br></p>', '<br>');
			body = body.replaceAll('<p>', '');
			body = body.replaceAll('</p>', '<br>');
			mailBody.body = body;

			documentApi.sendDocumentAsEmail(props.documentId, mailBody)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Email.SendSuccess'));
					emit("sendSuccess");
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Is triggered when the form-value changes
		 * @param {string} formValue
		 */
		const onChangeForm = (formValue: string) => {
			if (mailBody) {
				mailBody.body = formValue;
			}
		}

		/** Triggered when the Edit-Template-Button is clicked */
		const editTemplate = () => {
			showDescription.value = true;
			showEditTemplate.value = false;
			showSaveTemplate.value = true;
			showDeleteTemplate.value = true;

			mailBody.body = mailBodyUnmodified.body;
			mailBody.subject = mailBodyUnmodified.subject;
		}

		/** Shows the delete template Dialog */
		const openDeleteTemplateDialog = () => {
			showDeleteTemplateDialog.value = true;
		}

		/** Replaces the template-data with the data from the document */
		const replaceTemplateData = () => {
			if (!mailBody) {
				return;
			}

			let subject = mailBody.subject!;
			let body = mailBody.body!;
			props.documentFields.forEach((field: DocumentField) => {
				subject = subject.replaceAll("#" + field.name + "#", field.value?.value as string);
				body = body.replaceAll("#" + field.name + "#", field.value?.value as string);
			})

			mailBody.subject = subject;
			mailBody.body = body;
		}

		/** Saves an Email Template */
		const saveTemplate = () => {
			if (activeMailTemplate && mailBody) {
				activeMailTemplate.body = mailBody.body;
				activeMailTemplate.subject = mailBody.subject;
			}

			if (activeMailTemplate && activeMailTemplate.id) {
				loadingSaveTemplate.value = true;
				emailApi.putEmailTemplate(activeMailTemplate.id, activeMailTemplate)
					.then(() => {
						loadingSaveTemplate.value = false;
						setValuesToFalse();
						labelSplitButton.value = t("Squeeze.Validation.Email.Templates");
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Email.SaveTemplateSuccess'));
						getEmailTemplates();
						replaceTemplateData();
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						loadingSaveTemplate.value = false;
					}))
			} else if (activeMailTemplate) {
				loadingSaveTemplate.value = true;
				emailApi.postEmailTemplate(activeMailTemplate)
					.then(() => {
						loadingSaveTemplate.value = false;
						setValuesToFalse();
						labelSplitButton.value = t("Squeeze.Validation.Email.Templates");
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Validation.Email.SaveTemplateSuccess'));
						getEmailTemplates();
						replaceTemplateData();
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						loadingSaveTemplate.value = false;
					}))
			}
		}

		/** Deletes a Template */
		const deleteTemplate = () => {
			if (activeMailTemplate && activeMailTemplate.id) {
				emailApi.deleteEmailTemplate(activeMailTemplate.id)
					.then(() => {
						showDeleteTemplateDialog.value = false;
						setValuesToFalse();
						labelSplitButton.value = t("Squeeze.Validation.Email.Templates");
						getEmailTemplates();
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						loadingSaveTemplate.value = false;
					}))
			}
		}

		watch(activatedFiles, () => {
			// Set attachmentIds to activated files
			mailBody.attachmentIds = [];
			activatedFiles.value.forEach((file: FileDto) => {
				if (file.id) {
					mailBody.attachmentIds!.push(file.id);
				}
			});
		})

		watch(activeMailTemplate, (newValue) => {
			if (mailBody) {
				mailBody.subject = newValue.subject;
				mailBody.body = newValue.body;
				mailBodyUnmodified.subject = newValue.subject;
				mailBodyUnmodified.body = newValue.body;

				replaceTemplateData();
			}
		})

		onMounted(() => {
			labelSplitButton.value = t('Squeeze.Validation.Email.Templates');

			documentApi.getDocumentAttachments(props.documentId, false)
				.then(data => {
					files.value = data;

					// Set all checkboxes for a file to true if it is an original document
					files.value.forEach(file => {
						if (file.id && file.isOriginFile) {
							activatedFiles.value.push(file);
							mailBody.attachmentIds!.push(file.id);
						}
					})
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))

			getEmailTemplates();
		})

		return {
			t,
			toast,
			value,
			loading,
			loadingSaveTemplate,
			showErrorMessage,
			files,
			activatedFiles,
			mailTemplates,
			v$,
			rules,
			mailBody,
			mailBodyUnmodified,
			activeMailTemplate,
			toolbarOptions,
			labelSplitButton,
			showEditTemplate,
			showDeleteTemplate,
			showSaveTemplate,
			showDescription,
			showDeleteTemplateDialog,
			setValuesToFalse,
			getEmailTemplates,
			sendMail,
			onChangeForm,
			editTemplate,
			openDeleteTemplateDialog,
			replaceTemplateData,
			saveTemplate,
			deleteTemplate,
		};
	},
});

