
import {defineComponent, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import SystemCsvTranslationImport from "@/apps/administration/components/system/SystemImport.vue";
import SystemCsvTranslationExport from "@/apps/administration/components/system/SystemCsvTranslationExport.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { UploadFile } from "@/shims-prime-vue";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "SystemCsvTranslationView",
	components: {
		SystemCsvTranslationImport,
		SystemCsvTranslationExport,
		EntryDialog,
		Dialog,
		Message,
		TabPanel,
		TabView,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Current active tab option */
		const activeTabOption = ref(0);

		/** All tab options of a file */
		const tabOptions = ref([
			{icon: 'mdi mdi-file-import-outline', value: t('Squeeze.General.Import')},
			{icon: 'mdi mdi-file-export-outline', value: t('Squeeze.General.Export')},
		]);

		/** List of all files */
		const files = ref<UploadFile[]>([]);

		/** Used CSV-Separator */
		const csvSeparator = ref(';');

		/** Skip error lines? */
		const skipError = ref<boolean>(false);

		/** List with Available File-Types */
		const fileTypes = [
			{
				id: 'csv',
				description: t('Squeeze.System.CSV'),
			},
		];

		/** Used File-Type for Upload */
		const fileType = ref('csv');

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Should the reset dialog be shown? */
		const showWarningDialog = ref<boolean>(false);

		/** Should the Import Error Dialog be shown? */
		const showImportErrors = ref<boolean>(false);

		/** List of errors of import */
		const importErrors = ref<string[]>([]);

		/** Count of errors */
		const errorCount = ref<number>(0);

		/** Api for translations */
		const translationsApi = ClientManager.getInstance().squeeze.translations;

		/**
		 * Triggered on update of translation country-form
		 * @param data
		 * @param isInvalid
		 */
		const onUpdate = (data: any, isInvalid: boolean) => {
			showErrorMessage.value = isInvalid;
			csvSeparator.value = data.csvSeparator;
			skipError.value = data.skipErrors;
			fileType.value = data.fileType;
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @param fileType
		 * @param csvSeparator
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: UploadFile, fileType: string, csvSeparator: string) => {
			const body = new FormData();
			body.set("file", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() +
				"/translations/import?format=" + fileType + "&csv-separator=" + encodeURIComponent(csvSeparator) + "&skipErrors=" + skipError.value, {
				method: "POST",
				body: body,
			});

			if (response.status !== 200 && response.status !== 204) {
				const responseJSON = await response.json();
				throw new Error("Unexpected status " + responseJSON.message);
			} else {
				const responseJSON = await response.json();
				if (responseJSON.errorLines.length > 0) {
					importErrors.value = responseJSON.errorLines;
					errorCount.value =  responseJSON.errorLineAmount;
					showImportErrors.value = true;
				}
			}
		}

		/**
		 * Starts the File upload for the given files
		 */
		const startFileUpload = () => {
			showWarningDialog.value = false;
			files.value
				.forEach((file: any, index: number) => {
					const idx = index;
					files.value[idx].error = false;
					files.value[idx].errorText = "";
					files.value[idx].loading = true;
					files.value = [...files.value];

					manualFileUpload(file, fileType.value, csvSeparator.value)
						.then(() => {
							files.value[idx].uploadFinished = true;
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.SuccessDataUpload'));
						})
						.catch(err => {
							files.value[idx].error = true;
							files.value[idx].errorText = err.message;
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						})
						.finally(() => {
							files.value[idx].loading = false;
							files.value = [...files.value];
						});
				})
		}

		/** Uploads the files from the file-uploader
		 * @param filesSend
		 */
		const fileUploader = (filesSend: UploadFile[]) => {
			files.value = filesSend;
			showWarningDialog.value = true;
		}

		/** Export/Download the translation file
		 * @param fileType
		 */
		const startTranslationFileDownload = (fileType: string) => {
			loading.value = true;
			translationsApi.getTranslationsExport(fileType.toUpperCase())
				.then((response: Response) => {
					window.open(response.url);
				})
				.catch((response: Response) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		return {
			t,
			toast,
			loading,
			activeTabOption,
			tabOptions,
			files,
			fileTypes,
			showErrorMessage,
			showWarningDialog,
			showImportErrors,
			importErrors,
			errorCount,
			onUpdate,
			manualFileUpload,
			startFileUpload,
			fileUploader,
			startTranslationFileDownload,
		}
	},
})
