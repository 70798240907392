
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {BatchClassClassification} from "@dex/squeeze-client-ts";
import {DocumentClass} from "@dex/squeeze-client-ts";
import Tooltip from "primevue/tooltip";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";
import {TableListSettings, TableSettings} from "@/util/TableSettings";

export default defineComponent({
	name: "ClassificationList",
	components: {
		DataTable, Column,
	},
	props: {
		classifications: {
			type: Array as PropType<BatchClassClassification[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
		},
		batchClassId: {
			type: Number,
			required: true,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "openTraining", "openTesting",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: BatchClassClassification) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect")
		}

		/** Get the description of a documentClass instead of number */
		const getDescription = (row: BatchClassClassification) => {
			if (props.documentClasses) {
				const docClass = props.documentClasses.find(docClass => docClass.id === row.documentClassId);
				if (docClass) {
					return docClass.description
				}
			}

			return row.documentClassId
		}

		/** Triggered when Open Training is clicked */
		const openTraining = (row: BatchClassClassification) => {
			emit("openTraining", row);
		}

		/** Triggered when Open Training is clicked */
		const openTesting = () => {
			emit("openTesting");
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { page: number; rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			DataTable, Column, store, tableListPagination, openDeleteDialog, onNewEntryClick, getDescription, openTraining, openTesting, onChangePage,
		}
	},
});
