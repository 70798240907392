
import {defineComponent, onBeforeMount, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {BatchClassSetting} from "@dex/squeeze-client-ts";
import OCRConfigForm from "@/apps/administration/components/batchclasses/OCRConfigForm.vue";

export default defineComponent({
	name: "OcrConfigurationInterfaceView",
	components: {
		BlockUI,
		OCRConfigForm,
	},
	props: {
		batchClassId: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** List of all available OCR Settings */
		const allOcrSettings = ref<BatchClassSetting[]>([]);

		/** Internal Object with all ocr attributes */
		const ocrAttributes = ref<BatchClassSetting[]>([]);

		/** All changed attributes */
		const allChangedAttributes = ref<BatchClassSetting[]>([]);

		/** All Tesseract OCR languages by versions */
		const tesseractOcrLanguages = reactive<any>({});

		/** Batch-Class-Api */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		/**
		 * Get OCR Languages by current Tesseract OCR Version
		 * @param ocrTesseractSetting
		 */
		const getOcrLanguagesByTesseractVersion = (ocrTesseractSetting: BatchClassSetting) => {
			// get ocr languages to the current selected Tesseract OCR version
			const ocrLanguageSetting = ocrAttributes.value.find(att => att.settingName === "OCRLanguage");
			ocrLanguageSetting!.schema.enum = tesseractOcrLanguages[ocrTesseractSetting.value as string];

			// check type because it can be also an array (this is an array when the multiselect is active)
			if (ocrLanguageSetting && ocrLanguageSetting.value && typeof ocrLanguageSetting.value === 'string') {
				ocrLanguageSetting.value = (ocrLanguageSetting.value.split('+') as any);
			}

			// check if current language(-s) exists in the selected Tesseract OCR Version
			const currentLanguages = tesseractOcrLanguages[ocrTesseractSetting.value as string]
				.filter((lang: string) => ocrLanguageSetting!.value?.includes(lang));
			if (!currentLanguages) {
				ocrLanguageSetting!.value = [] as any;
			} else {
				ocrLanguageSetting!.value = currentLanguages;
			}
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			const promise1 = batchClassApi.getPossibleOcrSettingsForBatchClass();
			const promise2 = batchClassApi.getBatchClassSettings(props.batchClassId);
			const promise3 = batchClassApi.getTesseractOcrLanguagesByVersions();

			// Wait until promises are finished
			Promise.all([promise1, promise2, promise3]).then(values => {
				allOcrSettings.value = values[0] as BatchClassSetting[];
				const bcSettings = values[1] as BatchClassSetting[];
				Object.assign(tesseractOcrLanguages, values[2]);

				allOcrSettings.value.forEach(setting => {
					const currentSetting = bcSettings.find(attribute => attribute.settingName === setting.settingName);
					if (currentSetting && currentSetting.value) {
						setting.value = currentSetting.value;
					} else if (!setting.value && setting.schema && setting.schema.default) {
						// set default value when empty
						setting.value = setting.schema.default;
					}

					if (setting.settingName === "OCRTesseractVersion") {
						getOcrLanguagesByTesseractVersion(setting);
					}
					ocrAttributes.value.push(setting);
				})
			}).catch(response => response.json().then((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}

		/** Save all batch class settings */
		const saveBatchClassSettings = () => {
			loading.value = true;

			const promises: any[] = [];
			allChangedAttributes.value.forEach(att => {
				// convert the array to a string of OCRLanguage
				if (att.settingName === 'OCRLanguage' && att.value) {
					att.value = (att.value as any).join('+');
				}

				if (!att.value) {
					// delete the attribute when the value is empty
					promises.push(batchClassApi.deleteBatchClassSettingsByName(props.batchClassId, att.settingName!));
				} else {
					// change the attribute when the value is not empty
					promises.push(batchClassApi.putBatchClassSettingsByName(props.batchClassId, att.settingName!, att.value));
				}
			})

			Promise.all(promises)
				.catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
					allChangedAttributes.value = [];
				})
		}

		/** Triggered on update of attribute-form
		 * @param data
		 */
		const onUpdate = (data: BatchClassSetting) => {
			if (data.settingName === "OCRTesseractVersion") {
				getOcrLanguagesByTesseractVersion(data);
			}

			// check if the data attribute exists in array
			const indexOfAttr = allChangedAttributes.value.findIndex(att => att.settingName === data.settingName);
			if (indexOfAttr > -1) {
				// delete exists attribute in array
				allChangedAttributes.value.splice(indexOfAttr, 1);
			}
			allChangedAttributes.value.push(data);
		}

		onBeforeMount(() => {
			reloadData();
		});

		return {
			t, toast,
			loading,
			allOcrSettings,
			ocrAttributes,
			allChangedAttributes,
			saveBatchClassSettings,
			onUpdate,
		}
	},
});
