
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, PropType} from "vue";
import {BatchClassClassification, FileImportConfig} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "FileSystem",
	components: {
		DataTable, Column,
	},
	props: {
		files: {
			type: Array as PropType<FileImportConfig[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: () => [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: [
		"onClickDelete", "onEntrySelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: FileImportConfig) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				batchClassId: undefined,
				documentClassId: 0,
				active: true,
				path: '',
				indexFileType: FileImportConfig.IndexFileTypeEnum.SCM,
				indexFileExtension: '',
				signalFileName: '',
				imageFileExtension: FileImportConfig.ImageFileExtensionEnum.PDF,
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: FileImportConfig) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a documentClass
		 * @param documentClassId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClass = (documentClassId: number) => {
			if (documentClassId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.documentClasses.find(i => i.documentClassId === documentClassId);

			if (!documentClassDescription) {
				return String(documentClassId)
			}

			return documentClassDescription.description
		}

		return {
			openDeleteDialog, onNewEntryClick, openEditDialog, getDescriptionFromDocumentClass,
		}
	},
});

