
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";
import {
	computed,
	defineComponent, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import RoleView from "@/apps/administration/views/squeeze/roles/tabs/RoleView.vue";
import UsersForRoleView from "@/apps/administration/views/squeeze/roles/tabs/UsersForRoleView.vue";
import RulesForRoleView from "@/apps/administration/views/squeeze/roles/tabs/RulesForRoleView.vue";

export default defineComponent({
	name: "UserForm",
	components: {
		TabMenu, TabView, TabPanel,
	},
	props: {
		roleId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		/** List of menu items */
		const {t} = useI18n();

		/** Currently active tab */
		const activeTab = ref(0);

		/** Array of the already loaded tabs. The first tab is always active */
		const loadedTabs = ref<number[]>([0]);

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.MasterData.General'), to: RoleView},
			]

			if (props.roleId) {
				itemsList.push({label: t('Squeeze.UserManagement.Users'), to: UsersForRoleView});
				itemsList.push({label: t('Squeeze.UserManagement.RuleFilter'), to: RulesForRoleView});
			}

			return itemsList;
		});


		return {items, t, activeTab, loadedTabs};
	},
});

