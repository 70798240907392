
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive, ref} from "vue";
import {User} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import EntryDialog from "@/components/EntryDialog.vue";
import UserTakeOverRolesView from "@/apps/administration/views/squeeze/users/tabs/UserTakeOverRolesView.vue";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import InputText from "primevue/inputtext";

export default defineComponent({
	name: "UserList",
	components: {
		InputText,
		DataTable,
		Column,
		EntryDialog,
		UserTakeOverRolesView,
	},
	props: {
		users: {
			type: Array as PropType<User[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		selectionMode: {
			type: String,
			default: null,
		},
		showEditButton: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "onRowSelect",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/** Currently-Selected row */
		const selection = ref(null);

		/** Show the loading for Save-Button of take over roles? */
		const loadingTakeOverRoles = ref(false);

		/** Should the Entry-Dialog for take over roles be shown? */
		const showTakeOverRolesDialog = ref<boolean>(false);

		/** Text of the header in take over roles Entry-Dialog */
		const headerTextOfTakeOverRoles = ref<string>(t('Squeeze.UserManagement.TakeOverRoles'));

		/** Is the Form invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Object with current users for take over roles */
		const usersTakeOverRoles = reactive<any>({
			userIdFrom: null,
			userIdTo: null,
		});

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: User) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				login: '',
				firstName: '',
				lastName: '',
				email: '',
				password: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: User) => {
			emit("onEntrySelect", row)
		}

		/** Triggered when a row is selected */
		const onRowSelect = (event: any) => {
			emit("onRowSelect", event.data)
		}

		/**
		 * Opens the take over Roles Dialog
		 * @param userId
		 */
		const openTakOverRolesDialog = (userId: number) => {
			usersTakeOverRoles.userIdTo = userId;
			showTakeOverRolesDialog.value = true;
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: any, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(usersTakeOverRoles, data);
		}

		/**
		 * Execute the take over roles
		 * @param keepDialogOpen
		 */
		const executeTakeOverRoles = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingTakeOverRoles.value = true;

			userService.transferUserRoles(usersTakeOverRoles.userIdFrom, usersTakeOverRoles.userIdTo)
				.then(() => {
					if (!keepDialogOpen) {
						showTakeOverRolesDialog.value = false;
					}
					else {
						isValidationInvalid.value = true;
					}
					// Empty value
					usersTakeOverRoles.userIdFrom = null;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.UserManagement.SuccessRolesTransfer'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingTakeOverRoles.value = false;
				})
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userService, route.name, event.rows);
		}

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"login": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"firstName": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"lastName": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"email": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			showBtnClearFilter.value = event.filteredValue.length < props.users!.length;
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		initFilters();

		return {
			t,
			toast,
			store,
			tableListPagination,
			selection,
			loadingTakeOverRoles,
			showTakeOverRolesDialog,
			headerTextOfTakeOverRoles,
			isValidationInvalid,
			showValidationMessage,
			usersTakeOverRoles,
			filters,
			showBtnClearFilter,
			openDeleteDialog,
			onNewEntryClick,
			onRowSelect,
			openEditDialog,
			openTakOverRolesDialog,
			onUpdate,
			executeTakeOverRoles,
			onChangePage,
			onFilter,
			clearFilters,
		}
	},
});

