
import {defineComponent, onBeforeMount, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ToastManager} from "@/util/ToastManager";
import {Chart, ChartDatasetCustomTypesPerDataset} from 'chart.js';
import moment from "moment";
import Calendar from 'primevue/calendar';
import {ClientManager} from "@/singletons/ClientManager";
import {ColorGenerator} from "@/util/ColorGenerator";

export default defineComponent({
	name: "DocumentReportOrigin",
	components: {
		Calendar,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Selected date */
		const selectedDate = ref<string[]>([]);

		/** Chart data */
		const chartOriginData = reactive<any>({});

		/** Chart labels */
		const chartOriginLabels = ref<string[]>([]);

		/** Date placeholder */
		const placeholder = ref<string>('');

		/** Current color of client */
		const currentClientColor = ref<string>('');

		/** Service for queue api */
		const queueAPI = ClientManager.getInstance().squeeze.queue;

		/** Get current chart datasets */
		const createOriginData = () => {
			const data = [];
			let tempData = {};
			const dataEntries = Object.entries(chartOriginData.value);
			const hexToHslColor = ColorGenerator.hexToHSL(currentClientColor.value);
			const hslColors = ColorGenerator.generateHslColors(dataEntries.length, hexToHslColor);

			let colorIndex = 0;
			for (const [key, value] of dataEntries) {
				const color = hslColors[colorIndex];
				colorIndex++;

				const dataFromChartOrigin: any = {value}.value;
				tempData = {
					label: {key}.key,
					data: dataFromChartOrigin[0]['data'],
					borderWidth: 3,
					borderColor: color,
					backgroundColor: color,
				}
				data.push(tempData);
			}
			return data;
		}

		/** Create the chart */
		const createOriginChart = () => {
			const canvas = document.getElementById('originReportChart') as HTMLCanvasElement;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			currentClientColor.value = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');
			new Chart(ctx, {
				type: 'bar',
				data: {
					labels: chartOriginLabels.value,
					datasets: createOriginData() as ChartDatasetCustomTypesPerDataset[],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			});
		}

		/**
		 * Get Chart Data
		 * @param startDate
		 * @param endDate
		 */
		const getOriginChartData = async (startDate: moment.Moment, endDate: moment.Moment) => {
			try {
				placeholder.value = startDate.format('DD.MM.YYYY') + ' - ' + endDate.format('DD.MM.YYYY');
				let queueReportOriginData = null;
				queueReportOriginData = await queueAPI.getQueueReportOrigin(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));

				// Build labels for x axis
				const labels: string[] = [];
				for (const i of queueReportOriginData.labels!) {
					labels.push(String(i));
				}
				chartOriginLabels.value = labels;

				// Build import data
				if (queueReportOriginData.datasets) {
					chartOriginData.value = queueReportOriginData.datasets;
				}

				// Build Chart
				const presentChart = Chart.getChart("originReportChart");
				if (presentChart) {
					presentChart.destroy();
					createOriginChart();
				} else {
					createOriginChart();
				}
			} catch (err: any) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err);
			}
		}

		/** Triggered when Dates are selected */
		const selectDate = () => {
			if (selectedDate.value[1]) {
				getOriginChartData(moment(selectedDate.value[0], 'LLLL'), moment(selectedDate.value[1], 'LLLL'));
			}
		}

		onBeforeMount(async () =>{
			await getOriginChartData(moment('01.01.', 'DD MM'), moment());
		})

		return {
			t,
			toast,
			store,
			selectedDate,
			chartOriginData,
			chartOriginLabels,
			placeholder,
			currentClientColor,
			createOriginChart,
			createOriginData,
			getOriginChartData,
			selectDate,
		};
	},
});

