
import {defineComponent, reactive} from 'vue';
import {useI18n} from "vue-i18n";

interface OcrSettingData {
	[key: string]: {
		data: any;
	};
}

export default defineComponent({
	name: "OverlayHelpDialog",
	components: {},
	props: {
		currentHelpFieldName: {
			type: String,
			required: true,
		},
	},
	setup() {
		const {t} = useI18n();

		/** Current documentation of all data */
		const allData = reactive<OcrSettingData>({
			"OCRForce": {
				data: t('Squeeze.BatchClasses.OCRForeHelpText'),
			},
			"OCRLanguage": {
				data: t('Squeeze.BatchClasses.OCRLanguageHelpText'),
			},
			"OCRPageLimit": {
				data: t('Squeeze.BatchClasses.OCRPageLimitHelpText'),
			},
			"OCRTesseractVersion": {
				data: t('Squeeze.BatchClasses.OCRTesseractVersionHelpText'),
			},
			"PDFA-Conversion": {
				data: t('Squeeze.BatchClasses.PDFAConversionHelpText'),
			},
			"PDFProcessor": {
				data: t('Squeeze.BatchClasses.PDFProcessorHelpText'),
			},
			"PSM-Mode": {
				data: [
					{
						version: "3",
						description: t('Squeeze.BatchClasses.PSMModeVersionThreeHelpText'),
					},
					{
						version: "4",
						description: t('Squeeze.BatchClasses.PSMModeVersionFourHelpText'),
					},
					{
						version: "6",
						description: t('Squeeze.BatchClasses.PSMModeVersionSixHelpText'),
					},
					{
						version: "11",
						description: t('Squeeze.BatchClasses.PSMModeVersionElevenHelpText'),
					},
				],
			},
		});

		return {
			t,
			allData,
		}
	},
})
