
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorValueList} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default defineComponent({
	name: "ValueListForm",
	components: {
		InputText,
	},
	props: {
		valueListEntry: {
			type: Object as PropType<LocatorValueList>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorValueList>({});

		/** Determines the required rules for validation */
		const rules = {
			description: { required },
			value: { required},
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.valueListEntry);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.valueListEntry, () => {
			Object.assign(value, props.valueListEntry);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			InputText,
			update,
			v$,
		}
	},
});

