import {ClientManager} from "@/singletons/ClientManager";
import {FreezeStore} from "@/apis/freeze/Types";
import {FreezeClient} from "@/apis/freeze/FreezeClient";

export interface EASSpool {
	spool: EASSpoolItem[];
}

export interface EASSpoolItem {
	id: string;
	path: string;
	fileName: string;
}

interface EasField {
	name: string;
	desc: string;
	type: 'string';
	value: '';
}

export class EasClient {

	/** List of all Freeze Stores **/
	public stores: FreezeStore[] = [];

	private freeze: FreezeClient;

	constructor() {
		this.freeze = FreezeClient.getInstance();
	}

	private getFetch() {
		return ClientManager.getInstance().freezeFetch;
	}

	/**
	 * Returns the Url for a store
	 * @param storeName
	 */
	public getStoreUrl = async (storeName: string) => {
		if (this.stores.length === 0) {
			try {
				this.stores  = await this.freeze.getAllStores();
			} catch (e) {
				throw Error(e);
			}
		}

		const store = this.stores.find(store => store.name === storeName);

		if (!store) {
			throw Error("Invalid store");
		}

		if (store.links && store.links.api) {
			return store.links.api + "/eas/archives/" + storeName;
		}

		throw Error("Couldn't get store Url");
	}

	/**
	 * Gets records for a store
	 * @param storeId
	 * @param query
	 * @param page
	 * @param pageSize
	 */
	public async getRecords(storeId: string, query: string[], page: number, pageSize: number, sortField: string|null, sortOrder: string|null) {
		const headers =  new Headers();
		headers.set("Accept", "application/json");

		let sortQuery = "";

		if (sortField && sortOrder) {
			sortQuery = '&sort=' + sortField + '&sortOrder=' + sortOrder;
		}

		const storeUrl = await this.getStoreUrl(storeId);
		const response = await this.getFetch()(storeUrl
			+ "?query=" + query!.join(' AND ')
			+ "&startIndex=" + (pageSize! * page!)
			+ "&itemsPerPage=" + pageSize
			+ sortQuery, {
			method: "GET",
			headers: headers,
		})

		if (response.status !== 200 && response.status !== 204) {
			throw response.statusText;
		}

		return await response.json();
	}

	/**
	 * Returns the data of a single record
	 * @param storeId
	 * @param recordId
	 */
	public async getRecord(storeId: string, recordId: string) {
		const headers = new Headers();
		headers.set("Accept", "application/json");
		const storeUrl = await this.getStoreUrl(storeId);
		const response = await this.getFetch()(
			`${storeUrl}/record/${recordId}`,
			{
				method: "GET",
				headers: headers,
			});

		if (response.status !== 200 || response.headers.has("X-Otris-Eas-Error")) {
			throw new Error("Retrieving record failed")
		}

		return await response.json();
	}

	/* Downloads a records attachment and returns */
	public getRecordAttachments = async (storeId: string, recordId: string) => {
		const headers =  new Headers();
		headers.set("Accept", "application/json");
		const storeUrl = await this.getStoreUrl(storeId);
		const response = await this.getFetch()(
			`${storeUrl}/record/${recordId}/attachments`,
			{
				method: "GET",
				headers: headers,
			});

		if (response.status !== 200 || response.headers.has("X-Otris-Eas-Error")) {
			throw new Error("Retrieving attachment list failed")
		} else {
			return (await response.json()).attachments;
		}
	}

	/**
	 * Returns a single attachment
	 * @param storeId
	 * @param recordId
	 * @param attachmentId
	 */
	public async getAttachment(storeId: string, recordId: string, attachmentId: string)  {
		const storeUrl = await this.getStoreUrl(storeId);
		const response = await this.getFetch()(
			`${storeUrl}/record/${recordId}/attachment/${attachmentId}`,
			{
				method: "GET",
			});

		if (response.status !== 200 || response.headers.has("X-Otris-Eas-Error")) {
			throw new Error("Retrieving attachment list failed")
		} else {
			return this.arrayBufferToBase64((await response.arrayBuffer()));
			//btoa((await response.text()));
		}
	}

	/**
	 * Uploads files to a stores and return the ids/paths of these files
	 * @param storeId
	 * @param files
	 */
	public async spoolDocumentToStore(storeId: string, files: any[]): Promise<EASSpool> {
		const body = new FormData();

		files.forEach((file, index) => {
			body.set(file.attachmentId, file);
		})

		const headers =  new Headers();
		headers.set("Accept", "application/json");

		const storeUrl = await this.getStoreUrl(storeId);
		const response = await this.getFetch()(
			`${storeUrl}/spool`,
			{
				method: "POST",
				body: body,
				headers: headers,
			});

		if (response.status !== 200 || response.headers.has("X-Otris-Eas-Error")) {
			throw new Error("Retrieving attachment list failed")
		} else {
			return (await response.json())
		}
	}

	public async archiveRecord(storeId: string, fields: EasField[], spoolFiles: EASSpoolItem[]) {
		const storeUrl = await this.getStoreUrl(storeId);
		const fieldStrings = fields.map(field => {
			return '\t\t<field name="' + field.name + '">' + field.value + '</field>';
		});

		const spoolItems = spoolFiles.map(file => {
			return '\t\t<attachment>\n'+
				'\t\t\t<name>' + file.fileName + '</name>\n' +
				'\t\t\t<path>' + file.path + '</path>\n' +
				'\t\t</attachment>\n';
		})

		const xmlBody = '<?xml version="1.0"?>\n' +
			'\t\t<records xmlns="http://namespace.otris.de/2010/09/archive/recordExtern">' +
			'\t<record>\n' +
			fieldStrings.join("\n") +
			spoolItems.join("\n") +
			'\t</record>\n' +
			'</records>';

		const headers =  new Headers();
		headers.set("Accept", "application/json");

		const body = new FormData();
		body.set ("record", xmlBody);

		const response = await this.getFetch()(
			`${storeUrl}/record`,
			{
				method: "POST",
				body: body,
				headers: headers,
			});

		if (response.status !== 200 || response.headers.has("X-Otris-Eas-Error")) {
			throw new Error("Retrieving attachment list failed")
		} else {
			return (await response.json())
		}
	}

	/**
	 * https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
	 * @param buffer
	 */
	public arrayBufferToBase64 = (buffer: ArrayBuffer) => {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[ i ]);
		}
		return window.btoa(binary);
	}

	private static instance: EasClient;

	/**
	 * @deprecated Most of the time, you should rely in dependency injection (https://vuejs.org/guide/components/provide-inject.html)
	 * to get an instance of this API client. Please use {@link EasClientKey} if you are unsure what method to use.
	 */
	public static getInstance() {
		if(this.instance == null) {
			this.instance = new EasClient();
		}

		return this.instance;
	}

}
