
/* eslint max-lines: off */
import {defineComponent, onBeforeMount, onMounted, PropType, ref} from "vue";
import {DocumentField, FieldLookupFilter, LookupDefinition, ValidationFieldDto} from "@dex/squeeze-client-ts";
import InputText from "primevue/inputtext";
import AutoComplete from '@/components/DexAutocomplete.vue';
import Badge from "primevue/badge";
import Divider from "primevue/divider";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import Tooltip from "primevue/tooltip";
import {ClientManager} from "@/singletons/ClientManager";
import {AutoCompleteOnCompleteEvent} from "@/shims-prime-vue";

interface LookupDefinitionWithFilters extends LookupDefinition {
	lookupFieldFilters: FieldLookupFilter[];
}

interface DocumentFieldWithLookupFilter extends DocumentField{
	lookup?: LookupDefinitionWithFilters;
}

interface UiHeadFields {
	name: string;
	field: DocumentField;
	element: any;
}

export default defineComponent({
	name: "ValidationFieldSet",
	components: {
		InputText,
		AutoComplete,
		Badge,
		Divider,
	},
	props: {
		/** List of all DocumentFields of document class */
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		/** List of all DocumentFields of document */
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		/** Field group id */
		id: {
			type: Number,
			required: true,
		},
		/** Technical field group name */
		name: {
			type: String,
			default: '',
		},
		/** Field group description */
		description: {
			type: String,
			default: '',
		},
		/** Is the current mode of component readonly? */
		isReadOnlyMode: {
			type: Boolean,
			default: false,
		},
		/** Document id */
		documentId: {
			type: Number,
			required: true,
		},
		/** Field Layout Details */
		layoutDetails: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		'onFocusField',
		'onBlur',
		'allHeadRefFields',
		'enterDocumentGroup',
		'onHoverItemAutocomplete',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current Vuex-Store */
		const store = useSqueezeStore();

		/** Current document fields */
		const fields = ref<DocumentField[]>([]);

		/** Object with the current filtered values */
		const filteredValues = ref<any[]>([]);

		/** Object with the current alternative values */
		const alternativeValues = ref<any[]>([]);

		/** Array with Fields  */
		const headRefField = ref<UiHeadFields[]>([]);

		/** Service for getting the master-data-lookups */
		const documentService = ClientManager.getInstance().squeeze.document;

		/** Emits the onFocusField-Event with the current-field  */
		const onFocusField = (event: FocusEvent, field: DocumentField) => {
			if (event && event.target) {
				(event.target as HTMLInputElement).select();
			}

			emit("onFocusField", field);
		}

		/**
		 * Emits the onFocusField-Event with the current-field
		 * @param event
		 */
		const onHoverItemAutocomplete = (event: any) => {
			// Only trigger event if there is something in the values to emit
			if (event.value && event.value.completeValue) {
				emit("onHoverItemAutocomplete", event.value.completeValue);
			}
		}

		/**
		 * Emits the onValidationRequest-Event with the current-field
		 * @param event
		 * @param {DocumentField} field
		 */
		const emitValidationRequest = (event: any, field: DocumentField) => {
			// In AutoComplete box if entry in popup list is clicked
			// first the input's blur event is triggered.
			// But in this case no further action (Validation) should be taken
			// but only after real blur (leave) of input.
			if (event && event.target) {
				const node = event.target as Node;
				if (node.parentElement && node.parentElement.getAttribute('aria-expanded') === 'true') {
					return;
				}
			}

			if (field.forceValidation && field.state !== "OK") {
				field.state = "OK";
			}

			// Check if the type is 'button', then return (prevent duplicate validation request)
			if (event.relatedTarget && event.relatedTarget.type && event.relatedTarget.type === 'button') {
				return;
			}

			emit("onBlur", field);
		}

		/**
		 * Return index of document field
		 * @param {string} name
		 */
		const getDocumentFieldIndex = (name: string) => {
			return props.documentFields.findIndex(field => field.name == name);
		}

		/**
		 * Event that is triggered when an item is selected on Autocomplete
		 * @param event Event of Autocomplete
		 * @param {DocumentField} documentClassField Current documentClassField
		 */
		const onItemSelect = (event: any, documentClassField: DocumentField) => {
			// At this point we used the value (instead of the label), because we want to write the ID in the lookup field.
			props.documentFields[getDocumentFieldIndex(documentClassField.name!)].value!.value = event.value.value;

			if (event.value.completeValue) {
				props.documentFields[getDocumentFieldIndex(documentClassField.name!)].value!.boundingBox = event.value.completeValue.boundingBox;
			} else {
				props.documentFields[getDocumentFieldIndex(documentClassField.name!)].value!.boundingBox = undefined;
			}
		}

		/**
		 * Gets the value from a field by field id
		 * @param {number} fieldId
		 */
		const getValueFromField = (fieldId: number) => {
			const field = props.documentFields.find(field => field.id === fieldId);

			if (field && field.value) {
				return field.value!.value;
			}

			// If no value can be found, search for something illogical that never can be found
			return "FieldNotFound";
		}

		/**
		 * Sets the Value for the autocomplete/lookup
		 * @param event
		 * @param field
		 */
		const setAutocompleteValues = async (event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) => {
			alternativeValues.value[field.name as any] = [];
			if (!field.lookup!.active && field.alternatives!.length > 0) {
				// show all alternatives in dropdown
				const alternatives = field.alternatives!
					.map(alternative => {
						const label = alternative.value;
						const value = alternative.value;
						const completeValue = alternative;
						return {value, label, completeValue};
					})

				filteredValues.value[field.name as any] = alternatives;

				// Add current entry if text is written to the field
				if (event.query) {
					const currentValue = {
						value: event.query,
						label: event.query,
						completeValue: {
							boundingBox: {
								x1: 0,
								y1: 0,
								page: 0,
								x0: 0,
								y0: 0,
							},
						},
					}
					filteredValues.value[field.name as any].unshift(currentValue);
				}
			} else if (field.lookup!.active) {
				// Otherwise the reference to props.documentFields is referenced and will be changed
				const documentFieldClone = JSON.parse(JSON.stringify(props.documentFields)) as ValidationFieldDto[];
				if (!event.query) {
					event.query = "";
				}

				// Sometimes value is null when the field is cleared. If it is, it's an empty field
				const autocompleteField = documentFieldClone.find((documentField: DocumentField) => documentField.name === field.name);
				if (autocompleteField) {
					if(!autocompleteField.value!.value) {
						autocompleteField.value!.value = "";
					}
				}

				const rows = await documentService.getDocumentFieldLookupValues(props.documentId, Number(field.id), {
					documentFields: documentFieldClone,
					fieldSearchValue: event.query,
				}) as any;
				const resultColumns = field.lookup?.resultValueColumnIds;
				const alternatives = rows
					.map((row: any) => {
						const completeValue = row;
						const value = row.resultValue;
						const label = resultColumns?.map(col => row.displayColumnResults[col]).join(" | "); // Map result columns to a single string to be displayed
						return {value, label, completeValue};
					})

				filteredValues.value[field.name as any] = alternatives;

				// Add current entry if text is written to the field
				if (event.query && field.lookup?.allowCustomValues) {
					const currentValue = {
						value: event.query,
						label: event.query,
						completeValue: {
							boundingBox: {
								x1: 0,
								y1: 0,
								page: 0,
								x0: 0,
								y0: 0,
							},
						},
					}
					filteredValues.value[field.name as any].unshift(currentValue);
				}
			}
		}

		/**
		 * Event that is triggered when users make autocomplete-inputs
		 * @param {AutoCompleteOnCompleteEvent} event Event of Autocomplete
		 * @param {DocumentFieldWithLookupFilter} field Current documentClassField
		 */
		const searchAutocomplete = (event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) => {
			setAutocompleteValues(event, field);
		}

		/**
		 * Triggered when a Dropdown is clicked. Currently, this triggered by clicking the Autocomplete-Field.
		 * @param {AutoCompleteOnCompleteEvent} event
		 * @param {DocumentFieldWithLookupFilter} field
		 */
		const onClickDropdown = (event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter) => {
			setAutocompleteValues(event, field);
		}

		/**
		 * Event that is triggered on click on autocomplete-fields
		 * @param event
		 * @param {DocumentField} field Current documentClassField
		 */
		const onClickAutocomplete = (event: unknown, field: DocumentField) => {
			// Trigger onDropDownClick-Event, so the alternatives are shown when there are alternatives. Otherwise, do nothing
			if (field.alternatives!.length > 1 || (field.lookup?.active === true && field.lookup.minInputLength === 0)) {
				const index = headRefField.value.findIndex(refField => refField.name === field.name);
				headRefField.value[index].element.onDropdownClick(event, field);
			}
		}

		/**
		 * Triggered on keydown
		 * @param {KeyboardEvent} event
		 * @param {DocumentField} field Current documentClassField
		 */
		const onKeydown = (event: KeyboardEvent, field: DocumentField) => {
			// if key ctrl and arrow down pressed, then open the autocomplete popup
			if (event.code === 'ArrowDown' && (navigator.platform.match("MacIntel") ? event.metaKey : event.ctrlKey)) {
				onClickAutocomplete(event, field);
			}
		}

		/**
		 * Check the length of the description
		 * @param {string} description
		 */
		const checkDescriptionLength = (description: string) => {
			return description.length > 15 && !description.includes('-') && !description.includes(' ');
		}

		/**
		 * Set field reference
		 * @param element
		 * @param {DocumentField} field
		 */
		const setFieldReference = (element: Element, field: DocumentField) => {
			const refField = headRefField.value.find(refField => refField.name === field.name);
			if (field.readonly === true) {
				return;
			}

			if (!refField) {
				const newField: UiHeadFields = {
					name: field.name || "Field name",
					field: field,
					element: element,
				};

				headRefField.value.push(newField);
			}
		}

		/**
		 * Emits on field confirm via enter with the current-field
		 * @param event
		 * @param {DocumentField} field
		 */
		const onEnter = (event: KeyboardEvent, field: DocumentField) => {
			event.preventDefault();

			if (field.forceValidation && field.state !== "OK") {
				field.state = "OK";
				emit("onBlur", field);
			}

			const allVisibleFields = headRefField.value.filter(field => field.field.hidden === false);
			let index = allVisibleFields.findIndex(refField => refField.field.name === field.name);
			if (event.shiftKey) {
				index -= 1;
			} else {
				index += 1;
			}

			if (allVisibleFields[index]) {
				allVisibleFields[index].element.$el.focus();
				if (allVisibleFields[index].field.lookup?.active === true && !props.isReadOnlyMode ||
					(allVisibleFields[index].field.alternatives!.length > 0 && allVisibleFields[index].element.$el.firstElementChild && allVisibleFields[index].element.$el.firstElementChild != null)) {
					allVisibleFields[index].element.$el.firstElementChild.focus();
				}
			} else {
				// If a field is the last one of a group, also emit
				if (event.shiftKey) {
					emit("enterDocumentGroup", props.id, false);
				} else {
					emit("enterDocumentGroup", props.id, true);
				}
				return;
			}

			if (allVisibleFields.length === index) {
				emit("enterDocumentGroup", props.id, true);
			} else if (index === -1) {
				emit("enterDocumentGroup", props.id, false);
			}
		}

		/**
         * Triggers the check of values on autocomplete-fields. If the value is invalid, the value will be cleared.
         * This function is needed for values that are inserted to fields via Squeeze-Viewer
         * @param event
         * @param field
         * @param fieldValue
         */
		const checkAutocompleteValues = async (event: AutoCompleteOnCompleteEvent, field: DocumentFieldWithLookupFilter, fieldValue: any = '') => {
			if (!fieldValue) {
				return;
			}

			await setAutocompleteValues(event, field);

			if (filteredValues.value[field.name as any]) {
				const index = headRefField.value.findIndex(refField => refField.name === field.name);
				if(!filteredValues.value[field.name as any].find((filteredValue: any) => filteredValue.value === fieldValue)) {
                    props.documentFields[getDocumentFieldIndex(field.name!)].value!.value = "";
				} else {
					headRefField.value[index].element.showOverlay();
				}
			}
		}

		onBeforeMount(() => {
			// set document fields
			fields.value = props.documentFields;
		})

		onMounted(() => {
			emit("allHeadRefFields", headRefField.value);
		});

		return {
			t,
			toast,
			store,
			fields,
			filteredValues,
			alternativeValues,
			headRefField,
			onFocusField,
			onHoverItemAutocomplete,
			emitValidationRequest,
			getDocumentFieldIndex,
			onItemSelect,
			getValueFromField,
			setAutocompleteValues,
			searchAutocomplete,
			onClickDropdown,
			onClickAutocomplete,
			onKeydown,
			checkDescriptionLength,
			setFieldReference,
			onEnter,
			checkAutocompleteValues,
		}
	},
});
