
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {ApiKey, BatchClass} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import ApiKeyList from "@/components/settingDialog/apiKey/ApiKeyList.vue";
import ApiKeyForm from "@/components/settingDialog/apiKey/ApiKeyForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";

export default defineComponent({
	name: "UserApiKeysView",
	components: {
		DialogDelete, ApiKeyList, ApiKeyForm, EntryDialog,
	},
	props: {
		userId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['clickSettingDialog'],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Service for getting the user-data */
		const userService = ClientManager.getInstance().squeeze.user;

		/** List of all users */
		const apiKeys = ref<ApiKey[]>([]);

		/** Currently active user */
		const apiKey = reactive<ApiKey>({
			name: '',
			value: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Should the Entry-Dialog for Columns be shown? */
		const showDialog = ref<boolean>(false);

		/** Is the Form of the mails invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.UserManagement.ApiKey') }));

		/** Triggered on update of attribute-form */
		const onUpdate = (data: BatchClass, valid: boolean) => {
			isValidationInvalid.value = valid;
			Object.assign(apiKey, data);
		}

		/** Reloads the table to get all users */
		const reloadData = () => {
			loading.value = true;
			userService.getUserApiKeys()
				.then(data => {
					apiKeys.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			reloadData();
		})

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: ApiKey) => {
			Object.assign(apiKey, row);
			showDialog.value = true;
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: ApiKey) => {
			deleteDialog.value = true;
			Object.assign(apiKey, row);
		}

		/** Deletes a user */
		const deleteEntry = () => {
			if (apiKey && apiKey.value) {
				loading.value = true;
				userService.deleteUserApiKey(apiKey.value).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}
		/** Deletes a user */
		const createApiKey = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;

			if (apiKey && apiKey.name) {
				loading.value = true;
				userService.addUserApiKey(apiKey).then(() => {
					showDialog.value = false;
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog');
		}

		return {loading, apiKeys, apiKey, deleteDialog, userService,
			showDialog, isValidationInvalid, showValidationMessage,
			loadingDialog, headerText, createApiKey, onEntrySelect,
			deleteEntry, openDeleteDialog, onUpdate, clickSettingDialog};
	},
});

