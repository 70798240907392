
import {defineComponent, reactive} from 'vue';
/*import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";*/
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: "SystemCsvTranslationExport",
	components: {},
	props: {
		fileTypes: {
			type: Array,
		},
	},
	emits: ['startTranslationFileDownload'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Current Object of all input-fields */
		const value = reactive<any>({csvSeparator: ';', fileType: 'csv', filePath: ''});

		/** Start download/export of translation file */
		const startFileDownload = () => {
			emit('startTranslationFileDownload', value.fileType);
		}

		return {
			t,
			value,
			startFileDownload,
		}
	},
})
