
import {defineComponent, ref} from 'vue';

export default defineComponent({
	name: 'DocumentClassAttributesSubView',
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup() {
		/** Currently active field-group */
		const activeFieldGroup = ref<number>(-1);

		/**
		 * Is triggered when the Field-Groups triggers a change
		 * @param {number} index Current active field group
		 */
		const onFieldGroupChange = (index: number) => {
			activeFieldGroup.value = index;
		}

		return {
			activeFieldGroup,
			onFieldGroupChange,
		};
	},
});

