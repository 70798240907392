
import {computed, defineComponent} from 'vue';
import {useI18n} from "vue-i18n";
import useSqueezeStore from "@/apps/squeeze/store";
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";

export default defineComponent({
	name: 'DocumentClassView',
	components: {
		TabMenu,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.DocumentClasses.General'), to: { name: 'DocumentClassSubView', params: { documentClassId: props.documentClassId }}},
			]

			if (props.documentClassId) {
				itemsList.push({
					label: t('Squeeze.DocumentClasses.Fields'),
					to: { name: 'DocumentClassFieldGroupsSubView', params: { documentClassId: props.documentClassId }}})
				itemsList.push({
					label: t('Squeeze.DocumentClasses.Tables'),
					to: { name: 'DocumentClassTablesSubView', params: { documentClassId: props.documentClassId }}})
				itemsList.push({
					label: t('Squeeze.DocumentClasses.ExportInterfaces'),
					to: { name: 'DocumentClassExportInterfaceSubView', params: { documentClassId: props.documentClassId }}})
				//{label: t('Squeeze.DocumentClasses.Attributes'), to: { name: 'DocumentClassAttributesSubView', params: { documentClassId: props.documentClassId }}},

				if (store.state.featureSet.xmlEditor) {
					itemsList.push({
						label: 'XML Mapper',
						to: { name: 'MapperView', params: { documentClassId: props.documentClassId }}})
				}
			}

			return itemsList;
		});

		return {
			t,
			items,
		};
	},
});

