
import {defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import Dialog from "primevue/dialog";

export default defineComponent({
	name: "DialogDelete",
	components: {
		Dialog,
	},
	props: {
		/** Prop from Show-Dialog */
		showDialog: {
			type: Boolean,
			default: false,
		},
		/** Label that can be optionally given from outside the component */
		headerLabel: {
			type: String,
			default: null,
		},
		/** Message in Dialog that can be optionally given from outside the component */
		messageLabel: {
			type: String,
			default: null,
		},
	},
	emits: ["onClose", "onConfirm"],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Save showDialog in local variable, because showDialog is not allowed to me mutated inside. React on change from showDialog from parent */
		const deleteDialog = ref<boolean>(props.showDialog);

		/** Component Label of Dialog */
		const headerLabelComponent = ref<string>("");

		/** Component Message of Dialog */
		const messageLabelComponent = ref<string>("");

		/** Emit onClose-Event to parent, when "no" or "close" is clicked */
		const closeDialog = ()  => {
			deleteDialog.value = false;
			emit("onClose");
		};

		/** Emit the "onConfirm-Event to parent if "yes" is clicked */
		const deleteEntry = () => {
			deleteDialog.value = false;
			emit("onConfirm");
		}

		/**
		 * If key escape or enter pressed, then close or save the (dialog) entry
		 * @param {KeyboardEvent} event
		 */
		const onKeydown = (event: KeyboardEvent) => {
			// Only do something if the dialog is visible
			if (!deleteDialog.value) {
				return;
			}

			// by key escape close the dialog
			if (event.key === "Escape") {
				closeDialog();
			}

			// by key enter delete entry
			if (event.key === "Enter") {
				event.preventDefault();
				deleteEntry();
			}
		}

		onMounted(() => {
			if (!props.headerLabel) {
				headerLabelComponent.value = t('Squeeze.General.DeleteHeader');
			} else {
				headerLabelComponent.value = props.headerLabel;
			}

			if (!props.messageLabel) {
				messageLabelComponent.value = t('Squeeze.General.DeleteConfirm');
			} else {
				messageLabelComponent.value = props.messageLabel;
			}
		});

		/** Is triggered before a Component unmounts. Available since Vue 3.0 */
		onBeforeUnmount(() => {
			document.removeEventListener("keydown", onKeydown);
		})

		watch(() => props.showDialog,() => {
			// Save showDialog in local variable, because showDialog is not allowed to me mutated inside. React on change from showDialog from parent
			deleteDialog.value = props.showDialog;

			// not in mounted because mounted is trigger to early
			if (props.showDialog) {
				// Setup keyboard events that are active for the whole view
				document.addEventListener("keydown", onKeydown);
			} else {
				document.removeEventListener("keydown", onKeydown)
			}
		})

		return {
			t,
			deleteDialog,
			headerLabelComponent,
			messageLabelComponent,
			closeDialog,
			deleteEntry,
			onKeydown,
		};
	},
});

