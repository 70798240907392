
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";
import {
	computed,
	defineComponent, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import UserView from "@/apps/administration/views/squeeze/users/tabs/UserView.vue";
import RolesForUserView from "@/apps/administration/views/squeeze/users/tabs/RolesForUserView.vue";

export default defineComponent({
	name: "UserTabsView",
	components: {
		TabMenu, TabView, TabPanel,
	},
	props: {
		userId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		/** List of menu items */
		const {t} = useI18n();

		/** Currently active tab */
		const activeTab = ref(0);

		/** Array of the already loaded tabs. The first tab is always active */
		const loadedTabs = ref<number[]>([0]);

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.User.General'), to: UserView},
			]

			if (props.userId) {
				itemsList.push({label: t('Squeeze.UserManagement.Roles'), to: RolesForUserView});
			}

			return itemsList;
		});


		return {items, t, activeTab, loadedTabs};
	},
});

