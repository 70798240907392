import {ClientManager} from "@/singletons/ClientManager";
import {FreezeExportDocuments, FreezeSearchMask, FreezeStore} from "@/apis/freeze/Types";
import {productStore} from "@/store/product-store";


export class FreezeClient {

	/** Defines the default route to Freeze */
	public freezeUrl: string = "";

	public constructor() {
		this.freezeUrl = "";
	}

	private getFetch() {
		return ClientManager.getInstance().freezeFetch;
	}

	/**
	 * Gets all EAS Stores
	 */
	public getAllStores = async (): Promise<FreezeStore[]> => {
		if (!productStore.state.freeze) {
			throw "Freeze Not activated";
		}

		const response = await this.getFetch()(this.freezeUrl + "/v0/easctrl/v0/stores", {
			method: "GET",
		})

		if ((response.status !== 200 && response.status !== 204) || response.headers.has("X-Otris-Eas-Error")) {
			const responseJSON = await response.json();
			throw responseJSON.errorText;
		}

		return await response.json();
	}

	/**
	 * Get all Search Masks. Be aware that this function will not return nested data of search masks.
	 * If you want to retrieve those, use {@link getSearchMask} instead.
	 */
	public getAllSearchMasks = async (): Promise<Array<FreezeSearchMask>> => {
		const response = await this.getFetch()(this.freezeUrl + "/v0/docsearch/v0/searchMasks", {
			method: "GET",
		})

		if (response.status !== 200 && response.status !== 204) {
			const responseJSON = await response.json();
			throw new Error(responseJSON.message)
		} else {
			const data = await response.json();
			return data;
		}
	}

	public async getSearchMask(name: string): Promise<FreezeSearchMask> {
		const response = await this.getFetch()(this.freezeUrl + "/v0/docsearch/v0/searchMasks/" + name, {
			method: "GET",
		})

		if (response.status !== 200) {
			const responseJSON = await response.json();
			throw new Error(responseJSON.message)
		} else {
			const data = await response.json();
			return data;
		}
	}

	/**
	 * Downloads Documents for a specific hitlist
	 * @param storeId
	 * @param exportDocuments
	 */
	public downloadDocuments = async (storeId: string, exportDocuments: FreezeExportDocuments) => {
		const response = await this.getFetch()(this.freezeUrl + "/v0/eas/stores/" + storeId + "/records/export/zip", {
			method: "POST",
			body: JSON.stringify(exportDocuments),
		})

		if (response.status !== 200 && response.status !== 204) {
			const responseJSON = await response.json();
			throw new Error(responseJSON.message)
		}

		return await response.blob();
	}


	private static instance: FreezeClient;

	/**
	 * @deprecated Most of the time, you should rely in dependency injection (https://vuejs.org/guide/components/provide-inject.html)
	 * to get an instance of this API client. Please use {@link FreezeClientKey} if you are unsure what method to use.
	 */
	public static getInstance() {
		if(this.instance == null) {
			this.instance = new FreezeClient();
		}

		return this.instance;
	}

}
