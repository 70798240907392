
import {
	defineComponent
} from 'vue';

import Dropdown from 'primevue/dropdown';
import {showDropdownOverlay} from "@/util/StylesHelper";
import {useI18n} from "vue-i18n";
import {createPdfUrlPath} from "@/util/BlobHelper";

export default defineComponent({
	name: 'RecordViewer',
	components: {
		Dropdown,
	},
	props: {
		record: Object,
		selectedDocument: Object,
	},
	emits: ["onChangeDocument"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const changeDocument = (event: { value: any}) => {
			emit("onChangeDocument", event.value)
		}

		/**
		 * Decodes from base64 to text
		 * @param base64String
		 */
		const decodeText = (base64String: string) => {
			try {
				const actual = atob(base64String)
				return actual.toString();
			} catch(e) {
				return t('Freeze.SearchMask.NoPreview');
			}
		}

		/**
		 * Decodes from base64 to JSON (if the JSON ist valid)
		 * @param base64String
		 */
		const decodeJSON = (base64String: string) => {
			try {
				const actual = JSON.parse(atob(base64String))
				return JSON.stringify(actual, null, 2);
			} catch(e) {
				return t('Freeze.SearchMask.NoPreviewJSON');
			}
		}

		/**
		 * Download an attachment on click of the button
		 * Source: https://stackoverflow.com/questions/3916191/download-data-url-file
		 * @param fileName
		 * @param base64String
		 */
		const downloadAttachment = (fileName: string, base64String: string) => {
			const link = document.createElement("a");
			link.download = fileName;
			link.href = 'data:;base64, ' + base64String;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}

		return {
			downloadAttachment,
			decodeText,
			decodeJSON,
			changeDocument,
			showDropdownOverlay,
			createPdfUrlPath,
		};
	},
});

