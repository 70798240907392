
import {computed, defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import {useI18n} from "vue-i18n";
import {technicalValidation} from "@/util/ValidationHelper";
import {showDropdownOverlay} from "@/util/StylesHelper";

interface DataTypes {
	id: string | undefined;
	text: string;
}

export default defineComponent({
	name: 'LocatorForm',
	components: {
		InputText, Dropdown, Checkbox,
	},
	props: {
		/** Current Locator */
		locator: {
			type: Object as PropType<DocumentLocator>,
			required: true,
		},
		/** Current locator id */
		locatorId: {
			type: Number,
			default: 0,
		},
		/** All available Locator-Types */
		locatorTypes: {
			type: Array as PropType<LocatorType[]>,
			default: () => [],
		},
		/** All available Locators */
		locators: {
			type: Array as PropType<DocumentLocator[]>,
			default: () => [],
		},
		showErrorMessage: {
			type: Boolean,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["openEntryDialog", "deleteEntry", 'update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Array with the Search-Pages-Dropzone */
		const searchPages = computed(() => {
			return [
				{
					id: 0,
					description: t('Squeeze.Locators.EverySite'),
				},
				{
					id: 1,
					description: t('Squeeze.Locators.FirstSite'),
				},
				{
					id: 2,
					description: t('Squeeze.Locators.LastSite'),
				},
			]
		});

		/** List of Data-Types */
		const dataTypes = computed(() => {
			return [
				{
					id: 'Text',
					text: t('Squeeze.General.Text'),
				},
				{
					id: 'Date',
					text: t('Squeeze.General.Date'),
				},
				{
					id: 'Amount',
					text: t('Squeeze.General.Amount'),
				},
			] as DataTypes[]
		});

		/** Current Object of all input-fields */
		const value = reactive<DocumentLocator>({
			name: '',
			description: '',
			active: true,
			searchPages: 0,
			sourceLocatorId: 0,
			dataType: 'Text',
			ignoreWhiteSpaces: true,
			locatorType: undefined,
		});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
			locatorType: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.locator);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.locator, async () => {
			Object.assign(value, props.locator);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			//value.locatorType = props.locator.type;
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			searchPages,
			dataTypes,
			value,
			props,
			v$,
			InputText,
			update,
			showDropdownOverlay,
		}
	},
});
