import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-mb-2 p-p-2" }
const _hoisted_2 = {
  key: 0,
  class: "p-col-12"
}
const _hoisted_3 = { class: "p-field p-input-filled p-inputgroup" }
const _hoisted_4 = {
  key: 1,
  class: "p-fluid p-formgrid p-grid p-jc-end p-p-2"
}
const _hoisted_5 = {
  key: 0,
  class: "p-field p-col-3"
}
const _hoisted_6 = { class: "p-field p-col-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserForm = _resolveComponent("UserForm")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_Message = _resolveComponent("Message")!
  const _component_EntryDialog = _resolveComponent("EntryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
        default: _withCtx(() => [
          _createVNode(_component_UserForm, {
            user: _ctx.user,
            showErrorMessage: _ctx.showValidationMessage,
            userId: _ctx.userId,
            onUpdate: _ctx.onUpdate
          }, null, 8, ["user", "showErrorMessage", "userId", "onUpdate"]),
          (!_ctx.user.externalUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _withDirectives(_createVNode(_component_InputText, {
                    id: "tokenLinkRef",
                    modelValue: _ctx.tokenLink,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tokenLink) = $event)),
                    modelModifiers: { trim: true },
                    required: "true",
                    ref: "tokenLinkRef"
                  }, null, 8, ["modelValue"]), [
                    [_vShow, _ctx.showCopyLink]
                  ]),
                  _withDirectives(_createVNode(_component_Button, {
                    icon: "mdi mdi-content-copy",
                    onClick: _ctx.onClickCopy
                  }, null, 8, ["onClick"]), [
                    [_vShow, _ctx.showCopyLink]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.user.externalUser)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.store.state.isAdminUser === true && _ctx.basicAuthentication)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_Button, {
                        label: _ctx.$t('Squeeze.UserManagement.ResetPassword'),
                        type: "button",
                        icon: "mdi mdi-lock-reset",
                        class: "p-button",
                        onClick: _ctx.openResetDialog,
                        disabled: !_ctx.userId
                      }, null, 8, ["label", "onClick", "disabled"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Button, {
                    label: _ctx.$t('Squeeze.General.Save'),
                    type: "button",
                    icon: "mdi mdi-content-save-outline",
                    class: "p-button",
                    onClick: _ctx.saveUser
                  }, null, 8, ["label", "onClick"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["blocked"])
    ]),
    _createVNode(_component_EntryDialog, {
      show: _ctx.showDialog,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false)),
      onOnConfirm: _ctx.sendResetMail,
      loading: _ctx.loadingDialog,
      "save-button-text": _ctx.$t('Squeeze.Login.Send'),
      headerText: _ctx.$t('Squeeze.UserManagement.ResetPassword')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_Message, {
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('Squeeze.UserManagement.ResetPasswordInfo')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "onOnConfirm", "loading", "save-button-text", "headerText"])
  ], 64))
}