
import {defineComponent, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import SystemResetForm from "@/apps/administration/components/system/SystemResetForm.vue";

export default defineComponent({
	name: "SystemResetView",
	components: {SystemResetForm, EntryDialog, BlockUI},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on refresh */
		const loadingDialog = ref(false);

		/** Is the password invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Show invalid password text */
		const showInvalidPassword = ref(false);

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Service for getting all system services */
		const systemService = ClientManager.getInstance().squeeze.system;

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.SpecifyEntry', { entryName: t('Squeeze.Login.Password') }));

		/** Password to run Reset */
		const resetPassword = ref<string>('');

		/**
		 * Triggered when an entry should be shown
		 * @param password
		 */
		const openEntryDialog = (password: string) => {
			Object.assign(resetPassword, password);
			showDialog.value = true;
		}

		/** Run System reset */
		const runReset = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;

			systemService.resetSystem(resetPassword.value)
				.then(() => {
					showDialog.value = false;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.System.ResetSuccess'));
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					showInvalidPassword.value = true;
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingDialog.value = false;
				})
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: { password: string }, isInvalid: boolean) => {
			showInvalidPassword.value = false;
			isValidationInvalid.value = isInvalid;
			resetPassword.value = data.password;
		}

		return {t, toast, loading, loadingDialog, isValidationInvalid, showDialog, showValidationMessage, showInvalidPassword, headerText, openEntryDialog, runReset, onUpdate};
	},
});

