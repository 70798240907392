import {RouteRecordRaw} from "vue-router";
import App from "../App.vue";
import Debug from "@/apps/debug/views/Debug.vue";
import {BFF} from "@/apis/dexp/BFFClient";

/**
 * @param pathBase URL path that defines this app.
 */
export function createRoutes(pathBase: string): Array<RouteRecordRaw> {
	return [
		{
			path: pathBase,
			name: "Dexpro-Platform",
			component: App,
			meta: {
				requiresAuth: false,
			},
			children: [
				{
					path: "",
					name: "Debug",
					component: Debug,
					meta: {
						requiresAuth: false,
					},
					props: {
						greeting: "Debug",
					},
				},
				{
					path: "login",
					name: "DebugLogin",
					component: Debug,
					meta: {
						requiresAuth: false,
					},
					props: {
						greeting: "You must login",
					},
				},
				{
					path: "private",
					name: "DebugPrivate",
					component: Debug,
					meta: {
						requiresAuth: false,
					},
					props: {
						greeting: "You are seeing private content",
					},
					beforeEnter: async (to, from, next) => {
						const isLoggedIn = await BFF.isLoggedIn();
						if (isLoggedIn) {
							return next();
						} else {
							return next("/app/debug/login");
						}
					},
				},
			],
		},
	];
}
