
/* eslint max-lines: off */
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DialogDelete from "@/components/DialogDelete.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import BlockUI from "primevue/blockui";
import EntryDialog from "@/components/EntryDialog.vue";
import TableForm from "@/apps/administration/components/documentclasses/TableForm.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {
	DocumentClassDto,
	DocumentLocator,
	DocumentTable,
	LineItemLocatorDetails
} from "@dex/squeeze-client-ts";
import TableBehaviourEnum = DocumentTable.TableBehaviourEnum;
import {ToastManager} from "@/util/ToastManager";
import DocumentClassTableColumnsSubView from "@/apps/administration/views/squeeze/documentclasses/DocumentClassTableColumnsSubView.vue";

export default defineComponent({
	name: 'DocumentClassTablesSubView',
	components: {
		DialogDelete, TabView, TabPanel, BlockUI,
		EntryDialog, TableForm, DocumentClassTableColumnsSubView,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Service for getting the documentclass-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** All Document-Class-Tables */
		const documentClassTables =  ref<DocumentTable[]>([]);

		/** List of all locators **/
		const locators = ref<DocumentLocator[]>([]);

		/** Show Loading in view? */
		const loading = ref<boolean>(false);

		/** Should the Entry-Dialog for fields be shown? */
		const showCurrentTableDialog = ref<boolean>(false);

		/** Entry-Data for Table */
		const documentClassTableEntry = reactive<DocumentTable>({
			id: undefined,
			documentClassId: props.documentClassId,
			name: '',
			description: '',
			locatorId: 0,
			mandatory: false,
			readonly: false,
			hidden: false,
			forceValidation: false,
			externalName: '',
			fieldGroupId: 0,
			tableBehaviour: TableBehaviourEnum.Dynamic,
		});

		/** Currently active tab */
		const activeTabIndex = ref<number>(0);

		/** Should the Delete-Dialog for Field-Groups be shown? */
		const showDialogDeleteTable = ref<boolean>(false);

		/** Message To Show in Entry-Dialogs */
		const message = ref<string>('');

		/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
		const messageType = ref<string>('');

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show the loading for Save-Button of Tables? */
		const loadingSaveTable = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(false);

		/** list of tables with locator */
		const tablesWithLocators = ref<DocumentTable[]>([]);

		/** All document classes */
		const allDocumentClasses = ref<DocumentClassDto[]>([]);

		/** List of all tables */
		const documentTables = ref<DocumentTable[]>([]);

		/** Currently active table in locator*/
		const activeTableId = ref<number>(-1);

		/** Resets the Default-Table */
		const initTableItem = () => {
			message.value = '';
			messageType.value = 'none';

			Object.assign(documentClassTableEntry, {
				id: undefined,
				documentClassId: props.documentClassId,
				name: '',
				description: '',
				locatorId: 23, // "Positionen"
				mandatory: false,
				readonly: false,
				hidden: false,
				forceValidation: false,
				externalName: '',
				fieldGroupId: 0,
				rows: [],
			});
		}

		/** Is triggered when a tab is changed */
		const onTabClick = (event: any) => {
			if (event.index === documentClassTables.value.length) {
				initTableItem();
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Table') });
				message.value = "";
				showCurrentTableDialog.value = true;
			}
		}

		/**
		 * Opens the Edit-Dialog for Tables
		 * @param {DocumentTable} rowData Data of the row to edit
		 */
		const openDialogDeleteTable = (rowData: DocumentTable) => {
			Object.assign(documentClassTableEntry, rowData);
			headerText.value = t('Squeeze.General.DeleteEntry', { entryName: t('Squeeze.DocumentClasses.Table') });
			showDialogDeleteTable.value = true;
		}

		/**
		 *  Is triggered when an entry should be edited/inserted from the Table
		 *  @param {DocumentTable} rowData Row to Save
		 */
		const openCurrentTableDialog = (rowData: DocumentTable) => {
			if (rowData.id) {
				headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.DocumentClasses.Table') }) +  ' (ID: ' + rowData.id + ')';
			} else {
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.DocumentClasses.Table') });
				rowData.documentClassId = props.documentClassId;
			}
			Object.assign(documentClassTableEntry, rowData);
			message.value = "";
			showCurrentTableDialog.value = true;
			tablesWithLocators.value = [];
		}

		/** Get document classes data */
		const getDocumentClassesData = async () => {
			let getAllDocumentClasses = undefined;
			if (allDocumentClasses.value.length === 0) {
				// get all document classes
				getAllDocumentClasses = documentClassService.getAllDocumentClasses()
					.then(data => {
						allDocumentClasses.value = data;
					})
					.catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
					});
			}

			let getAllDocumentClassesTables = undefined;
			if (documentTables.value.length === 0) {
				// get all document class tables
				getAllDocumentClassesTables = documentClassService.getDocumentClassTablesGlobal()
					.then(data => {
						documentTables.value = data;
					})
					.catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
					});
			}

			// Wait until promises are finished
			await Promise.all([getAllDocumentClasses, getAllDocumentClassesTables]);
		}

		/**
		 * Triggered on update of attribute-form field
		 * @param {DocumentTable} data
		 * @param {boolean} valid
		 */
		const onUpdateTable = async (data: DocumentTable, valid: boolean) => {
			if (data.locatorId) {
				const getLocatorTableRefs = locatorService.getLocatorTableRefs(data.locatorId)
					.then(tables => {
						tablesWithLocators.value = tables.filter(table => table.id !== data.id);
					})
					.catch(() => {
						return;
					})

				const getLineItemsLocatorDetails = locatorService.getLineItemLocatorDetails(data.locatorId)
					.then((lineItemDetails: LineItemLocatorDetails) => {
						activeTableId.value = lineItemDetails.tableId!;
					})
					.catch(() => {
						return;
					})

				// Wait until promises are finished
				await Promise.all([getLocatorTableRefs, getLineItemsLocatorDetails]);

				await getDocumentClassesData();
			} else {
				tablesWithLocators.value = [];
				activeTableId.value = -1;
			}
			isInvalid.value = valid;
			Object.assign(documentClassTableEntry, data);
		}

		/**
		 * Gets the Fields for a Document-Class and shows them
		 * @param {Number} documentClassID Id of the Document-Class to get the fields from
		 * @param {Boolean} showLoadingAnimation Should the loading animation be shown?
		 */
		const getDocumentClassTables = (documentClassID: number, showLoadingAnimation: boolean) => {
			loading.value = showLoadingAnimation;

			documentClassService.getAllDocumentClassTables(documentClassID)
				.then(data => {
					documentClassTables.value = data;

					// TODO: Tech Debt Fabian: Return Columns in Request for Tables?
					// Get all Columns for Table
					return Promise.all(documentClassTables.value.map(table => {
						if (table.id) {
							return documentClassService.getAllDocumentClassTableColumns(props.documentClassId, table.id)
								.then(cols => {
									table.columns = cols;
								})
						}
					}))
				})
				.catch((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				})
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Saves a new table
		 * @param {boolean} reloadTableData Is the Save triggered from outside the Entry-Dialog?
		 */
		const saveTable = (reloadTableData: boolean) => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			showErrorMessage.value = false;
			loadingSaveTable.value = true;
			if (documentClassTableEntry && documentClassTableEntry.id) {
				documentClassService.putDocumentClassTable(props.documentClassId, Number(documentClassTableEntry.id), documentClassTableEntry)
					.then(() => {
						showCurrentTableDialog.value = false;
						if (reloadTableData) {
							getDocumentClassTables(props.documentClassId, false);
						}
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);

						// Undo the current changes, if there is an error
						getDocumentClassTables(props.documentClassId, false);
					}).finally(() => {
						loadingSaveTable.value = false;
					})
			} else {
				documentClassService.postDocumentClassTable(props.documentClassId, documentClassTableEntry)
					.then(() =>{
						showCurrentTableDialog.value = false;
						if (reloadTableData) {
							getDocumentClassTables(props.documentClassId, false);
						}
					}).catch((err: {message: string}) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.SaveError') + ": " + err.message);

						// Undo the current changes, if there is an error
						getDocumentClassTables(props.documentClassId, false);
					}).finally(() => {
						loadingSaveTable.value = false;
					})
			}
		}

		/**
		 * Deletes a Table
		 * @param {DocumentTable} rowData Row-Data of the Table to delete
		 */
		const deleteTable = (rowData: DocumentTable) => {
			loadingSaveTable.value = true;
			documentClassService.deleteDocumentClassTable(props.documentClassId, Number(rowData.id))
				.then(() =>{
					showCurrentTableDialog.value = false;
					getDocumentClassTables(props.documentClassId, false);
				}).catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.DeleteError') + ": " + err.message);
				}).finally(() => {
					loadingSaveTable.value = false;
				})
		}

		/** Reloads the full dataset needed for field-groups */
		const reloadData = () => {
			loadingSaveTable.value = true;
			loading.value = true;
			locatorService.getAllLocators()
				.then(data => {
					locators.value = data;
					getDocumentClassTables(props.documentClassId, true);
				}).catch((err: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}).finally(() => {
					loadingSaveTable.value = false;
				})
		}

		/**
		 * Gets the Locators from Type 'Search for line items'
		 * @param {DocumentLocator[]} locators List of Locators
		 */
		const getLineLocators = (locators: DocumentLocator[]) => {
			if (locators) {
				return locators.filter(locator => locator.locatorType === 8);
			}

			return [];
		}

		onMounted(() =>{
			reloadData();
		})

		return {
			t,
			toast,
			documentClassTables,
			locators,
			loading,
			showCurrentTableDialog,
			documentClassTableEntry,
			activeTabIndex,
			showDialogDeleteTable,
			message,
			messageType,
			headerText,
			loadingSaveTable,
			showErrorMessage,
			isInvalid,
			tablesWithLocators,
			allDocumentClasses,
			documentTables,
			activeTableId,
			initTableItem,
			onTabClick,
			openDialogDeleteTable,
			openCurrentTableDialog,
			getDocumentClassesData,
			onUpdateTable,
			getDocumentClassTables,
			saveTable,
			deleteTable,
			reloadData,
			getLineLocators,
		};
	},
});

