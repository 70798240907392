
import {useToast} from "primevue/usetoast";
import {useI18n} from "vue-i18n";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Tooltip from "primevue/tooltip";
import {defineComponent, inject, PropType, ref} from "vue";
import {PaginationDto} from "@dex/squeeze-client-ts";
import {
	FreezeDocumentExportType,
	FreezeExportDocuments,
	FreezeSearchMask,
	FreezeSearchMaskField
} from "@/apis/freeze/Types";
import {ToastManager} from "@/util/ToastManager";
import Dialog from "primevue/dialog";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import InputText from "primevue/inputtext";
import {EasClientKey, FreezeClientKey} from "@/apps/freeze/DI";
import {createPdfUrlPath} from "@/util/BlobHelper";
import DownloadHitList from "@/apps/freeze/components/DownloadHitList.vue";

/**
 * @description Uses Elastic Search API endpoint instead of Queue API endpoint
 */
export default defineComponent({
	name: "RecordList",
	components: {
		DataTable,
		Column,
		Dialog,
		InputText,
		DownloadHitList,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		pagination: {
			type: Object as PropType<PaginationDto>,
			default: () => ({}),
		},
		records: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
		totalSize: {
			type: Number,
			default: 0,
		},
		searchFields: {
			type: Array as PropType<FreezeSearchMaskField[]>,
			default: () => [],
		},
		storeId: {
			type: String,
			default: "",
		},
		fullText: {
			type: String,
			default: '',
		},
		firstEntry: {
			type: Number,
			default: 0,
		},
		searchMaskId: {
			type: String,
			default: '',
		},
		searchQuery: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		sortField: {
			type: String,
			default: '',
		},
		sortOrder: {
			type: String,
			default: '',
		},
		filterOptions: Object as PropType<DocumentFilterObject>,
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onRowSelect", "onReload", "onPage", "onFilter", "onSort", "update:fullText", "clearSearch"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const { locale } = useI18n({ useScope: 'global' })
		// const store = useStore();
		const toast = useToast();

		/** Array with the expanded rows-data */
		const expandedRows = ref<any[]>([]);

		const refArray = ref<any[]>([]) as any;

		/** Currently-Selected row */
		const selection = ref<any>([]);

		/** Show Document Popup? */
		const showDocument = ref(false);

		/** Show Download Popup? */
		const showDownload = ref(false);

		/** Current selected Document in base64 */
		const currentDocumentBase64 = ref('');

		/** Currently selected Document Name */
		const currentDocumentName = ref('');

		/** Api for Squeeze-Requests */
		const freezeApi = inject(FreezeClientKey)!
		const easApi = inject(EasClientKey)!;

		/** Filters of list */
		const filters = ref<DocumentFilterObject>(props.filterOptions!);

		/** Triggered when the next page is selected */
		const onPage = (event: any) => {
			emit("onPage", event)
		}

		/** Triggered on sort a column */
		const onSort = (event: { sortField: string; sortOrder: string }) => {
			emit("onSort", event.sortField, event.sortOrder)
		}

		/** Triggered on Filter of column */
		const onFilter = (tableFilters: DocumentFilterObject) => {
			emit("onFilter", tableFilters);
		}

		/**
		 * Triggered when a row is clicked
		 * @param event
		 */
		const onRowClick = (event: { originalEvent: MouseEvent; data: any; index: number }) => {
			// Row-click events, if not clicked on the expander for expand
			// Prevent the row-click in the selection-column with the checkbox
			if (event.originalEvent
				&& !(event.originalEvent.target as HTMLElement).matches('.expander')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-selection-column')
				&& !(event.originalEvent.target as HTMLElement).matches('.p-checkbox *')
				&& !(event.originalEvent.target as HTMLElement).matches('svg')
				&& !(event.originalEvent.target as HTMLElement).matches('svg *')) {
				emit("onRowSelect", event.data);
			}
			return;
		}

		/** Triggered when a row is expanded */
		const onRowExpand = async function(event: { originalEvent: MouseEvent; data: any; index: number }) {
			const record = event.data;
			if (record.attachments == null) {
				try {
					record.attachmentsLoading = true;
					record.attachments = await easApi.getRecordAttachments(props.storeId!, record.id);

					//record.attachments.forEach((attachment: any) => record.url = `${ClientManager.getInstance().freezeUrl}/eas/archives/${props.storeId}/record/${record.id}/attachment/${attachment.id}`)

					for (const attachment of record.attachments) {
						const documentBase64 = await easApi.getAttachment(props.storeId!, record.id, attachment.id!);
						attachment.documentBase64 = documentBase64;
					}

					if (record.attachments && record.attachments[0]) {
						const firstAttachment = record.attachments[0];
						record.currentDocumentBase64 = firstAttachment.documentBase64;
						record.currentDocumentName = firstAttachment.name;
					}
				} catch (err) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err); // FIXME: Localization
				} finally {
					record.attachmentsLoading = false;
				}
			}
		}

		/** Triggered when a row is closed */
		const onRowCollapse = async function(event: { originalEvent: { originalEvent: MouseEvent }; data: any; index: number }) {
			// Do nothing as of now
		}

		/**
		 * Shows the overlay
		 * @param record
		 * @param base64Document
		 * @param documentName
		 */
		const onMouseOverAttachment = (record: any, base64Document: string, documentName: string) => {
			record.currentDocumentBase64 = base64Document;
			record.currentDocumentName = documentName;
		}

		/**
		 * Opens a document in wider view
		 * @param base64Document
		 * @param documentName
		 */
		const openDocument = (base64Document: string, documentName: string) => {
			if (!documentName.toLowerCase().includes(".pdf")) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Freeze.SearchMask.OnlyPdf'));
				return;
			}

			currentDocumentBase64.value = base64Document;
			showDocument.value = true;
			currentDocumentName.value = documentName;
		}

		/** Triggered when table content should be reloaded */
		const onReload = () => {
			emit("onReload");
		}

		/** Clears the current search */
		const clearSearch = () => {
			emit("clearSearch");
		}

		/**
		 * Formats a date from default JSON-Datestring
		 * @param dateToFormat
		 */
		const formatDate = (dateToFormat: string) => {
			if(dateToFormat && dateToFormat.length) {
				const dateNumber = Date.parse(dateToFormat);

				if(!isNaN(dateNumber)) {
					const date = new Date(dateToFormat);
					const options: any = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" };
					return date.toLocaleDateString(locale.value.toLowerCase() + '-' + locale.value.toUpperCase(), options);
				}
			}
			return dateToFormat;
		}

		/**
		 * Open the Download-Dialog
		 */
		const openDownload = () => {
			showDownload.value = true;
		}

		/**
		 * Triggers the download of a document
		 * @param searchMask
		 * @param downloadType
		 * @param delimiter
		 */
		const downloadDocuments = async (searchMask: FreezeSearchMask, downloadType: FreezeDocumentExportType, delimiter: string) => {
			const exportDocument = {
				filter: {
					ids: [],
					queryString: '',
				},
				csv: {
					fields: [],
					delimiter: delimiter,
				},
			} as FreezeExportDocuments;

			if (downloadType === FreezeDocumentExportType.Selected) {
				exportDocument.filter.ids = selection.value.map((select: any) => select.id);
			}
			else if (downloadType === FreezeDocumentExportType.All) {
				let sortQuery = "";

				if (props.sortField && props.sortOrder) {
					sortQuery = '&sort=' + props.sortField + '&sortOrder=' + props.sortOrder;
				}

				const query = "?query=" + props.searchQuery.join(' AND ')
					+ "&startIndex=" + (props.pagination?.pageSize! * props.pagination?.page!)
					+ "&itemsPerPage=100" + sortQuery;

				exportDocument.filter.queryString = query;
			}

			exportDocument.csv.fields = searchMask.fields.map(field => field.name);

			try {
				const blob = await freezeApi.downloadDocuments(props.storeId, exportDocument);
				// Open Download
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.setAttribute('href', url);
				link.setAttribute('download', 'Freeze-Export.zip');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				showDownload.value = false;
			}catch (err) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err);
			}
		}

		return {
			showDocument,
			currentDocumentBase64,
			currentDocumentName,
			expandedRows,
			selection,
			showDownload,
			filters,
			emit,
			clearSearch,
			formatDate,
			onReload,
			onPage,
			onRowClick,
			onRowExpand,
			onRowCollapse,
			onSort,
			onMouseOverAttachment,
			openDocument,
			onFilter,
			createPdfUrlPath,
			openDownload,
			downloadDocuments,
			refArray,
		}
	},
});
