
import {defineComponent, PropType, ref} from "vue";
import {useI18n} from "vue-i18n";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {FieldTrainingWithName} from "@/apps/squeeze/views/HeadTraining.vue";
import {DocumentField} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "DocumentFieldTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		fieldTestingValues: {
			type: Array as PropType<FieldTrainingWithName[]>,
			default: () => [],
		},
		tableTrainingValues: {
			type: Array as PropType<FieldTrainingWithName[]>,
			default: () => [],
		},
		clickOnTestingButton: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
	},
	emits: [
		'deleteTrainingRow',
		'changeTrainingRow',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Currently-Selected row */
		const selection = ref(null);

		/**
		 * Deletes a row of the training table
		 * @param {FieldTrainingWithName} rowData
		 */
		const deleteTrainingRow = (rowData: FieldTrainingWithName) => {
			emit("deleteTrainingRow", rowData);
		}

		/**
		 * Change a training value
		 * @param {FieldTrainingWithName} rowData
		 */
		const changeTrainingRow = (rowData: FieldTrainingWithName) => {
			emit("changeTrainingRow", rowData);
		}

		/**
		 * Click a testing row to mark
		 * @param event
		 */
		const onRowSelect = (event: any) => {
			emit("onMarkRegion", event);
		}

		/**
		 * Check if the field is not trainable to show a warning
		 * @param fieldName
		 */
		const checkIfFieldIsNotTrainable = (fieldName: string) => {
			const field = props.documentClassFields.find((field: DocumentField) => field.description === fieldName);
			return (field && !field.trainable);
		}

		return {
			t,
			selection,
			deleteTrainingRow,
			changeTrainingRow,
			onRowSelect,
			checkIfFieldIsNotTrainable,
		}
	},
});
