
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import BlockUI from "primevue/blockui";
import {BatchClass, ErrorDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import BatchClassForm from "@/apps/administration/components/batchclasses/BatchClassForm.vue";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import router from "@/router";

export default defineComponent({
	name: "BatchClassSubView",
	components: {
		BlockUI, BatchClassForm,
	},
	props: {
		batchClassId: {
			type: Number,
		},
	},
	setup(props) {
		/** Batch-Class-Api */
		const batchClassApi = ClientManager.getInstance().squeeze.batchClass;

		const {t} = useI18n();
		const toast = useToast();

		/** Object of batchClass */
		const batchClass = reactive<BatchClass>({ name: "", description: ""})

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Show loading in table? */
		const loading = ref(false);

		/** Triggered the valid of form */
		const isInvalid = ref(true);

		/**  Translations of the Save-Button */
		const saveButtonText = computed(() => {
			// If there is no batch-class id, it's a new entry
			if (!props.batchClassId) {
				return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.BatchClasses.BatchClass') })
			}

			return t('Squeeze.General.Save');
		})

		/** Get all Batch-Classes */
		const getBatchClass = () => {
			if (!props.batchClassId) {
				return;
			}

			loading.value = true;
			batchClassApi.getBatchClassById(props.batchClassId).then(data => {
				Object.assign(batchClass, data);
			}).finally(() => {
				loading.value = false;
			})
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => props.batchClassId, () => {
			getBatchClass();
		})

		/** Triggered on update of attribute-form
		 * @param data
		 * @param valid
		 */
		const onUpdate = (data: BatchClass, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(batchClass, data);
		}

		/** Saves or creates a Batch-Class */
		const saveBatchClass = () => {
			loading.value = true;
			if (!isInvalid.value) {
				if (batchClass.id) {
					batchClassApi.putBatchClass(batchClass.id, batchClass)
						.then(() => {
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
						}).catch(response => response.json().then ((err: { message: string }) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
				}
				else {
					batchClassApi.postNewBatchClass(batchClass)
						.then((data) => {
							ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
							if (data.id) {
								loading.value = false;
								router.push({ name: 'BatchClassView', params: { batchClassId: data.id }});
							}
						})
						.catch(response => response.json().then ((err: ErrorDto) => {
							ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
						}))
				}
			} else {
				showErrorMessage.value = true;
			}
			loading.value = false;
		}

		onMounted(() => {
			getBatchClass();
		});

		return {
			getBatchClass, batchClass, loading, onUpdate, saveButtonText, saveBatchClass, isInvalid, showErrorMessage,
		}
	},
});
