
import {computed, defineComponent, ref, watch} from 'vue';
import Listbox from 'primevue/listbox';
import Avatar from 'primevue/avatar';
import ViewSettings from "@/components/settingDialog/ViewSettings.vue";
import UserAccount from "@/components/settingDialog/UserAccount.vue";
import {useI18n} from "vue-i18n";
import UserApiKeysView from "@/components/settingDialog/UserApiKeysView.vue";
import SystemInformation from "@/apps/administration/components/system/SystemInformation.vue";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: 'UserSettings',
	components: {SystemInformation, UserApiKeysView, ViewSettings, UserAccount, Listbox, Avatar },
	props: {
		loggedInUserInitials: {
			type: String,
			default: '',
		},
	},
	emits: ['clickSettingDialog'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const store = useSqueezeStore();

		/** Array of setting-MenuItems */
		const settingMenuItems = computed(() => {
			return [
				{name: t('Squeeze.UserSettings.Account.UserAccount'), code: 'Account'},
				{name: t('Squeeze.UserSettings.Presentation'), code: 'View'},
				{name: t('Squeeze.UserManagement.MyApiKeys'), code: 'ApiKey'},
				{name: t('Squeeze.System.System'), code: 'SystemInfo'},
			]
		});

		/** Currently selected menuItem */
		const selectedMenuItem = ref<string>('View');

		/** Emit of click on close button */
		const clickSettingDialog = () => {
			emit('clickSettingDialog', false);
		}

		/** Watch on selected menuItem, so that show 'View' ever */
		watch(selectedMenuItem, () => {
			if(!selectedMenuItem.value) {
				selectedMenuItem.value = 'View';
			}
		})

		return { store, settingMenuItems, selectedMenuItem, clickSettingDialog };
	},
});

