
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {
	DbLinkLocatorDetails, DocumentClassDto, DocumentField,
	DocumentLocator, ErrorDto,
	LocatorSourceTable,
	MasterDataColumn,
	MasterDataTable
} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import DBLinkedData from "@/apps/administration/components/locators/tabs/DBLinkedData.vue";
import DBLinkedDataForm from "@/apps/administration/components/locators/tabs/DBLinkedDataForm.vue";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "DBLinkedDataView",
	components: {
		DBLinkedDataForm, DBLinkedData, DialogDelete, EntryDialog, BlockUI, Dropdown, Message,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the DbLinkedDataEntries invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** List of all DbLinkedDataEntries */
		const DbLinkedDataEntries = ref<DbLinkLocatorDetails[]>([]);

		/** Should the Entry-Dialog for DbLinkedDataEntries be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active DBLinkedData */
		const DBLinkedData = reactive<DbLinkLocatorDetails>({
			id: 0,
			sortOrder: 0,
			locatorId: 0,
			sourceLocatorId: 0,
			dbcolumn: 0,
			stopIfUnique: true,
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** List of all Master data tables */
		const masterDataTables = ref<MasterDataTable[]>([]);

		/** Service for getting the master-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** Service for getting the document-class-data */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** ID of the masterDataTable */
		const masterDataTableId = ref<number>(0);

		/** ID of the masterDataTableColumn */
		const masterDataTableColumnId = ref<number>(0);

		/** ID of the tableKeyColumnFilter */
		const tableKeyColumnFilterId = ref<number|undefined>(0);

		/** ID of the tableKeyFieldFilter */
		const tableKeyFieldFilterId = ref<number|undefined>(0);

		/** ID of the tableKeyLocatorFilterId */
		const tableKeyLocatorFilterId = ref<number|undefined>(0);

		/** List of all Locators */
		const allLocators = ref<DocumentLocator[]>([]);

		/** List of all masterData columns */
		const masterDataColumns = ref<MasterDataColumn[]>([]);

		/** List of all field of all document classes */
		const documentClassFields = ref<DocumentField[]>([]);

		/** List of all Document Classes */
		const allDocumentClasses = ref <DocumentClassDto[]>([]);

		/** Currently active SourceTable */
		const sourceTable = reactive<LocatorSourceTable>({
			locatorId: 0,
			tableId: 0,
			tableColumnId: 0,
			tableKeyColumnFilter: undefined,
			tableKeyFieldFilter: undefined,
			tableKeyLocatorFilter: undefined,
		});

		/** Get all Locators */
		const getAllLocators = async () => {
			await locatorService.getAllLocators()
				.then((data: DocumentLocator[]) => {
					allLocators.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Gets all Document Classes */
		const getAllDocumentClasses = async () => {
			await documentClassService.getAllDocumentClasses()
				.then(data => {
					allDocumentClasses.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get all Fields from all Document Classes */
		const getAllDocumentClassFields = async () => {
			await documentClassService.getAllFields()
				.then((data: DocumentLocator[]) => {
					documentClassFields.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get all MasterData TableColumns */
		const getMasterDataTableColumns = async () => {
			await masterDataService.getMasterDataTableColumns(masterDataTableId.value)
				.then((data: MasterDataColumn[]) => {
					masterDataColumns.value = data;
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Get all masterDataTables */
		const getMasterDataTables = async () => {
			await masterDataService.getAllMasterDataTables()
				.then((data: MasterDataTable[]) => {
					masterDataTables.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: DbLinkLocatorDetails, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(DBLinkedData, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.DBLinkedData') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: DbLinkLocatorDetails) => {
			Object.assign(DBLinkedData, row);
			// make sure locatorId always right
			DBLinkedData.locatorId = props.locatorId;
			showDialog.value = true;
		}

		/** Reloads the table to get all DBLinkedData */
		const reloadData = async () => {
			await locatorService.getDbLinkLocatorDetails(props.locatorId)
				.then((data: DbLinkLocatorDetails[]) => {
					// check if masterDataTable not exists
					if (masterDataTableId.value === 0) {
						DbLinkedDataEntries.value = [];
					} else {
						DbLinkedDataEntries.value = data;
					}
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Triggered when rows are reordered */
		const onChangeSortOrder = (orderedList: number[]) => {
			loading.value = true;
			locatorService.putDbLinkLocatorDetailsSortOrder(props.locatorId, {elements: orderedList})
				.then(() => {
					reloadData();
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get data of sourceTable */
		const getDataOfSourceTable = async () => {
			await locatorService.getSourceTable(props.locatorId)
				.then(async (data: LocatorSourceTable) => {
					Object.assign(sourceTable, data);
					masterDataTableId.value = sourceTable.tableId;
					masterDataTableColumnId.value = sourceTable.tableColumnId;
					tableKeyColumnFilterId.value = sourceTable.tableKeyColumnFilter;
					tableKeyLocatorFilterId.value = sourceTable.tableKeyLocatorFilter;
					tableKeyFieldFilterId.value = sourceTable.tableKeyFieldFilter;

					await getMasterDataTableColumns();
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		/** Save sourceTable */
		const saveSourceTable = () => {
			loading.value = true;

			// check if masterTable is changed
			if (sourceTable.tableId !== masterDataTableId.value) {
				DbLinkedDataEntries.value = [];
			}

			sourceTable.tableId = masterDataTableId.value;
			sourceTable.tableColumnId = masterDataTableColumnId.value;
			sourceTable.tableKeyColumnFilter = tableKeyColumnFilterId.value;
			sourceTable.tableKeyFieldFilter = tableKeyFieldFilterId.value;
			sourceTable.tableKeyLocatorFilter = tableKeyLocatorFilterId.value;

			locatorService.putSourceTable(props.locatorId, sourceTable)
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(async () => {
			loading.value = true;
			try {
				await Promise.all([
					getAllDocumentClasses(),
					getMasterDataTables(),
					getAllLocators(),
					getAllDocumentClassFields(),
					getDataOfSourceTable(),
				])
				await reloadData();
			} finally {
				loading.value = false;
			}
		})

		/** Saves an DBLinkedData */
		const saveDbLinkLocator = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let DbLinkLocatorChangePromise = null;

			// make sure locatorId always right
			DBLinkedData.locatorId = props.locatorId;

			if (!DBLinkedData.id) {
				DbLinkLocatorChangePromise = locatorService.postDbLinkLocatorDetails(props.locatorId, DBLinkedData);
			} else {
				DbLinkLocatorChangePromise = locatorService.putDbLinkLocatorDetails(props.locatorId, DBLinkedData.id, DBLinkedData);
			}

			DbLinkLocatorChangePromise.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					DBLinkedData.id = 0;
					DBLinkedData.sortOrder = undefined;
					DBLinkedData.locatorId = undefined;
					DBLinkedData.sourceLocatorId = undefined;
					DBLinkedData.dbcolumn = undefined;
					DBLinkedData.stopIfUnique = true;

					isValidationInvalid.value = true;
				}
				reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: DbLinkLocatorDetails) => {
			deleteDialog.value = true;
			Object.assign(DBLinkedData, row);
		}

		/** Deletes a DBLinkedData */
		const deleteEntry = () => {
			if (DBLinkedData.id) {
				loading.value = true;
				locatorService.deleteDbLinkLocatorDetail(props.locatorId, DBLinkedData.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Change a masterDataTable */
		const onChangeMasterDataTable = async () => {
			// check if masterTable is changed
			if (sourceTable.tableId !== masterDataTableId.value) {
				DbLinkedDataEntries.value = [];
			}

			tableKeyColumnFilterId.value = undefined;

			await getMasterDataTableColumns();
			reloadData();
		}

		/**
		 * Gets the Label for Columns. Is used in the Dropdowns for the Columns
		 * @param field
		 */
		const getFieldLabel = (field: DocumentField): string => {
			const documentClass = allDocumentClasses.value.find(documentClass => documentClass.id === field.documentClassId);

			if (documentClass) {
				return field.description + " (ID: " + field.id +  ", " + t('Squeeze.DocumentClasses.DocumentClass') +": " + documentClass.description + ")";
			}else {
				return field.description + " (" + field.name + ")";
			}
		}

		return {
			loading,
			isValidationInvalid,
			showValidationMessage,
			loadingDialog,
			allLocators,
			masterDataColumns,
			sourceTable,
			showDialog,
			headerText,
			DbLinkedDataEntries,
			DBLinkedData,
			deleteDialog,
			masterDataTables,
			masterDataTableId,
			masterDataTableColumnId,
			tableKeyColumnFilterId,
			tableKeyFieldFilterId,
			tableKeyLocatorFilterId,
			documentClassFields,
			allDocumentClasses,
			getAllLocators,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onEntrySelect,
			saveSourceTable,
			saveDbLinkLocator,
			getMasterDataTables,
			onChangeMasterDataTable,
			onChangeSortOrder,
			getFieldLabel,
			showDropdownOverlay,
		};
	},
});

