
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Dropdown from "primevue/dropdown";
import {User} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "UserTakeOverRolesForm",
	components: {
		Dropdown,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		usersFrom: {
			type: Array as PropType<User[]>,
			required: true,
		},
		usersTo: {
			type: Array as PropType<User[]>,
			required: true,
		},
		usersTakeOverRoles: {
			type: Object,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all fields */
		const value = reactive<any>({});

		/** Determines the required rules for validation */
		const rules = {
			userIdFrom: { required },
			userIdTo: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		/**
		 * Gets the Label for Users.
		 * @param user current user
		 */
		const getUsersFullName = (user: User): string => {
			return user.firstName + ' ' + user.lastName;
		}

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.usersTakeOverRoles, () => {
			Object.assign(value, props.usersTakeOverRoles);
		})

		onMounted(async () => {
			Object.assign(value, props.usersTakeOverRoles);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		return {
			value,
			v$,
			getUsersFullName,
			update,
			showDropdownOverlay,
		}
	},
});

