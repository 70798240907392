
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {LocatorKeyword} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import KeyWordsForm from "@/apps/administration/components/locators/tabs/KeyWordsForm.vue";
import KeyWords from "@/apps/administration/components/locators/tabs/KeyWords.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "KeyWordsView",
	components: {
		KeyWordsForm, KeyWords, DialogDelete, EntryDialog, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const keyWordsForm = ref<InstanceType<typeof KeyWordsForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Keywords invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all keywords */
		const keywords = ref<LocatorKeyword[]>([]);

		/** Should the Entry-Dialog for keywords be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active keyword */
		const keyword = reactive<LocatorKeyword>({
			keyword: '',
		});

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Triggered on update of attribute-form */
		const onUpdate = (data: LocatorKeyword, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(keyword, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.KeyWords') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: LocatorKeyword) => {
			Object.assign(keyword, row);
			showDialog.value = true;
		}

		/** Reloads the table */
		const reloadData = async () => {
			loading.value = true;
			await locatorService.getLocatorKeywords(props.locatorId)
				.then(data => {
					keywords.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get Type of current locator on load */
		onMounted(() =>{
			reloadData();
		})

		/** Saves a Keyword */
		const saveKeyword = (keepDialogOpen: boolean = false, event: any = null) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let promise = null;

			// make sure locatorId always right
			keyword.locatorId = props.locatorId;

			if (!keyword.id) {
				promise = locatorService.postLocatorKeyword(props.locatorId, keyword);
			} else {
				promise = locatorService.putLocatorKeyword(props.locatorId, keyword.id, keyword);
			}

			promise.then(async () => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					keyword.keyword = '';
					keyWordsForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}
				await reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;

				if (event) {
					// Notify any listeners in case they need to wait for the completion of this request
					document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
				}
			})
		}

		/**
		 * Triggered on change of attribute-form
		 * @param event
		 * @param isInvalid
		 */
		const onChangeKeyword = (event: any, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(keyword, event.newData);
			saveKeyword(false, event);
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: LocatorKeyword) => {
			deleteDialog.value = true;
			Object.assign(keyword, row);
		}

		/** Deletes a Keyword */
		const deleteEntry = () => {
			if (keyword && keyword.id) {
				loading.value = true;
				locatorService.deleteLocatorKeyword(props.locatorId, keyword.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			loading,
			isValidationInvalid,
			showValidationMessage,
			loadingDialog,
			showDialog,
			headerText,
			keywords,
			keyword,
			deleteDialog,
			keyWordsForm,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onChangeKeyword,
			onEntrySelect,
			saveKeyword,
		};
	},
});

