
import {defineComponent, nextTick, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import {TranslationCountry} from "@dex/squeeze-client-ts";
import {required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {useSqueezeStore} from "@/apps/squeeze/store";

export default defineComponent({
	name: "LanguageForm",
	components: {
		InputText,
		Checkbox,
	},
	props: {
		language: {
			type: Object as PropType<TranslationCountry>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const store = useSqueezeStore();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<TranslationCountry>({
			countryCode: '',
			translatedDescription: '',
			translationKey: '',
			description: '',
			name: '',
			active: false,
		});

		/** Determines the required rules for validation */
		const rules = {
			countryCode: { required },
			description: { required },
			name: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.language);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.language, async () => {
			Object.assign(value, props.language);
			await nextTick();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			t,
			toast,
			store,
			loading,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});

