<template>
	<!-- Note: This a modified version of the TabMenu-Component from Prime Vue: https://github.com/primefaces/primevue/blob/master/src/components/fileupload/FileUpload.vue
	The "files" have been made reactive. Also the file has been extened to have loading, error und errorText-props  -->
	<div class="p-fileupload p-fileupload-advanced p-component" v-if="isAdvanced">
		<div class="p-fileupload-buttonbar">
			<div class="p-grid p-jc-between p-mt-0">
				<div class="p-col-12">
					<span :class="advancedChooseButtonClass" @click="choose" @keydown.enter="choose" @focus="onFocus" @blur="onBlur" v-ripple tabindex="0">
						<input ref="fileInput" type="file" @change="onFileSelect" :multiple="multiple" :accept="accept" :disabled="chooseDisabled" />
						<span class="p-button-icon p-button-icon-left mdi mdi-plus"></span>
						<span class="p-button-label">{{chooseButtonLabel}}</span>
					</span>
					<FileUploadButton :label="uploadButtonLabel" icon="mdi mdi-cloud-upload-outline" @click="upload" :disabled="uploadDisabled || customUploadDisabled" v-if="showUploadButton" />
					<slot name="content" v-bind:files="files"></slot>
					<FileUploadButton :label="cancelButtonLabel" icon="mdi mdi-close" @click="clear" :disabled="cancelDisabled" v-if="showCancelButton" />
				</div>
				<!--<div class="p-col-2 p-text-right mdi mdi-comment-question-outline p-col-align-center icon-alert" v-tooltip.bottom="$t('Squeeze.General.UploadNote')"></div>-->
			</div>
		</div>
		<div ref="content" class="p-fileupload-content" @dragenter="onDragEnter" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
			<FileUploadProgressBar :value="progress" v-if="hasFiles" />
			<FileUploadMessage v-for="msg of messages" severity="error" :key="msg">{{msg}}</FileUploadMessage>
			<div class="p-fileupload-files" v-if="hasFiles">
				<ScrollPanel :style="'width: 100%; height: ' + fileContentHeight">
					<div class="p-fileupload-row" v-for="(file, index) of files" :key="file.name + file.type + file.size">
						<div style="display: none">
							<img v-if="isImage(file)" role="presentation" :alt="file.name" :src="file.objectURL" :width="previewWidth" />
						</div>
						<div class="word-break-file-name">{{file.name}}</div>
						<div>{{formatSize(file.size)}}</div>
						<div v-if="file.error" style="color: darkred">{{file.errorText}}</div>
						<div v-if="file.message">{{file.message}}</div>
						<div v-if="file.uploadFinished === false && file.error === false">
							<i v-if="file.loading" class="mdi mdi-spin mdi-loading" style="fontSize: 2rem"></i>
							<FileUploadButton v-if="!file.loading"  type="button" icon="mdi mdi-close" @click="remove(index)" />
						</div>
						<div v-if="file.error">
							<FileUploadButton type="button" icon="mdi mdi-close" @click="remove(index)" style="background-color: darkred" />
						</div>
						<div v-if="file.uploadFinished === true && file.error === false">
							<i class="mdi mdi-check" style="fontSize: 1.5rem; color: darkgreen"></i>&nbsp;
							<!--<FileUploadButton v-if="!file.loading"  type="button" icon="mdi mdi-delete-outline"  style="background-color: darkred" @click="remove(index)" />-->
						</div>
					</div>
				</ScrollPanel>
			</div>
			<Divider v-if="hasFiles" />
			<div class="p-fileupload-empty">
				<div class="dragFiles" @click="choose" v-bind:class="{ drag: isDragged }">
					<i class="mdi mdi-cloud-upload-outline uploadImage"></i>
				</div>
			</div>
		</div>
	</div>
	<div class="p-fileupload p-fileupload-basic p-component" v-else-if="isBasic">
		<FileUploadMessage v-for="msg of messages" severity="error" :key="msg">{{msg}}</FileUploadMessage>
		<span :class="basicChooseButtonClass" @mouseup="onBasicUploaderClick"  @keydown.enter="choose" @focus="onFocus" @blur="onBlur" v-ripple tabindex="0" >
            <span :class="basicChooseButtonIconClass"></span>
            <span class="p-button-label">{{basicChooseButtonLabel}}</span>
            <input ref="fileInput" type="file" :accept="accept" :disabled="disabled" @change="onFileSelect" @focus="onFocus" @blur="onBlur" v-if="!hasFiles" />
        </span>
	</div>
</template>

<script>
/* eslint max-lines: off */
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message';
import {DomHandler} from 'primevue/utils';
import Ripple from 'primevue/ripple';
import ScrollPanel from 'primevue/scrollpanel';
import Divider from 'primevue/divider';

export default {
	emits: ['select', 'selectFiles', 'uploader', 'before-upload', 'progress', 'upload', 'error', 'before-send', 'clear', 'removeFile'],
	props: {
		name: {
			type: String,
			default: null,
		},
		url: {
			type: String,
			default: null,
		},
		mode: {
			type: String,
			default: 'advanced',
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		accept: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		auto: {
			type: Boolean,
			default: false,
		},
		maxFileSize: {
			type: Number,
			default: null,
		},
		invalidFileSizeMessage: {
			type: String,
			default: '{0}: Invalid file size, file size should be smaller than {1}.',
		},
		fileLimit: {
			type: Number,
			default: null,
		},
		invalidFileLimitMessage: {
			type: String,
			default: 'Maximum number of files exceeded, limit is {0} at most.',
		},
		withCredentials: {
			type: Boolean,
			default: false,
		},
		previewWidth: {
			type: Number,
			default: 50,
		},
		chooseLabel: {
			type: String,
			default: null,
		},
		uploadLabel: {
			type: String,
			default: null,
		},
		cancelLabel: {
			type: String,
			default: null,
		},
		customUpload: {
			type: Boolean,
			default: false,
		},
		showUploadButton: {
			type: Boolean,
			default: true,
		},
		showCancelButton: {
			type: Boolean,
			default: true,
		},
		uploadFiles: {
			type: Array,
			default: null,
		},
		customProgress: {
			type: Number,
			default: null,
		},
		fileContentHeight: {
			type: String,
			default: "15.625rem",
		},
		customUploadDisabled: {
			type: Boolean,
			default: false,
		},
	},
	duplicateIEEvent: false,
	data() {
		return {
			uploadedFileCount: 0,
			files: [],
			messages: [],
			focused: false,
			progress: null,
			isDragged: false,
		}
	},
	watch: {
		uploadFiles: function(newValue, oldValue) {
			this.files = [...newValue];
		},
		customProgress: function(newValue) {
			this.progress = newValue;
		},
	},
	methods: {
		onFileSelect(event) {
			if (event.type !== 'drop' && this.isIE11() && this.duplicateIEEvent) {
				this.duplicateIEEvent = false;
				return;
			}

			this.messages = [];
			this.files = this.files || [];
			const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
			for (const file of files) {
				if (!this.isFileSelected(file)) {
					if (this.validate(file)) {
						if (this.isImage(file)) {
							file.objectURL = window.URL.createObjectURL(file);
						}
						file.uploadFinished = false;
						file.error = false;
						file.errorText = "";
						file.loading = false;
						this.files.push(file);
					}
				}
			}

			this.$emit('select', {originalEvent: event, files: this.files});

			if (this.fileLimit) {
				this.checkFileLimit();
			}

			if (this.auto && this.hasFiles && !this.isFileLimitExceeded()) {
				this.upload();
			}

			if (event.type !== 'drop' && this.isIE11()) {
				this.clearIEInput();
			}
			else {
				this.clearInputElement();
			}
		},
		choose() {
			this.$refs.fileInput.click();
		},
		upload() {
			if (this.customUpload) {
				if (this.fileLimit) {
					this.uploadedFileCount += this.files.length;
				}

				this.$emit('uploader', {files: this.files});
			}
			else {
				const xhr = new XMLHttpRequest();
				const formData = new FormData();

				this.$emit('before-upload', {
					'xhr': xhr,
					'formData': formData,
				});

				for (const file of this.files) {
					formData.append(this.name, file, file.name);
				}

				xhr.upload.addEventListener('progress', (event) => {
					if (event.lengthComputable) {
						this.progress = Math.round((event.loaded * 100) / event.total);
					}

					this.$emit('progress', {
						originalEvent: event,
						progress: this.progress,
					});
				});

				xhr.onreadystatechange = () => {
					if (xhr.readyState === 4) {
						this.progress = 0;

						if (xhr.status >= 200 && xhr.status < 300) {
							if (this.fileLimit) {
								this.uploadedFileCount += this.files.length;
							}

							this.$emit('upload', {
								xhr: xhr,
								files: this.files,
							});
						}
						else {
							this.$emit('error', {
								xhr: xhr,
								files: this.files,
							});
						}

						this.clear();
					}
				};

				xhr.open('POST', this.url, true);

				this.$emit('before-send', {
					'xhr': xhr,
					'formData': formData,
				});

				xhr.withCredentials = this.withCredentials;

				xhr.send(formData);
			}
		},
		clear() {
			this.files = [];
			this.messages = null;
			this.$emit('clear');

			if (this.isAdvanced) {
				this.clearInputElement();
			}
		},
		onFocus() {
			this.focused = true;
		},
		onBlur() {
			this.focused = false;
		},
		isFileSelected(file) {
			if (this.files && this.files.length) {
				for (const sFile of this.files) {
					if ((sFile.name + sFile.type + sFile.size) === (file.name + file.type + file.size))
						return true;
				}
			}

			return false;
		},
		isIE11() {
			return !!window['MSInputMethodContext'] && !!document['documentMode'];
		},
		validate(file) {
			if (this.maxFileSize && file.size > this.maxFileSize) {
				this.messages.push(this.invalidFileSizeMessage.replace('{0}', file.name).replace('{1}', this.formatSize(this.maxFileSize)));
				return false;
			}

			return true;
		},
		onDragEnter(event) {
			if (!this.disabled) {
				event.stopPropagation();
				event.preventDefault();
				this.isDragged = true;
			}
		},
		onDragOver() {
			if (!this.disabled) {
				DomHandler.addClass(this.$refs.content, 'p-fileupload-highlight');
				event.stopPropagation();
				event.preventDefault();
				this.isDragged = true;
			}
		},
		onDragLeave() {
			if (!this.disabled) {
				DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
				this.isDragged = false;
			}
		},
		onDrop() {
			if (!this.disabled) {
				DomHandler.removeClass(this.$refs.content, 'p-fileupload-highlight');
				event.stopPropagation();
				event.preventDefault();
				this.isDragged = false;

				const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
				const allowDrop = this.multiple || (files && files.length === 1);

				if (allowDrop) {
					this.onFileSelect(event);
				}
			}
		},
		onBasicUploaderClick() {
			if (this.hasFiles)
				this.upload();
			else
				this.$refs.fileInput.click();
		},
		remove(index) {
			this.clearInputElement();
			this.files.splice(index, 1);
			this.files = [...this.files];
			this.$emit('removeFile', {originalEvent: event, files: this.files});
		},
		isImage(file) {
			return /^image\//.test(file.type);
		},
		clearInputElement() {
			this.$refs.fileInput.value = '';
		},
		clearIEInput() {
			if (this.$refs.fileInput) {
				this.duplicateIEEvent = true; //IE11 fix to prevent onFileChange trigger again
				this.$refs.fileInput.value = '';
			}
		},
		formatSize(bytes) {
			if (bytes === 0) {
				return '0 B';
			}
			const k = 1000,
				dm = 3,
				sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
				i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},
		isFileLimitExceeded() {
			if (this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount && this.focused) {
				this.focused = false;
			}

			return this.fileLimit && this.fileLimit < this.files.length + this.uploadedFileCount;
		},
		checkFileLimit() {
			if (this.isFileLimitExceeded()) {
				this.messages.push(this.invalidFileLimitMessage.replace('{0}', this.fileLimit.toString()))
			}
		},
	},
	computed: {
		isAdvanced() {
			return this.mode === 'advanced';
		},
		isBasic() {
			return this.mode === 'basic';
		},
		advancedChooseButtonClass() {
			return ['p-button p-component p-fileupload-choose', {
				'p-disabled': this.disabled,
				'p-focus': this.focused,
			}];
		},
		basicChooseButtonClass() {
			return ['p-button p-component p-fileupload-choose', {
				'p-fileupload-choose-selected': this.hasFiles,
				'p-disabled': this.disabled,
				'p-focus': this.focused,
			}];
		},
		basicChooseButtonIconClass() {
			return ['p-button-icon p-button-icon-left mdi', {
				'mdi mdi-plus': !this.hasFiles || this.auto,
				'mdi mdi-cloud-upload-outline': this.hasFiles && !this.auto,
			}];
		},
		basicChooseButtonLabel() {
			return this.auto ? this.chooseButtonLabel : (this.hasFiles ? this.files[0].name : this.chooseButtonLabel);
		},
		hasFiles() {
			return this.files && this.files.length > 0;
		},
		chooseDisabled() {
			return this.disabled || (this.fileLimit && this.fileLimit <= this.files.length + this.uploadedFileCount);
		},
		uploadDisabled() {
			return this.disabled || !this.hasFiles;
		},
		cancelDisabled() {
			return this.disabled || !this.hasFiles;
		},
		chooseButtonLabel() {
			return this.chooseLabel || this.$primevue.config.locale.choose;
		},
		uploadButtonLabel() {
			return this.uploadLabel || this.$primevue.config.locale.upload;
		},
		cancelButtonLabel() {
			return this.cancelLabel || this.$primevue.config.locale.cancel;
		},
	},
	components: {
		'FileUploadButton': Button,
		'FileUploadProgressBar': ProgressBar,
		'FileUploadMessage': Message,
		'ScrollPanel': ScrollPanel,
		'Divider': Divider,
	},
	directives: {
		'ripple': Ripple,
	},
}
</script>
<style>
.p-fileupload-content {
	position: relative;
}

.p-fileupload-row {
	display: flex;
	align-items: center;
}

.p-fileupload-row > div {
	flex: 1 1 auto;
	width: 25%;
}

.p-fileupload-row > div.word-break-file-name {
	max-width: 50%;
	width: auto;
	min-width: 50%;
}

.p-fileupload-row > div:last-child {
	text-align: right;
}

.p-fileupload-content .p-progressbar {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.p-button.p-fileupload-choose {
	position: relative;
	overflow: hidden;
}

.p-button.p-fileupload-choose input[type=file] {
	display: none;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type=file] {
	display: none;
}

.p-fluid .p-fileupload .p-button {
	width: auto;
}

.dragFiles {
	height: 100px;
	width: 100%;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 2px dashed #dedede;
	border-radius: 5px;
	background: var(--dex-primary-background-color);
}

.dragFiles:hover {
	cursor: pointer;
}

.drag {
	background-color: var(--dex-secondary-background-color);
}

.uploadImage {
	font-size: 50px !important;
	top: 20px !important;
	position: relative;
}

.word-break-file-name {
	word-break: break-word;
}

.p-scrollpanel-bar.p-scrollpanel-bar-y {
	width: 0.625rem;
	background: var(--dex-primary-light-color) !important;
	opacity: 1;
}

.icon-alert {
	cursor: pointer;
	font-size: 1.5rem;
}

</style>
