
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import FileSystem from "@/apps/administration/components/imports/FileSystem.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from "primevue/blockui";
import {ClientManager} from "@/singletons/ClientManager";
import Dropdown from "primevue/dropdown";
import {BatchClass, BatchClassClassification, FileImportConfig} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import FileSystemForm from "@/apps/administration/components/imports/FileSystemForm.vue";

export default defineComponent({
	name: "FileSystemView",
	components: {
		FileSystemForm, FileSystem, DialogDelete, EntryDialog, BlockUI, Dropdown,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Array with the batchClasses */
		const batchClasses = ref<BatchClass[]>([]);

		/** ID of the batchClass */
		const batchClassId = ref<number>(1);

		/** Array with the documentClasses of batchClassClassifications */
		const documentClasses = ref<BatchClassClassification[]>([]);

		/** Is the Form of the mails invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** List of all files */
		const files = ref<FileImportConfig[]>([]);

		/** Currently active mail */
		const file = reactive<FileImportConfig>({
			batchClassId: 1,
			documentClassId: undefined,
			active: true,
			path: '',
			indexFileType: FileImportConfig.IndexFileTypeEnum.SCM,
			indexFileExtension: '',
			signalFileName: '',
			imageFileExtension: FileImportConfig.ImageFileExtensionEnum.PDF,
		})

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Service for getting the all batchClasses */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** Service for getting the import-data */
		const importService = ClientManager.getInstance().squeeze.import;

		/** Get all BatchClasses */
		const getAllBatchClasses = () => {
			loading.value = true;
			batchClassService.getAllBatchClasses()
				.then(data => {
					batchClasses.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			loading.value = true;
			batchClassService.getBatchClassClassifications(batchClassId.value)
				.then(data => {
					// add defaultDocumentClass documentClasses
					const defaultDocumentClass: any = {
						id: null,
						name: 'NoDocumentClass',
						description: t('Squeeze.General.None'),
					}
					documentClasses.value = data;
					documentClasses.value.unshift(defaultDocumentClass);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all File Import Configs */
		const getFileImportConfigs = () => {
			importService.getFileImportConfigs(batchClassId.value)
				.then((data: FileImportConfig[]) => {
					files.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: FileImportConfig, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(file, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Imports.FileSystem') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: FileImportConfig) => {
			Object.assign(file, row);
			// make sure batchClassId always right
			file.batchClassId = batchClassId.value

			showDialog.value = true;
		}

		onMounted(() =>{
			getAllBatchClasses();
			getAllDocumentClasses();
			getFileImportConfigs();
		})

		/** Saves a file */
		const saveFile = (keepDialogOpen: boolean = false) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let fileChangePromise = null;

			// make sure batchClassId always right
			file.batchClassId = batchClassId.value;

			if (!file.documentClassId) {
				file.documentClassId = undefined;
			}

			if (!file.id) {
				fileChangePromise = importService.postFileImportConfig(file);
			} else {
				fileChangePromise = importService.putFileImportConfig(file.id, file);
			}

			fileChangePromise.then(() => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					file.documentClassId = undefined;
					file.active = true;
					file.path = '';
					file.indexFileType = FileImportConfig.IndexFileTypeEnum.SCM;
					file.indexFileExtension = '';
					file.signalFileName = '';
					file.imageFileExtension = FileImportConfig.ImageFileExtensionEnum.PDF;

					//mailForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}
				getFileImportConfigs();
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: FileImportConfig) => {
			deleteDialog.value = true;
			Object.assign(file, row);
		}

		/** Deletes a file */
		const deleteEntry = () => {
			if (file && file.id) {
				loading.value = true;
				importService.deleteFileImportConfig(file.id).then(() => {
					getFileImportConfigs();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Triggered when batchClassId is change */
		const onChangeBatchClass = () => {
			getFileImportConfigs();
			getAllDocumentClasses();
		}

		return {t, toast, loading, loadingDialog, batchClasses, batchClassId, isValidationInvalid, showValidationMessage, documentClasses, files, file, showDialog, deleteDialog, headerText,
			getAllBatchClasses, getAllDocumentClasses, getFileImportConfigs, onUpdate, saveFile, onEntrySelect, openDeleteDialog, deleteEntry, onChangeBatchClass};
	},
});

