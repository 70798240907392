
import {defineComponent, PropType} from "vue";
import {useI18n} from "vue-i18n";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {DocumentField} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "FormHeadTrainingTable",
	components: {
		Button,
		DataTable,
		Column,
	},
	props: {
		tableOrigin: {
			type: String,
			default: '',
		},
		tableData: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
	},
	emits: [
		'changeData',
		'deleteData',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();

		/**
		 * Change an indicator
		 * @param rowData
		 */
		const changeData = (rowData: any) => {
			emit("changeData", rowData);
		}

		/**
		 * Deletes an indicator
		 * @param rowData
		 */
		const deleteData = (rowData: any) => {
			emit("deleteData", rowData);
		}

		/**
		 * Click a row to mark
		 * @param event
		 */
		const onMarkRegion = (event: any) => {
			emit("onMarkRegion", event);
		}

		/**
		 * Get field name
		 * @param fieldId
		 */
		const getFieldName = (fieldId: number) => {
			const field = props.documentFields.find(field => field.id === fieldId);
			return field ? field.description : fieldId;
		}

		return {
			t,
			changeData,
			deleteData,
			onMarkRegion,
			getFieldName,
		}
	},
});
