
import QueueTimeline from "@/apps/squeeze/components/QueueTimeline.vue";
import QueueNavigatorView from "@/apps/squeeze/views/QueueNavigatorView.vue";
import {defineComponent} from "vue";

interface ReportOption {
	name: string;
	code: number;
}

export default defineComponent({
	name: "QueueView",
	components: {
		QueueTimeline,
		QueueNavigatorView,
	},
	setup() {
		return {}
	},

})

