
import {
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {DocumentLocator, InvoiceAmountLocatorDetails} from "@dex/squeeze-client-ts";
import InvoiceAmountsForm from "@/apps/administration/components/locators/tabs/InvoiceAmountsForm.vue";
import BlockUI from "primevue/blockui";

export default defineComponent({
	name: "InvoiceAmountsView",
	components: {
		InvoiceAmountsForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** Show Loading on Save */
		const saveLoading = ref(false);

		/** List of all locators */
		const locators = ref<DocumentLocator[]>([]);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all invoice amounts */
		const invoiceAmount = reactive<InvoiceAmountLocatorDetails>({});

		/** Trigged on update of attribute-form */
		const onUpdate = (data: InvoiceAmountLocatorDetails) => {
			Object.assign(invoiceAmount, data)
		}

		/** Reloads the data */
		const reloadData = () => {
			loading.value = true;
			locatorService.getInvoiceAmountLocatorDetails(props.locatorId)
				.then((data: InvoiceAmountLocatorDetails) => {
					Object.assign(invoiceAmount, data);
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all locators */
		const getLocators = () => {
			loading.value = true;
			locatorService.getAllLocators()
				.then((data: DocumentLocator[]) => {
					locators.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all locators and invoice amounts of current locator on load */
		onMounted(() =>{
			reloadData();
			getLocators();
		})

		/** Saves an Invoice Amount */
		const saveInvoiceAmount = () => {
			saveLoading.value = true;

			// make sure locatorId always right
			invoiceAmount.locatorId = props.locatorId;

			locatorService.putInvoiceAmountLocatorDetails(props.locatorId, invoiceAmount)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.SaveSuccess'));
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					saveLoading.value = false;
				})
		}

		return {loading, saveLoading, locators, invoiceAmount, onUpdate, saveInvoiceAmount, getLocators};
	},
});

