
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {LocatorSearchValue} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import SearchValues from "@/apps/administration/components/locators/tabs/SearchValues.vue";
import SearchValuesForm from "@/apps/administration/components/locators/tabs/SearchValuesForm.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "SearchValueView",
	components: {
		DialogDelete, EntryDialog, SearchValues, SearchValuesForm, BlockUI,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
		valueListValue: {
			type: String as PropType<string>,
			default: '',
		},
	},
	emits: [
		"onLocatorChange",
	],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const searchValueForm = ref<InstanceType<typeof SearchValuesForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Search-Value invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all Search-Values */
		const searchValues = ref<LocatorSearchValue[]>([]);

		/** Should the Entry-Dialog for regexes be shown? */
		const showDialog = ref<boolean>(false);

		/** Currently active Search-Value */
		const searchValue = reactive<LocatorSearchValue>({
			searchValue: '',
		});

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Trigger on update of attribute-form */
		const onUpdate = (data: LocatorSearchValue, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(searchValue, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (searchValue.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.Tabs.SearchValue') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.Tabs.SearchValue') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: LocatorSearchValue) => {
			Object.assign(searchValue, row);
			showDialog.value = true;
		}

		/** Reloads the table */
		const reloadData = async () => {
			loading.value = true;
			await locatorService.getLocatorSearchValues(props.locatorId)
				.then(data => {
					if (props.valueListValue) {
						searchValues.value = data.filter(searchValues => searchValues.value === props.valueListValue);
					} else {
						searchValues.value = data;
					}
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** When the Value from the value list changes, reload the data */
		watch(() => props.valueListValue, () => {
			reloadData();
		})

		/** Get Type of current locator on load */
		onMounted(() =>{
			reloadData();
		})

		/** Saves an Regex */
		const saveSearchValue = (keepDialogOpen: boolean = false,  event: any = null) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let promise = null;

			// make sure locatorId is always right
			searchValue.locatorId = props.locatorId;
			searchValue.value = props.valueListValue;

			if (!searchValue.id) {
				promise = locatorService.postLocatorSearchValue(props.locatorId, searchValue);
			} else {
				promise = locatorService.putLocatorSearchValues(props.locatorId, searchValue.id, searchValue);
			}

			promise.then(async () => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					searchValue.searchValue = '';
					searchValueForm.value?.$el.querySelector('input').focus()
					isValidationInvalid.value = true;
				}

				await reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;

				if (event) {
					// Notify any listeners in case they need to wait for the completion of this request
					document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
				}
			})
		}

		/**
		 * Triggered on change of attribute-form
		 * @param event
		 * @param isInvalid
		 */
		const onChangeSearchValue = (event: any, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(searchValue, event.newData);
			saveSearchValue(false, event);
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: LocatorSearchValue) => {
			deleteDialog.value = true;
			Object.assign(searchValue, row);
		}

		/** Deletes a Regex */
		const deleteEntry = () => {
			if (searchValue && searchValue.id) {
				loading.value = true;
				locatorService.deleteLocatorSearchValue(props.locatorId, searchValue.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		return {
			loading,
			isValidationInvalid,
			showValidationMessage,
			loadingDialog,
			searchValueForm,
			showDialog,
			headerText,
			deleteDialog,
			searchValues,
			searchValue,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onChangeSearchValue,
			onEntrySelect,
			saveSearchValue,
		};
	},
});

