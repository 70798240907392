
import {defineComponent, PropType, reactive, ref, watch} from 'vue';
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import {Document} from "@dex/squeeze-client-ts";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

interface Option {
	label: string;
	value: string;
}

interface Locales {
	header: string;
	notice: {
		selectReason: string;
		comment: string;
	};
	selectPlaceholder: string;
	buttons: {
		confirm: string;
		abort: string;
	};
}

export default defineComponent({
	name: "DialogOptionsConfirm",
	components: {
		Dialog,
		Dropdown,
		InputText,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		/** Reasons to choose from while deleting or suspending a document */
		options: {
			type: Array as PropType<Option[]>,
			default: () => [],
		},
		/** Localization for dialog text */
		locales: {
			type: Object as PropType<Locales>,
			required: true,
		},
		currentDocument: {
			type: Object as PropType<Document|null>,
			default: null,
		},
	},
	emits: ["onClose", "onConfirm"],
	setup(props, {emit}) {
		/** Comment for confirmation */
		const value = reactive<any>({
			comment: '',
		})

		/** Determines the required rules for validation */
		const rules = {
			comment: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		const confirmed = ref<boolean>(false);

		/** Indicates if the dialog is displayed */
		const showDialog = ref<boolean>(false);

		/** Selected option of options (dropdown) */
		const selectedOption = ref<Option>({
			label: "",
			value: "",
		});

		/** Emits confirmation event with comment */
		const confirm = () => {
			confirmed.value = true;
			if (props.options.length > 0 && selectedOption.value.value !== 'misc') {
				value.comment = selectedOption.value.label;
			}

			if (value.comment.length > 0) {
				selectedOption.value = { label: "", value: "" };
				emit("onConfirm", value.comment);
			}
		}

		/** Closes the dialog */
		const closeDialog = () => {
			showDialog.value = false;
			selectedOption.value = { label: "", value: "" };
			emit("onClose");
		}

		/**
		 * Triggered when the option is changed
		 * @param event
		 */
		const onChangeOption = (event: { originalEvent: PointerEvent; value: Option }) => {
			selectedOption.value = event.value;
		}

		watch(() => props.show, () => {
			// Save show in local variable, because show is not allowed to me mutated inside. React on change from show from parent
			showDialog.value = props.show;

			// check if current document has an error text and if status is suspend
			if (props.currentDocument?.workflowContext?.errorText && props.currentDocument?.workflowContext?.status === "SUSPEND") {
				value.comment = props.currentDocument.workflowContext.errorText;
			} else {
				value.comment = "";
				confirmed.value = false;
			}
		})

		return {
			v$,
			value,
			rules,
			confirmed,
			showDialog,
			selectedOption,
			confirm,
			closeDialog,
			onChangeOption,
		}
	},
});

