
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {RouteLocationRaw, useRoute} from "vue-router";
import router from "@/router";
import QueueEntryList from "@/apps/squeeze/components/QueueEntryList.vue";
import QueueTimeline from "@/apps/squeeze/components/QueueTimeline.vue";
import DocumentList from "@/apps/squeeze/components/DocumentList.vue";
import Skeleton from "primevue/skeleton";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {ListDocument, ListDocumentField} from "@/apps/squeeze/interfaces/ListDocument";
import {DocumentFilterObject, Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {FieldColumn} from "@/apps/squeeze/interfaces/FieldColumn";
import {
	Document,
	DocumentSearchRequestDto,
	FieldValueFilterDto,
	PaginationDto,
	QueueEntry,
	WorkflowContextFilterDto
} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "ValidationDocumentList",
	components: {
		QueueEntryList,
		QueueTimeline,
		DocumentList,
		Skeleton,
	},
	props: {
		/** ID of document class */
		documentClassId: {
			type: Number,
			required: true,
		},
	},
	emits: ["onRowSelect"],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Name of document class */
		const documentClassName = ref<string>("");

		/** Documents to list */
		const documents = ref<ListDocument[]>([]);

		/** List of fields as columns array for datatable */
		const fieldColumns = ref<FieldColumn[]>([]);

		/** Indicates end of request */
		const loaded = ref<boolean>(false);

		/** Indicates end of mounted */
		const initialLoaded = ref<boolean>(false);

		/** Indicates that queue entries are loaded instead of searching for documents via the ES search endpoint */
		const usingQueueEntryFallback = ref<boolean>(false);

		/** List of all QueueEntries in QueueStep Validation */
		const queueEntries = ref<QueueEntry[]>([]);

		/** Currently-Selected row */
		const selection = reactive<any>(null);

		/** Workflow Filter Standard for validation */
		const standardWorkflowFilter = ref<any[]>([
			{
				fieldName: "queueStep",
				comp: "eq",
				searchValue: "validation",
				fieldType: "text",
			},
		]);

		/** Info / Options of pagination */
		const paginationOption = reactive<PaginationDto>({
			pageSize: 25,
			page: 0,
			total: 0,
		});

		/** Filters of Document list */
		const filters = reactive<DocumentFilterObject>({
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"queueStatus": {value: null, matchMode: FilterMatchMode.EQUALS},
		});

		/** Search request for document search filtering */
		const searchRequest = reactive<DocumentSearchRequestDto>({});

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;

		/** DocumentClass API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Sets available filters of search request for Vue to update */
		const setDataTableFilter = () => {
			const searchRequestFilters: {[type: string]: FieldValueFilterDto[] | WorkflowContextFilterDto[] | undefined} = {
				"fields": searchRequest.fieldFilters,
				"wf": searchRequest.workflowContextFilters,
			};

			for (const filterType in searchRequestFilters) {
				const isField = filterType === "fields";
				const requestFilters = searchRequestFilters[filterType];

				if (requestFilters) {
					// TODO: TELL TYPESCRIPT TYPE OF FILTER
					requestFilters.forEach((filter:  any) => {
						const id = isField ? '' + filter.fieldId : filter.fieldName;
						const listFilter = {value: filter.searchValue, matchMode: FilterMatchMode.CONTAINS};

						if (!isField && filter.fieldName === "queueStatus") {
							listFilter.value = listFilter.value.toUpperCase();
						}

						switch(filter.comp) {
						case "eq": listFilter.matchMode = FilterMatchMode.EQUALS; break;
						case "bw": listFilter.matchMode = FilterMatchMode.STARTS_WITH; break;
						case "ew": listFilter.matchMode = FilterMatchMode.ENDS_WITH; break;
						default: break;
						}

						filters[id] = listFilter;
					});
				}
			}
		}

		/** @deprecated JUST FOR PRESENTATION PURPOSE */
		/**
		 * Sort field column by id
		 * @param {FieldColumn} colA
		 * @param {FieldColumn} colB
		 */
		const sortFieldColumnsById = (colA: FieldColumn, colB: FieldColumn) => {
			if (colA && colB) {
				if ((colA.id && colB.id) && (colA.id < colB.id)) {
					return -1;
				}
				if ((colA.id && colB.id) && (colA.id > colB.id)) {
					return 1;
				}
			}
			return 0;
		}

		/** Loads all documents of a specified document class in queue step "Validation" */
		const loadDocuments = async () => {
			const result = await documentApi.searchDocuments(props.documentClassId, searchRequest, 'db', paginationOption.page, paginationOption.pageSize, ["id:asc"]);
			const resultDocuments = result.elements as Document[];
			const pagination = result.pagination;
			if (pagination) Object.assign(paginationOption, pagination);
			documents.value = [];

			// Add documents to list
			resultDocuments.forEach((document) => {
				const listDocument: ListDocument = {
					id: document.id as any,
					createdAt: document.createdAt,
					//batchClassId: document.batchClassId as any,
					//documentClassId: document.documentClassId as any,
					workflowContext: document.workflowContext,
					fields: {},
					exports: [],
				};

				// Add fields to document
				const fields = document.fields;
				fields.forEach((field) => {
					if (field.id) {
						const listField: ListDocumentField = {
							id: field.id,
							name: field.name as any,
							value: (field.value && field.value.value) ? field.value.value : "",
							dataType: field.dataType as any,
						};
						listDocument.fields[field.id] = listField;
					}
				});

				documents.value.push(listDocument);
			});
			fieldColumns.value.sort(sortFieldColumnsById);
			setDataTableFilter();
		}

		/** Loads all queue entries of a specified document class in queue step "Validation" */
		const loadQueueEntries = async () => {
			const result = await queueApi.getQueueStepEntries("Validation", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, paginationOption.page, paginationOption.pageSize as number);
			const entries = result.elements;
			Object.assign(paginationOption, result.pagination);

			if (entries && entries.length > 0) {
				queueEntries.value = entries;
			} else {
				throw new Error("Missing entries in documents class!")
			}
		}

		/** Reloads QueueStepEntries for step Validation */
		const reloadData = async () => {
			loaded.value = false;

			// load fields of the documentClasses
			await documentClassApi.getAllDocumentClassFields(props.documentClassId)
				.then(fields => {
					fields.forEach(field => {
						if (field.hidden !== true) {
							fieldColumns.value.push({
								id: field.id,
								name: field.name as string,
								header: field.description as string,
								type: field.dataType as string,
							});
							filters['' + field.id] = {value: null, matchMode: FilterMatchMode.CONTAINS};
						}
					});
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})

			// Load documents
			try {
				await loadDocuments();
				usingQueueEntryFallback.value = false;
			} catch (err) {
				ToastManager.showInfo(toast, t('Toasts.Title.Info'), t('Squeeze.Queue.QueueEntryFallback.Info'));
				// todo: Send to sentry
				try {
					usingQueueEntryFallback.value = true;
					await loadQueueEntries();
				} catch (err) {
					ToastManager.showError(toast, t('Squeeze.General.Error'), String(err));
				}
			}

			loaded.value = true;
		}

		/**
		 * Triggered when a row is selected
		 * @param {Document} document
		 */
		const onRowSelect = (document: Document) => {
			const route: RouteLocationRaw = {
				name: 'ValidateEntry',
				params: {
					documentId: document.id as any,
					searchRequest: JSON.stringify(searchRequest),
					pagination: JSON.stringify(paginationOption),
				}};
			router.push(route);
		}

		/**
		 * Triggered when the next page should be loaded
		 * @param event
		 */
		const onPage = (event: any) => {
			// Set current page
			paginationOption.page = event.page;
			paginationOption.pageSize = event.rows;
			reloadData();
		}

		/** Encode query element for router */
		const encodeFilterQuery = () => {
			const query: {[key: string]: string} = {};
			if (searchRequest.workflowContextFilters && searchRequest.workflowContextFilters.length > 0) {
				query.wf = encodeURI(JSON.stringify(searchRequest.workflowContextFilters));
			}

			if (searchRequest.fieldFilters && searchRequest.fieldFilters.length > 0) {
				query.f = encodeURI(JSON.stringify(searchRequest.fieldFilters));
			}

			return query;
		}

		/**
		 * Triggered when a filter has been entered
		 * @param {DocumentFilterObject} tableFilters
		 */
		const onFilter = async (tableFilters: DocumentFilterObject) => {
			searchRequest.fulltextFilters = [];
			searchRequest.fieldFilters = [];
			searchRequest.workflowContextFilters = standardWorkflowFilter.value;

			Object.assign(filters, tableFilters);
			const fieldFilters: FieldValueFilterDto[] = [];
			const workflowContextFilter: WorkflowContextFilterDto[] = [];

			for (const id in tableFilters) {
				const filterId = Number(id);
				const isField = !isNaN(filterId);

				const filter: Filter = tableFilters[id];
				if(filter && filter.value != null && filter.value != "") {
					paginationOption.page = 0;

					let comp = "";
					switch(filter.matchMode) {
					case FilterMatchMode.CONTAINS: comp = "like"; break;
					case FilterMatchMode.STARTS_WITH: comp = "bw"; break;
					case FilterMatchMode.ENDS_WITH: comp = "ew"; break;
					case FilterMatchMode.EQUALS: comp = "eq"; break;
					default: ToastManager.showInfo(toast, t('Toasts.Title.Info'), t('Squeeze.Queue.Filter.Unknown')); break;
					}

					if(comp != "") {
						if(!isField) {
							// WF CONTEXT
							workflowContextFilter.push({
								fieldName: id,
								searchValue: filter.value.toLowerCase(),
								comp: comp,
								fieldType: "text",
							});
						} else {
							// FIELD
							const fieldType = fieldColumns.value.filter((column) => {
								return column.id && column.id === filterId;
							});

							if(fieldType.length > 0) {
								fieldFilters.push({
									fieldId: filterId,
									searchValue: filter.value,
									comp: comp as any,
									fieldType: fieldType[0].type.toLowerCase(),
								})
							}
						}
					}
				}
			}

			searchRequest.fieldFilters = fieldFilters;
			searchRequest.workflowContextFilters = workflowContextFilter;

			// SET FILTER QUERY
			const route: RouteLocationRaw = { name: 'DocumentList', params: { documentClassId: props.documentClassId}};
			route.query = encodeFilterQuery();
			await router.replace(route);
			await reloadData();
		}

		/** On view ready */
		onMounted(async () => {
			// Initialize pagination info / options
			paginationOption.pageSize = 25;
			paginationOption.page = 0;
			paginationOption.total = 0;

			const wfQuery = route.query.wf;
			if(wfQuery && typeof wfQuery === "string") {
				const wfValueFilters = JSON.parse(decodeURI(wfQuery));
				if (wfValueFilters) {
					searchRequest.workflowContextFilters = wfValueFilters;
				}
			} else {
				searchRequest.workflowContextFilters = standardWorkflowFilter.value;
			}
			const fieldQuery = route.query.f;
			if (fieldQuery && typeof fieldQuery === "string") {
				const fieldValueFilters = JSON.parse(decodeURI(fieldQuery));
				if(fieldValueFilters) {
					searchRequest.fieldFilters = fieldValueFilters;
				}
			}

			await reloadData();
			initialLoaded.value = true;
		})

		return {
			t,
			toast,
			documentClassName,
			documents,
			fieldColumns,
			loaded,
			initialLoaded,
			usingQueueEntryFallback,
			queueEntries,
			selection,
			standardWorkflowFilter,
			paginationOption,
			filters,
			searchRequest,
			reloadData,
			onRowSelect,
			onPage,
			encodeFilterQuery,
			setDataTableFilter,
			onFilter,
			loadQueueEntries,
			sortFieldColumnsById,
			loadDocuments,
		};
	},
});

