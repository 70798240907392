<template>
	<!-- Note: This a modified version of the TabMenu-Component from Prime Vue: https://github.com/primefaces/primevue/blob/master/src/components/tabmenu/TabMenu.vue.
	Some adjustments had to be made due to typescript/eslint-errors that would occur otherwise.
	Feature-Request for this adjustment is here: https://github.com/primefaces/primevue/issues/1051
	The important change is in function "activeTab" and the computed prop  -->
	<div class="p-tabmenu p-component">
		<ul ref="nav" class="p-tabmenu-nav p-reset" role="tablist">
			<template v-for="(item,i) of model" :key="item.label + '_' + i.toString()">
				<li :class="getItemClass(item)" :style="item.style" v-if="visible(item)" role="tab" :aria-selected="isActive(item)" :aria-expanded="isActive(item)">
					<router-link v-if="item.to && !item.disabled" :to="item.to" custom v-slot="{navigate, href}">
						<a :href="href" class="p-menuitem-link" @click="onItemClick($event, item, navigate)" role="presentation" v-ripple>
							<span :class="getItemIcon(item)" v-if="item.icon"></span>
							<span class="p-menuitem-text">{{item.label}}</span>
						</a>
					</router-link>
					<a v-else :href="item.url" class="p-menuitem-link" :target="item.target" @click="onItemClick($event, item)" role="presentation" :tabindex="item.disabled ? null : '0'" v-ripple>
						<span :class="getItemIcon(item)" v-if="item.icon"></span>
						<span class="p-menuitem-text">{{item.label}}</span>
					</a>
				</li>
			</template>
			<li ref="inkbar" class="p-tabmenu-ink-bar"></li>
		</ul>
	</div>
</template>

<script>
import {DomHandler} from 'primevue/utils';
import Ripple from 'primevue/ripple';
export default {
	props: {
		model: {
			type: Array,
			default: null,
		},
	},
	mounted() {
		this.updateInkBar();
	},
	updated() {
		this.updateInkBar();
	},
	methods: {
		onItemClick(event, item, navigate) {
			if (item.disabled) {
				event.preventDefault();
				return;
			}
			if (item.command) {
				item.command({
					originalEvent: event,
					item: item,
				});
			}
			if (item.to && navigate) {
				navigate(event);
			}
		},
		isActive(item) {
			if (this.activeRoute.path === item.to) {
				return true
			}

			if (this.activeRoute.name === item.to.name) {
				return true
			}

			return false;
		},
		getItemClass(item) {
			return ['p-tabmenuitem', item.class, {
				'p-highlight': this.isActive(item),
				'p-disabled': item.disabled,
			}];
		},
		getItemIcon(item) {
			return ['p-menuitem-icon', item.icon];
		},
		visible(item) {
			return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
		},
		findActiveTabIndex() {
			if (this.model) {
				for (let i = 0; i < this.model.length; i++) {
					if (this.isActive(this.model[i])) {
						return i;
					}
				}
			}
			return null;
		},
		updateInkBar() {
			const activeTabIndex = this.findActiveTabIndex();
			if (activeTabIndex !== null) {
				const tabHeader = this.$refs.nav.children[activeTabIndex];
				this.$refs.inkbar.style.width = DomHandler.getWidth(tabHeader) + 'px';
				this.$refs.inkbar.style.left =  DomHandler.getOffset(tabHeader).left - DomHandler.getOffset(this.$refs.nav).left + 'px';
			}
			else {
				this.$refs.inkbar.style.width = '0px';
				this.$refs.inkbar.style.left =  '0px';
			}
		},
	},
	computed: {
		activeRoute() {
			return this.$route;
		},
	},
	directives: {
		'ripple': Ripple,
	},
}
</script>

<style>
.p-tabmenu-nav {
	display: flex;
	margin: 0;
	padding: 0;
	list-style-type: none;
	flex-wrap: wrap;
}
.p-tabmenu-nav a {
	cursor: pointer;
	user-select: none;
	display: flex;
	align-items: center;
	position: relative;
	text-decoration: none;
	text-decoration: none;
	overflow: hidden;
}
.p-tabmenu-nav a:focus {
	z-index: 1;
}
.p-tabmenu-nav .p-menuitem-text {
	line-height: 1;
}
.p-tabmenu-ink-bar {
	display: none;
	z-index: 1;
}
</style>
