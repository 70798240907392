
import {defineComponent, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Tooltip from "primevue/tooltip";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {DocumentFilterObject} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";

export default defineComponent({
	name: 'DocumentClassList',
	components: {
		DataTable,
		Column,
		InputText,
	},
	props: {
		/** Show loading in the table? */
		loading: {
			type: Boolean,
			default: false,
		},
		/** All document classes */
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
		isAdministrationPathOpen: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["openEntryDialog", "openDeleteDialog", "onRowSelect"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current document class data */
		const rowData = reactive<DocumentClass>({name: "", description: ""});

		/** Currently-Selected row */
		const selection = ref(null);

		/** Filters of list (Currently not activated) */
		const filters = ref<DocumentFilterObject>({});

		/** Should the clear filter button be shown in the table? */
		const showBtnClearFilter = ref<boolean>(false);

		/**
		 * Opens the Delete-Dialog
		 * @param {DocumentClass} data The row to delete
		 */
		const openDeleteDialog = (data: DocumentClass) => {
			Object.assign(rowData, data);
			emit("openDeleteDialog", rowData);
		}

		/** Triggered when the New Entry Button is clicked */
		const openEntryDialog = () => {
			emit("openEntryDialog");
		}

		/**
		 * Triggered when a row is selected
		 * @param event
		 */
		const onRowSelect = (event: any) => {
			emit("onRowSelect", event.data.id);
		}

		/**
		 * Triggered when a filter has been entered
		 * @param event
		 */
		const onFilter = (event: any) => {
			showBtnClearFilter.value = event.filteredValue.length < props.documentClasses.length;
		}

		/** Init filters */
		const initFilters = () => {
			filters.value = {
				"id": {value: null, matchMode: FilterMatchMode.EQUALS},
				"name": {value: null, matchMode: FilterMatchMode.CONTAINS},
				"description": {value: null, matchMode: FilterMatchMode.CONTAINS},
			};
		}

		/** Clear filters */
		const clearFilters = () => {
			initFilters();
		}

		initFilters();

		return {
			t,
			toast,
			rowData,
			selection,
			filters,
			showBtnClearFilter,
			openDeleteDialog,
			openEntryDialog,
			onRowSelect,
			onFilter,
			initFilters,
			clearFilters,
		};
	},
});

