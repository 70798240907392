
import {computed, defineComponent, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import draggable from 'vuedraggable'
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentSplitStatus, FileDto} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";

interface UiSplitElement {
	model: FileDto;
	clicked: boolean;
}

export default defineComponent({
	name: "DocumentSplit",
	components: {
		BlockUI,
		draggable,
	},
	props: {
		/** ID of currently active Document */
		documentId: {
			type: String,
		},
	},
	emits: ["onImageClick", "onEmptyList"],
	setup(props, { emit }) {
		const {t} = useI18n();
		const toast = useToast();

		/** List with available Thumbnails */
		const thumbnails = ref<UiSplitElement[]>([]);

		/** List with all selected Thumbnails */
		const listSelected = ref<UiSplitElement[]>([]);

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** Is the Item currently dragged? */
		const drag = ref<boolean>(false);

		/** Drag options. Taken from https://github.com/SortableJS/vue.draggable.next/blob/master/example/components/transition-example-2.vue */
		const dragOptions = reactive<any>({
			animation: 200,
			group: "images",
			disabled: false,
			ghostClass: "ghost",
		});

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Is Queue item active? */
		const selectedThumbnails = computed(() => {
			const clickedElements = thumbnails.value.filter((element: UiSplitElement) => element.clicked);
			if (clickedElements) {
				return clickedElements.length;
			} else {
				return 0;
			}
		})

		/**
		 * Triggered when a key is press on the window
		 * @param event
		 */
		const onKeyPress = (event: any) => {
			if (event.code === "ArrowRight") {
				const clickedElements = thumbnails.value.filter(element => element.clicked);

				if (clickedElements) {
					clickedElements.forEach(element => {
						listSelected.value.push({...element});
					})

					thumbnails.value = thumbnails.value.filter(element => !element.clicked);
				}
			}


			if (event.code === "ArrowLeft") {
				const clickedElements = listSelected.value.filter(element => element.clicked);

				if (clickedElements) {
					clickedElements.forEach(element => {
						const newElement = {...element};
						newElement.clicked = false;
						listSelected.value.push(newElement);
					})

					listSelected.value = listSelected.value.filter(element => !element.clicked);
				}
			}
		}

		/**
		 * Triggered when an element is clicked
		 * @param event
		 * @param splitElement
		 */
		const clickThumbnail = (event: any, splitElement: UiSplitElement) => {
			splitElement.clicked = !splitElement.clicked;
			emit("onImageClick", splitElement.model.id);
		}

		/**
		 * Triggered when an element on the right side is clicked
		 * @param event
		 * @param splitElement
		 */
		const clickThumbnailRight = (event: any, splitElement: UiSplitElement) => {
			emit("onImageClick", splitElement.model.id);
		}

		/** Moves the Pages to right side */
		const movePages = () => {
			const clickedElements = thumbnails.value.filter(element => element.clicked);

			if (clickedElements) {
				clickedElements.forEach(element => {
					const newElement = {...element};
					newElement.clicked = false;
					listSelected.value.push(newElement);
				})

				thumbnails.value = thumbnails.value.filter(element => !element.clicked);
			}
		}

		/** Is triggered when the dragging has ended */
		const onDragEnd = () => {
			drag.value = false;

			// Don't show any element as clicked on the right side
			listSelected.value.forEach(element => {
				element.clicked = false;
			});
		}

		/** Splits the chosen documents */
		const splitDocument = () => {
			const pageIds = listSelected.value.map(element => {
				return Number(element.model.id);
			});

			const pages: any = {
				pageIds: pageIds,
			};

			loading.value = true;

			documentApi.splitDocument(Number(props.documentId), pages)
				.then(() => {
					listSelected.value = [];

					if (listSelected.value.length === 0 && thumbnails.value.length === 0) {
						emit("onEmptyList");
					}
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onBeforeMount(() => {
			window.addEventListener('keyup', onKeyPress);
		})

		onBeforeUnmount(() => {
			window.removeEventListener("keyup", onKeyPress);
		})

		onMounted(() =>{
			const promiseSplitStatus = documentApi.getDocumentSplitStatus(Number(props.documentId));
			const promiseThumbnails = documentApi.getDocumentThubnails(Number(props.documentId), true)

			Promise.all([promiseSplitStatus, promiseThumbnails])
				.then(promises => {
					const splitStatus: DocumentSplitStatus =  promises[0];
					let allThumbnails = promises[1];

					allThumbnails = allThumbnails.filter(thumbnail => splitStatus.unsplitPages?.indexOf(Number(thumbnail.id)) != -1);

					thumbnails.value = allThumbnails.map((thumbnail: FileDto) => {
						return {
							model: thumbnail,
							clicked: false,
						}
					})
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})
		})

		return {
			t,
			toast,
			thumbnails,
			listSelected,
			loading,
			drag,
			dragOptions,
			selectedThumbnails,
			onKeyPress,
			clickThumbnail,
			clickThumbnailRight,
			movePages,
			onDragEnd,
			splitDocument,
		};
	},
});

