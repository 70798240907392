
import QueueTimeline from '@/apps/squeeze/components/QueueTimeline.vue';
import DashboardTile from "@/components/DashboardTile.vue";
import DocumentClassSearchMask from "@/apps/squeeze/components/DocumentClassSearchMask.vue";
import {defineComponent, onMounted, ref} from 'vue';
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentClass} from "@dex/squeeze-client-ts";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import QueueNavigatorView from "@/apps/squeeze/views/QueueNavigatorView.vue";

export default defineComponent({
	components: {
		QueueNavigatorView,
		DashboardTile,
		QueueTimeline,
		DocumentClassSearchMask,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Document API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** List of all DocumentClasses */
		const allDocumentClasses = ref<DocumentClass[]>([]);

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			documentClassApi.getAllDocumentClasses()
				.then((documentClasses) => {
					allDocumentClasses.value = documentClasses;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		/** On view ready */
		onMounted(() => {
			getAllDocumentClasses();
		})

		return {
			t, toast, allDocumentClasses, getAllDocumentClasses,
		};
	},
});
