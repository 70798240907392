
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Dropdown from 'primevue/dropdown';
import InputText from "primevue/inputtext";
import {BarcodeLocatorDetails, BarcodeType} from "@dex/squeeze-client-ts";
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default defineComponent({
	name: "BarcodeForm",
	components: { Dropdown, InputText },
	props: {
		barcodeTypes: {
			type: Array as PropType<BarcodeType[]>,
			default: () => [],
		},
		barcode: {
			type: Object as PropType<BarcodeLocatorDetails>,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields  */
		const value = reactive<BarcodeLocatorDetails>({});

		/** Determines the required rules for validation */
		const rules = {
			barcodeType: { required },
			barcodeRegex: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(() => {
			Object.assign(value, props.barcode);
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.barcode, () => {
			Object.assign(value, props.barcode);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});

