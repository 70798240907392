
import {defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {LocatorKeyword} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default defineComponent({
	name: "KeyWordsForm",
	components: {
		InputText,
	},
	props: {
		keyword: {
			type: Object as PropType<LocatorKeyword>,
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<LocatorKeyword>({keyword: ''});

		/** Determines the required rules for validation */
		const rules = {keyword: { required }}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(() => {
			Object.assign(value, props.keyword);
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.keyword, () => {
			Object.assign(value, props.keyword);
		})

		/** Triggered on change of any field */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});

