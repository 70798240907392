
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {defineComponent, onMounted, PropType, reactive} from "vue";
import {BatchClassClassification, MailImportConfig} from "@dex/squeeze-client-ts";
import ProtocolEnum = MailImportConfig.ProtocolEnum;
import EncryptionEnum = MailImportConfig.EncryptionEnum;
import {useI18n} from "vue-i18n";
import Tooltip from "primevue/tooltip";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useToast} from "primevue/usetoast";
import useSqueezeStore from "@/apps/squeeze/store";
import {ClientManager} from "@/singletons/ClientManager";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "Mail",
	components: {
		DataTable, Column,
	},
	props: {
		mails: {
			type: Array as PropType<MailImportConfig[]>,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		documentClasses: {
			type: Array as PropType<BatchClassClassification[]>,
			default: () => [],
			required: true,
		},
		showErrorMessage: {
			type: Boolean,
		},
		showAuthenticateButtonColumn: {
			type: Boolean,
			default: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: [
		"onClickDelete", "onEntrySelect", "testMailConnection", "fetchMails",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: MailImportConfig) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				batchClassId: undefined,
				documentClassId: 0,
				server: '',
				port: undefined,
				protocol: ProtocolEnum.Imap,
				encryption: EncryptionEnum.Ssl,
				checkCertificate: false,
				user: '',
				password: '',
				clientId: '0',
				tenantId: '0',
				inboxFolder: '',
				validFolder: '',
				errorFolder: '',
			})
		}

		/**
		 * Triggered when the New Entry Button is clicked
		 * @param row The row to edit
		 */
		const openEditDialog = (row: MailImportConfig) => {
			emit("onEntrySelect", row)
		}

		/**
		 * Gets the Description of a documentClass
		 * @param documentClassId Id of the documentClass to get the Description from
		 * @return Description of the documentClass
		 */
		const getDescriptionFromDocumentClass = (documentClassId: number) => {
			if (documentClassId == null) {
				return t('Squeeze.General.None');
			}

			const documentClassDescription = props.documentClasses.find(i => i.documentClassId === documentClassId);

			if (!documentClassDescription) {
				return String(documentClassId)
			}

			return documentClassDescription.description
		}

		/**
		 * Test the Mail Connection of Row
		 * @param row
		 */
		const testMailConnection = (row: MailImportConfig) => {
			emit("testMailConnection", row)
		}

		/**
		 * Fetch E-Mails
		 * @param mail
		 */
		const fetchMails = (mail: MailImportConfig) => {
			emit("fetchMails", mail.id)
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		/**
		 * Create the URL to trigger the Authenticate of Microsoft Graph Api Delegated
		 * @param mailId
		 */
		const createUrl = (mailId: number) => {
			return ClientManager.getInstance().getSqueezeBasePath() + '/importers/email/' + mailId + '/authenticate/start';
		}

		return {
			tableListPagination,
			openDeleteDialog,
			onNewEntryClick,
			openEditDialog,
			getDescriptionFromDocumentClass,
			testMailConnection,
			fetchMails,
			onChangePage,
			createUrl,
		}
	},
});

