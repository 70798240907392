
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentClass, DocumentField, XmlHeadFieldTraining} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import {useI18n} from "vue-i18n";
import {showDropdownOverlay} from "@/util/StylesHelper";
import Textarea from "primevue/textarea";

export default defineComponent({
	name: "XmlMapperHeadForm",
	components: {
		InputText, Dropdown, Textarea,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<XmlHeadFieldTraining>,
			default: () => ({}),
		},
		documentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update', 'onChangeDocumentClass'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Entry-Data of the field-group */
		const value = reactive<XmlHeadFieldTraining>({
			company: '',
			creditor: '',
			documentclassid: '',
			xpath: '',
			fieldName: '',
			readonly: false,
		});

		/** Determines the required rules for validation */
		const rules = {
			company: { required },
			creditor: { required },
			documentclassid: { required },
			xpath: { required },
			fieldName: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/** Triggered when input-field is changed */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		/**
		 * Triggered when a document class changes
		 **/
		const updateDocumentClass = async (data: any)  => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("onChangeDocumentClass", value, v$.value.$invalid, data.value);
		}

		/**
		 * Get the description of a field
		 * @param row
		 */
		const getFieldDescription = (row: any) => {
			if (props.documentClassFields) {
				const documentClassFields = props.documentClassFields.filter(field => field.name === row.name);

				if (documentClassFields.length > 1) {
					return row.name + " (" + documentClassFields[0].description + " (" + t('Squeeze.XmlMapper.FurtherEntries') +"))";
				} else if (documentClassFields.length === 1) {
					return row.name + " (" + documentClassFields[0].description + ")";
				}
			}

			return row.name;
		}

		return {
			value,
			props,
			v$,
			update,
			getFieldDescription,
			updateDocumentClass,
			showDropdownOverlay,
		}
	},
});
