
import {defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {
	DocumentField,
	FormTrainingHeadField,
	FormTrainingRegionAnchor
} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import {showDropdownOverlay} from "@/util/StylesHelper";
import FormHeadTrainingTable from "@/apps/squeeze/components/FormHeadTrainingTable.vue";

export default defineComponent({
	name: "FormTrainingHeadFields",
	methods: {showDropdownOverlay},
	components: {
		FormHeadTrainingTable,
		InputText,
		Dropdown,
		Button,
	},
	props: {
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		headFieldOfRegionInFormTraining: {
			type: Object as PropType<FormTrainingHeadField>,
			default: () => ({}),
		},
		documentFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		booleanOptions: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Selected region anchor ID */
		const regionAnchorId = ref<number>(0);

		/** Current head field of region in form training */
		const headField = reactive<FormTrainingHeadField>({
			id: 0,
			fieldId: 0,
			valuePattern: '',
			valueRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			valueIgnoreWhitespace: false,
			accumulateFoundValues: false,
		});

		/** Show Loading on load data */
		const loading = ref(false);

		/** All head fields */
		const allHeadFields = ref<FormTrainingHeadField[]>([]);

		/** All region anchors */
		const allRegionAnchors = ref<FormTrainingRegionAnchor[]>([]);

		/** Form Training API endpoint */
		const formTrainingApi = ClientManager.getInstance().squeeze.formTraining;

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/** Set region anchor ID */
		const setRegionAnchorId = () => {
			if (allRegionAnchors.value[0] && allRegionAnchors.value[0].id) {
				regionAnchorId.value = allRegionAnchors.value[0].id;
			}
		}

		/** Reset head field */
		const resetHeadField = () => {
			Object.assign(headField, {
				id: undefined,
				fieldId: 0,
				valuePattern: '',
				valueRegion: {
					page: 0,
					x0: 0,
					y0: 0,
					x1: 0,
					y1: 0,
				},
				valueIgnoreWhitespace: false,
				accumulateFoundValues: false,
			});
		}

		/** Get all head fields */
		const getHeadFields = () => {
			loading.value = true;

			formTrainingApi.getFormHeadFields(props.documentClassId, regionAnchorId.value)
				.then(data => {
					allHeadFields.value = data;
					resetHeadField();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 404) {
						allHeadFields.value = [];
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Train head field */
		const trainHeadField = () => {
			loading.value = true;

			let promise;
			if (headField.id) {
				promise = formTrainingApi.retrainFormHeadField(props.documentClassId, headField.id, headField, true);
			} else {
				promise = formTrainingApi.trainFormHeadField(props.documentClassId, regionAnchorId.value, headField, false);
			}

			promise
				.then(() => {
					getHeadFields();
					resetHeadField();
				}).catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), err.message);
				}).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Change a head field
		 * @param data
		 */
		const changeHeadField = (data: FormTrainingHeadField) => {
			Object.assign(headField, data);
		}

		/**
		 * Deletes a head field
		 * @param data
		 */
		const deleteHeadField = (data: any) => {
			loading.value = true;
			formTrainingApi.deleteFormHeadField(props.documentClassId, data.id)
				.then(() => {
					resetHeadField();
					getHeadFields();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Get all region anchors */
		const getAllRegionAnchors = () => {
			loading.value = true;

			formTrainingApi.getFormHeadRegionAnchors(props.documentClassId, trainingKey.value!.value!)
				.then(data => {
					allRegionAnchors.value = data;

					setRegionAnchorId();
					getHeadFields();
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					if (err.statusCode === 404) {
						allRegionAnchors.value = [];
					} else {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
					}
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Set marker regions by clicking a row
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: any; index: number}) => {
			const row = event.data;
			emit("onMarkRegion", [row.valueRegion]);
		}

		onMounted(() => {
			Object.assign(headField, props.headFieldOfRegionInFormTraining);
			resetHeadField();
			getAllRegionAnchors();
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		watch(() => props.headFieldOfRegionInFormTraining.valuePattern, () => {
			headField.valuePattern = props.headFieldOfRegionInFormTraining.valuePattern;
			headField.valueRegion = props.headFieldOfRegionInFormTraining.valueRegion;
		})

		return {
			t,
			toast,
			trainingKey,
			regionAnchorId,
			headField,
			loading,
			allHeadFields,
			allRegionAnchors,
			onFocusField,
			getHeadFields,
			trainHeadField,
			changeHeadField,
			deleteHeadField,
			getAllRegionAnchors,
			onMarkRegion,
		}
	},
});
