
import {defineComponent, nextTick, onMounted, ref} from "vue";
import DocumentLocatorTestingTable from "@/apps/squeeze/components/DocumentLocatorTestingTable.vue";
import DocumentLocatorTestingForm from "@/apps/squeeze/components/DocumentLocatorTestingForm.vue";
import {DocumentFieldValue, DocumentLocator, ErrorDto, LocatorTestResult} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {useI18n} from "vue-i18n";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import LocatorSubView from "@/apps/administration/views/squeeze/locators/LocatorView.vue";
import Dialog from "primevue/dialog";
import Log from "@/components/Log.vue";
import {LocatorLogLine} from "@dex/squeeze-client-ts";

export default defineComponent({
	name: "LocatorTesting",
	components: {
		LocatorSubView, DocumentLocatorTestingTable, DocumentLocatorTestingForm, Dialog, Log,
	},
	props: {
		documentId: {
			type: Number,
			default: 0,
			required: true,
		},
		/** If the component is opened by smaller components, some forms have to be smaller */
		showSingleLinedForm: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'onMarkWord',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** All Locators */
		const locators = ref<DocumentLocator[]>([]);

		/** Indicates end of request */
		const loading = ref<boolean>(false);

		/** Get selected locator values */
		const locatorValues = ref<DocumentFieldValue[] | undefined>([]);

		/** Should the component LocatorSubview be shown? */
		const renderComponent = ref<boolean>(false);

		/** Entry selected locator values for editing */
		const entryLocatorId = ref<number>(0);

		/** Locator API endpoint */
		const locatorApi = ClientManager.getInstance().squeeze.locator;

		/** Array with all logs */
		const logs = ref<LocatorLogLine[]>([]);

		/** Should the logs be shown? */
		const showLog = ref(false);

		/** Reference to the testing form to calculate its height **/
		const testingForm = ref<typeof DocumentLocatorTestingTable|null>(null);

		/** The calculated height of the testing form for height calculation */
		const testingFormHeight = ref<number>(0);

		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		/** Get all locators from API */
		const getAllLocators = () => {
			loading.value = true;
			locatorApi.getAllLocators()
				.then((allLocators: DocumentLocator[]) => {
					locators.value = allLocators;
				})
				.catch((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all data of the selected locator
		 * @param locator
		 * @param reRenderComponent
		 */
		const getDataOfLocator = (locator: DocumentLocator, reRenderComponent: boolean) => {
			loading.value = true;

			if (reRenderComponent) {
				renderComponent.value = false;
			}

			entryLocatorId.value = locator.id!

			locatorApi.testLocatorOnDocument(locator.id!, props.documentId)
				.then((data: LocatorTestResult) => {
					locatorValues.value = data.values;
					if (data.logLines) {
						logs.value = data.logLines.map((log: LocatorLogLine)=> {
							return { time: log.time, message: log.message };
						})
					}
				})
				.catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Test all data of the selected locator
		 * @param locator
		 */
		const testLocator = (locator: DocumentLocator) => {
			getAllLocators();
			getDataOfLocator(locator, true);
		}

		/** Open Log of current locator
		 * @param showEntryLocator
		 */
		const openEntryLocator = (showEntryLocator: boolean) => {
			renderComponent.value = showEntryLocator;
		}

		/** Open Log of current locator
		 * @param locator
		 */
		const openLog = (locator: DocumentLocator) => {
			showLog.value = true;
		}

		onMounted(async () => {
			await nextTick();
			testingFormHeight.value = testingForm.value ? testingForm.value.$el.clientHeight : 0;
			getAllLocators();
		})

		return {
			locators,
			loading,
			locatorValues,
			renderComponent,
			entryLocatorId,
			showLog,
			logs,
			testingForm,
			testingFormHeight,
			onMarkWord,
			getAllLocators,
			getDataOfLocator,
			testLocator,
			openEntryLocator,
			openLog,
		}
	},
});
