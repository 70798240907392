import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import {squeezeStore, squeezeStoreKey} from '@/apps/squeeze/store'
import {productStore, productStoreKey} from '@/store/product-store'
import { createI18n } from 'vue-i18n'
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import {DEFAULT_LOCALE, Messages, getActiveLanguageMessages} from './lang';
import * as moment from "moment";

/* Material Icons from https://pictogrammers.github.io/@mdi/font/5.4.55/ */
import '@mdi/font/css/materialdesignicons.css'; // Material icons
import 'primevue/resources/themes/md-light-indigo/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                 //core css
import 'primeicons/primeicons.css';                           //icons'
import 'primeflex/primeflex.css'; // layout

/* VueFlex: Import the necessary styles */
import "@vue-flow/core/dist/style.css";
import "@vue-flow/core/dist/theme-default.css";
// import default controls styles
import '@vue-flow/controls/dist/style.css';
// import default minimap styles
import '@vue-flow/minimap/dist/style.css';

// gridstack
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';

import '@/styles/main.scss';
import '@dex/squeeze-viewer/dist/squeeze-viewer.css';
import {Chart, registerables} from "chart.js";
Chart.register(...registerables);

export const i18n = createI18n({
	locale: DEFAULT_LOCALE,
	fallbackLocale: DEFAULT_LOCALE,
	messages: Messages,
});

moment.locale(DEFAULT_LOCALE);

const LanguageMessages = getActiveLanguageMessages();

const app = createApp(App);
app.use(squeezeStore, squeezeStoreKey);
app.use(productStore, productStoreKey);
app.use(router);
app.use(i18n);
app.use(ToastService);
app.use(ConfirmationService);
app.use(PrimeVue, { locale: LanguageMessages.PrimeVue });

app.directive('tooltip', Tooltip);

// TODO: somehow the config does not contain a typing for the `devtools` property.
(app.config as any).devtools = true;

app.component("Button", Button);
app.component("Toast", Toast);
app.mount('#app');
