
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HotKeyList',
	setup() {
		return {};
	},
});

