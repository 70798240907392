
import {defineComponent, ref} from 'vue';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import {useI18n} from "vue-i18n";

interface FieldType {
	operator: string;
	comparator: string;
	text: string;
	date: string;
	amount: string;
}

export default defineComponent({
	name: "HelpFunctionalityList",
	components: {
		DataTable,
		Column,
	},
	props: {
		currentFilterFieldType: {
			type: String,
			required: true,
		},
	},
	setup() {
		const {t} = useI18n();

		/**
		 * Current documentation data to get all functionalities
		 * Handling of (filter) field types
		 */
		const currentDocumentationData = ref<FieldType[]>([
			{
				operator: t('Squeeze.Operators.Equals'),
				comparator: "=",
				text: "=GmbH",
				date: "=31.12.2023",
				amount: "=1012,99",
			},
			{
				operator: t('Squeeze.Operators.NotEqual'),
				comparator: "!=",
				text: "!=GmbH",
				date: "!=31.12.2023",
				amount: "!=1012.99",
			},
			{
				operator: t('Squeeze.Operators.GreaterThan'),
				comparator: ">",
				text: ">1012.99",
				date: ">31.12.2023",
				amount: ">1012.99",
			},
			{
				operator: t('Squeeze.Operators.LowerThan'),
				comparator: "<",
				text: "<1012.99",
				date: "<31.12.2023",
				amount: "<1012.99",
			},
			{
				operator: t('Squeeze.Operators.GreaterOrEqual'),
				comparator: ">=",
				text: ">=1012.99",
				date: ">=31.12.2023",
				amount: ">=1012.99",
			},
			{
				operator: t('Squeeze.Operators.LowerOrEqual'),
				comparator: "<=",
				text: "<=1012.99",
				date: "<=31.12.2023",
				amount: "<=1012.99",
			},
			/*{
				"id": "isZero",
				"comparator": "",
				"text": "",
				"date": "",
				"amount": "",
			},
			{
				"id": "isNotZero",
				"comparator": "",
				"text": "",
				"date": "",
				"amount": "",
			},
			{
				"id": "in",
				"comparator": "",
				"text": "",
				"date": "",
				"amount": "",
			},
			{
				"id": "notIn",
				"comparator": "",
				"text": "",
				"date": "",
				"amount": "",
			},*/
			{
				operator: t('Squeeze.Operators.StartsWith'),
				comparator: "abc*",
				text: "GmbH*",
				date: "-",
				amount: "-",
			},
			/*{
					operator: t('Squeeze.Operators.NotStartsWith'),
					comparator: "-",
					text: "-",
					date: "-",
					amount: "-",
				},*/
			{
				operator: t('Squeeze.Operators.EndsWith'),
				comparator: "*abc",
				text: "*GmbH",
				date: "-",
				amount: "-",
			},
			/*{
					operator: t('Squeeze.Operators.NotEndsWith'),
					comparator: "-",
					text: "-",
					date: "-",
					amount: "-",
				},*/
			{
				operator: t('Squeeze.Operators.Contains'),
				comparator: "*abc*",
				text: "*GmbH*",
				date: "-",
				amount: "-",
			},
			/*{
				operator: t('Squeeze.Operators.NotContains'),
				comparator: "-",
				text: "-",
				date: "-",
				amount: "-",
			},*/]);

		return {
			t,
			currentDocumentationData,
		}
	},
})
