
import {computed, defineComponent, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import Checkbox from 'primevue/checkbox';

export default defineComponent({
	name: "ReportSystemView",
	components: {
		Checkbox,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Show Loading on load data */
		const loading = ref(false);

		/** All reset options for reports */
		const resets = computed(() => {
			return [
				{
					name: t("Squeeze.Reports.DeleteValidationLog"),
					description: t("Squeeze.Reports.ResetAutovalidatedDocuments"),
					key: "validationLog",
				},
				{
					name: t("Squeeze.Reports.DeleteExtractionData"),
					description: t("Squeeze.Reports.ResetExtractionValues"),
					key: "extractionData",
				},
			]
		});

		/** Selected resets for reports */
		const selectedResets = ref([]);

		/** Service for getting all report services */
		const reportApi = ClientManager.getInstance().squeeze.report;

		/** Run report reset */
		const runReset = () => {
			if (!selectedResets.value.length) {
				return;
			}

			loading.value = true;

			const promises: any[] = [];
			selectedResets.value.forEach(option => {
				if (option === t("Squeeze.Reports.DeleteValidationLog")) {
					promises.push(reportApi.deleteValidationReport());
				} else if (option === t("Squeeze.Reports.DeleteExtractionData")) {
					promises.push(reportApi.deleteExportReport());
				}
			});

			Promise.all(promises)
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Reports.ResetSuccess'));
				}).catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
					selectedResets.value = [];
				})
		}

		return {
			t,
			toast,
			loading,
			resets,
			selectedResets,
			runReset,
		};
	},
});

