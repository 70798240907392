
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {BatchClass, BatchClassClassification, MailImportConfig} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';
import Dropdown from 'primevue/dropdown';
import Mail from "@/apps/administration/components/imports/Mail.vue";
import MailForm from "@/apps/administration/components/imports/MailForm.vue";
import router from "@/router";
import EncryptionEnum = MailImportConfig.EncryptionEnum;
import ProtocolEnum = MailImportConfig.ProtocolEnum;

interface MailImportMicrosoftConfig extends MailImportConfig {
	clientId?: string;
	tenantId?: string;
}

export default defineComponent({
	name: "MailSubView",
	components: {
		MailForm, Mail, DialogDelete, EntryDialog, BlockUI, Dropdown,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const mailForm = ref<InstanceType<typeof MailForm>>()

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the mails invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the import-data */
		const importService = ClientManager.getInstance().squeeze.import;

		/** List of all mails */
		const mails = ref<MailImportMicrosoftConfig[]>([]);

		/** Should the Entry-Dialog for mails be shown? */
		const showDialog = ref<boolean>(false);

		/** Should the Dialog of test mail connection be shown? */
		const showTestMailConnectionDialog = ref<boolean>(false);

		/** Message in test mail connection Dialog */
		const messageTestMailConnectionDialog = ref<string>('');

		/** Currently active mail */
		const mail = reactive<MailImportMicrosoftConfig>({
			batchClassId: 1,
			documentClassId: undefined,
			server: '',
			port: undefined,
			protocol: ProtocolEnum.Imap,
			encryption: EncryptionEnum.Ssl,
			checkCertificate: false,
			user: '',
			password: '',
			clientId: '0',
			tenantId: '0',
			inboxFolder: '',
			validFolder: '',
			errorFolder: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Array with the batchClasses */
		const batchClasses = ref<BatchClass[]>([]);

		/** ID of the batchClass */
		const batchClassId = ref<number>(1);

		/** Service for getting the all batchClasses */
		const batchClassService = ClientManager.getInstance().squeeze.batchClass;

		/** Array with the documentClasses of batchClassClassifications */
		const documentClasses = ref<BatchClassClassification[]>([]);

		/** Service for getting the all documentClasses */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Should the Feedback-Dialog for delegated mail config be shown? */
		const showFeedbackDialog = ref<boolean>(false);

		/** Text of the header in Feedback-Dialog */
		const headerFeedbackText = ref<string>('');

		/** Message in Feedback-Dialog */
		const feedbackMessage = ref<string>('');

		/** Mail ID of Feedback */
		const feedbackMailId = ref<string>('');

		/** Should the Column for authenticate button be shown? */
		const showAuthenticateButtonColumn = ref<boolean>(false);

		/** Should the Authenticate-Dialog for delegated mail config be shown? */
		const showAuthenticateDialog = ref<boolean>(false);

		/** Authenticate Message in Authenticate-Dialog */
		const authenticateMessage = ref<string>('');

		/** Authenticate mail id */
		const authenticateMailId = ref<number | undefined>(undefined);

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			loading.value = true;
			batchClassService.getBatchClassClassifications(batchClassId.value)
				.then(data => {
					documentClasses.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all BatchClasses */
		const getAllBatchClasses = () => {
			loading.value = true;
			batchClassService.getAllBatchClasses()
				.then(data => {
					batchClasses.value = data;
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: MailImportMicrosoftConfig, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(mail, data)
		}

		/**
		 * Test the Mail Connection of Row
		 * @param mailConfig
		 */
		const testMailConnection = (mailConfig: MailImportMicrosoftConfig) => {
			loading.value = true;

			// set mail user together
			if (mailConfig.protocol === ProtocolEnum.Microsoft || mail.protocol === ProtocolEnum.MicrosoftDelegated) {
				mailConfig.user = mailConfig.user +'/' + mailConfig.clientId + '/' + mailConfig.tenantId;
			}

			importService.testMailConnection(mailConfig)
				.then((data: number[]) => {
					if (data && data[0] >= 0) {
						messageTestMailConnectionDialog.value = t('Squeeze.Imports.TestMailConnectionMessage', { number: data[0]});
						showTestMailConnectionDialog.value = true;
					} else {
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Imports.SuccessEmailConnection'));
					}
				})
				.catch(response => response.json().then((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Fetch E-Mails
		 * @param mailId
		 */
		const fetchMails = (mailId: number) => {
			loading.value = true;

			importService.fetchEmails([mailId])
				.then(() => {
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Imports.SuccessMailFetch'))
				})
				.catch(response => response.json().then((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>(t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Imports.EmailAccounts') }));

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: MailImportMicrosoftConfig) => {
			Object.assign(mail, row);

			// split mail user string
			if ((mail.protocol === ProtocolEnum.Microsoft || mail.protocol === ProtocolEnum.MicrosoftDelegated) && mail.user.includes('/')) {
				const mailUser = mail.user.split('/');
				mail.user = mailUser[0];
				mail.clientId = mailUser[1];
				mail.tenantId = mailUser[2];
			}

			// make sure batchClassId always right
			mail.batchClassId = batchClassId.value

			showDialog.value = true;
		}

		/** Reloads the table to get all Mail imports*/
		const reloadData = () => {
			loading.value = true;
			importService.getMailImportConfigs(batchClassId.value)
				.then(data => {
					mails.value = data;

					// split mail user string
					for (const mail of mails.value) {
						if (mail.protocol === ProtocolEnum.Microsoft || mail.protocol === ProtocolEnum.MicrosoftDelegated) {
							const mailUser = mail.user.split('/');
							mail.user = mailUser[0];
							mail.clientId = mailUser[1];
							mail.tenantId = mailUser[2];
						}
					}

					// check count of microsoft delegated mail configs to show the column in table
					const microsoftDelegatedCount = data.filter((mail => mail.protocol === ProtocolEnum.MicrosoftDelegated));
					if (microsoftDelegatedCount.length > 0) {
						showAuthenticateButtonColumn.value = true;
					}
				})
				.catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(() =>{
			getAllBatchClasses();
			getAllDocumentClasses();
			reloadData();

			// check router query params for feedback dialog
			const routerQueryState = router.currentRoute.value.query.state;
			if (routerQueryState) {
				showFeedbackDialog.value = true;
				if (routerQueryState !== 'error') {
					headerFeedbackText.value = t('Squeeze.General.Success');
				} else {
					headerFeedbackText.value = t('Squeeze.General.Error');
				}
				feedbackMessage.value = router.currentRoute.value.query.message as string;
				feedbackMailId.value = router.currentRoute.value.query.configId as string;
			}
		})

		const startAuthenticate = (mailId?: number) => {
			if (mailId) {
				const link = document.createElement("a");
				link.href = ClientManager.getInstance().getSqueezeBasePath() + '/importers/email/' + mailId + '/authenticate/start';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			showAuthenticateDialog.value = false;
		}

		/** Saves an mail */
		const saveMail = (keepDialogOpen: boolean = false) => {
			// set values when protocol is microsoft graph api or microsoft graph api delegated
			if ((mail.protocol === ProtocolEnum.Microsoft || mail.protocol === ProtocolEnum.MicrosoftDelegated) && mail.user && mail.password && mail.inboxFolder && mail.validFolder && mail.errorFolder) {
				isValidationInvalid.value = false;
				mail.server = 'https://graph.microsoft.com';
				mail.port = 0;
				mail.encryption = EncryptionEnum.Tls;
				mail.checkCertificate = true;
				mail.user = mail.user + '/' + mail.clientId + '/' + mail.tenantId;
			}

			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let mailChangePromise = null;

			// make sure batchClassId always right
			mail.batchClassId = batchClassId.value;

			if (!mail.documentClassId) {
				mail.documentClassId = undefined;
			}

			if (!mail.id) {
				mailChangePromise = importService.postMailImportConfig(mail);
			} else {
				mailChangePromise = importService.putMailImportConfig(mail.id, mail);
			}

			mailChangePromise.then(() => {
				// call authenticate of microsoft delegated
				if (mail.protocol === ProtocolEnum.MicrosoftDelegated && mail.id) {
					authenticateMailId.value = mail.id;
					showAuthenticateDialog.value = true;
					authenticateMessage.value = t('Squeeze.Imports.ChangeAuthenticationMessage');
				} else if (mail.protocol === ProtocolEnum.MicrosoftDelegated) {
					authenticateMailId.value = undefined;
					showAuthenticateDialog.value = true;
					authenticateMessage.value = t('Squeeze.Imports.StartAuthenticationMessage');
				}

				if (!keepDialogOpen) {
					showDialog.value = false;
				}
				else {
					// Empty values and focus first input-field
					mail.documentClassId = undefined;
					mail.server = '';
					mail.port = undefined;
					mail.protocol = ProtocolEnum.Imap;
					mail.encryption = EncryptionEnum.Ssl;
					mail.checkCertificate = false
					mail.user = '';
					mail.password = '';
					mail.clientId = '0';
					mail.tenantId = '0';
					mail.inboxFolder = '';
					mail.validFolder = '';
					mail.errorFolder = '';

					mailForm.value?.$el.querySelector('input').focus();
					isValidationInvalid.value = true;
				}
				reloadData();
			}).catch(response => response.json().then ((err: any) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MailImportMicrosoftConfig) => {
			deleteDialog.value = true;
			Object.assign(mail, row);
		}

		/** Deletes a mail */
		const deleteEntry = () => {
			if (mail && mail.id) {
				loading.value = true;
				importService.deleteMailImportConfig(mail.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Triggered when batchClassId is change */
		const onChangeBatchClass = () => {
			reloadData();
			getAllDocumentClasses();
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, documentClasses, documentClassService,
			showDialog, showTestMailConnectionDialog, messageTestMailConnectionDialog, headerText, mails, mail, deleteDialog, mailForm, importService, batchClasses, batchClassService, batchClassId,
			showFeedbackDialog, headerFeedbackText, feedbackMessage, feedbackMailId, showAuthenticateButtonColumn, showAuthenticateDialog, authenticateMessage, authenticateMailId, startAuthenticate,
			getAllDocumentClasses, getAllBatchClasses, deleteEntry, openDeleteDialog, onUpdate, testMailConnection, fetchMails, onEntrySelect, saveMail, onChangeBatchClass};
	},
});

