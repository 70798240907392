
import {computed, defineComponent} from 'vue';
import {useI18n} from "vue-i18n";
import TabMenu from "@/components/DexTabMenu.vue";
import {MenuItem} from "@/shims-prime-vue";

export default defineComponent({
	name: 'XmlMapperView',
	components: {
		TabMenu,
	},
	props: {},
	setup(props) {
		const {t} = useI18n();

		/** List of all Tab-Items */
		const items = computed(() => {
			const itemsList: MenuItem[] = [
				{label: t('Squeeze.XmlMapper.HeadData'), to: { name: 'XmlHeadDataView'}},
				{label: t('Squeeze.XmlMapper.PositionData'), to: { name: 'XmlPositionDataView'}},
			]

			//itemsList.push({label: t('Squeeze.XmlMapper.Testing'), to: { name: 'XmlMapperTestView'}})

			return itemsList;
		});

		return {
			t,
			items,
		};
	},
});

