
import Panel from 'primevue/panel';
import {defineComponent, ref} from "vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import BlockUI from "primevue/blockui";
import router from "@/router";
import ResetPasswordForm from "@/components/ResetPasswordForm.vue";
import { PasswordReset } from '@dex/squeeze-client-ts';
import {useRoute} from "vue-router";

export default defineComponent({
	name: "ResetPassword",
	components: {Panel, ResetPasswordForm, BlockUI},
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Should the ui be blocked? */
		const loading = ref(false);

		/** Service for public requests */
		const publicApi = ClientManager.getInstance().squeeze.public;

		/** Sends a mail to reset the password to a specific user */
		const resetPassword = (password: string) => {
			if (!route.query.token) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Login.NoToken'));
				return;
			}

			const passwordReset: PasswordReset = {
				password: password,
				token: route.query.token as string,
			}

			loading.value = true;
			publicApi.resetPasswordByToken(passwordReset).then(() => {
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.Login.ResetSuccess'));
				router.push({name: "Login"});
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loading.value = false;
			})
		}
		return {loading,
			resetPassword}
	},
})
