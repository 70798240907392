
import {defineComponent, ref} from "vue";
import ValueListView from "@/apps/administration/views/squeeze/locators/tabs/ValueListView.vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {LocatorValueList} from "@dex/squeeze-client-ts";
import SearchValueView from "@/apps/administration/views/squeeze/locators/tabs/SearchValueView.vue";

export default defineComponent({
	name: "KeyWordsToValueView",
	components: {
		ValueListView, SearchValueView,
	},
	props: {
		locatorId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: ['onLocatorChange'],
	setup() {
		const {t} = useI18n();
		const toast = useToast();
		const valueListValue = ref<string|undefined>(undefined);

		/** Triggered when a value list value is chosen */
		const onChangeValue = (value: LocatorValueList) => {
			valueListValue.value = value.value;
		}

		return {t, toast, valueListValue,
			onChangeValue};
	},
});
