
import {defineComponent, nextTick, onMounted, PropType, reactive, watch} from "vue";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {MasterDataTablePermission, UserRole} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "MasterDataTablePermissionForm",
	components: {
		Dropdown,
		Checkbox,
	},
	props: {
		masterDataPermission: {
			type: Object as PropType<MasterDataTablePermission>,
			default: () => ({}),
		},
		showErrorMessage: {
			type: Boolean,
		},
		allRoles: {
			type: Array as PropType<UserRole[]>,
			default: () => [],
		},
		dialogMode: {
			type: String,
			default: '',
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		/** Current Object of all input-fields */
		const value = reactive<MasterDataTablePermission>({tableId: undefined, roleId: undefined, canManage: false, canEdit: false});

		/** Determines the required rules for validation */
		const rules = {
			roleId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.masterDataPermission);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.masterDataPermission, async () => {
			Object.assign(value, props.masterDataPermission);
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// need nextTick, because the dropdown don't check the change of a selected element (is to slow)
			await nextTick();
			emit("update", value, v$.value.$invalid);
		}

		return {
			value,
			props,
			v$,
			Dropdown,
			Checkbox,
			update,
			showDropdownOverlay,
		}
	},
});
