import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-component p-fluid p-formgrid p-grid" }
const _hoisted_2 = { class: "p-col-3" }
const _hoisted_3 = { style: {"font-size":"1rem"} }
const _hoisted_4 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "p-col-3" }
const _hoisted_7 = { style: {"font-size":"1rem"} }
const _hoisted_8 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "p-col-3" }
const _hoisted_11 = { style: {"font-size":"1rem"} }
const _hoisted_12 = { class: "p-col-9" }
const _hoisted_13 = { class: "p-field p-input-filled p-inputgroup" }
const _hoisted_14 = { class: "p-col-3" }
const _hoisted_15 = { style: {"font-size":"1rem"} }
const _hoisted_16 = { class: "p-col-9" }
const _hoisted_17 = { class: "p-field p-input-filled p-inputgroup" }
const _hoisted_18 = { class: "p-col-3" }
const _hoisted_19 = { style: {"font-size":"1rem"} }
const _hoisted_20 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_21 = { class: "p-col-3" }
const _hoisted_22 = { style: {"font-size":"1rem"} }
const _hoisted_23 = { class: "p-field p-input-filled p-col-9" }
const _hoisted_24 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DocumentFieldTrainingTable = _resolveComponent("DocumentFieldTrainingTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.trainingKey.description), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.trainingKey.value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trainingKey.value.value) = $event)),
          disabled: ""
        }, null, 8, ["modelValue"]),
        (!(_ctx.trainingKey.value.value !== '' && _ctx.trainingKey.value.value !== null))
          ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t("Squeeze.Training.TrainingKey")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("Squeeze.DocumentClasses.Field")), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.value.fieldId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.fieldId) = $event)),
          options: _ctx.documentClassFields.filter((field) => field.trainable === true),
          optionLabel: "description",
          optionValue: "id",
          filter: true,
          autoFilterFocus: true,
          onChange: _ctx.onChangeDocumentField,
          onShow: _ctx.showDropdownOverlay
        }, null, 8, ["modelValue", "options", "onChange", "onShow"]),
        (_ctx.warnAmount)
          ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t("Squeeze.Training.WarnAmount")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("Squeeze.Training.Anchor")), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.value.keyWordPattern,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.keyWordPattern) = $event)),
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFocusField('keyWordPattern')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            class: "active",
            icon: "mdi mdi-auto-fix",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onFocusField('keyWordPattern')))
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("Squeeze.Locators.Value")), 1)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_InputText, {
            type: "text",
            modelValue: _ctx.value.valuePattern,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.valuePattern) = $event)),
            onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFocusField('valuePattern')))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_Button, {
            class: "active",
            icon: "mdi mdi-auto-fix",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onFocusField('valuePattern')))
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("Squeeze.Training.ResultValue")), 1)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.value.replaceValue,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.value.replaceValue) = $event)),
          maxLength: "250"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t("Squeeze.Training.IgnoreSpaces")), 1)
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.value.valueIgnoreWhitespace,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.value.valueIgnoreWhitespace) = $event)),
          options: _ctx.trainWhitespaceOptions,
          optionLabel: "name",
          optionValue: "id"
        }, null, 8, ["modelValue", "options"])
      ])
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createVNode(_component_Button, {
        onClick: _ctx.trainFieldValues,
        class: "p-mr-2",
        label: _ctx.showTrainDocument ? _ctx.$t('Squeeze.Training.Train')
				:_ctx.$t('Squeeze.Training.Retrain'),
        disabled: ((!(_ctx.value.fieldId !== 0 && _ctx.value.keyWordPattern !== '' && _ctx.value.valuePattern !== ''
				&& _ctx.trainingKey.value.value !== '' && _ctx.trainingKey.value.value !== null)))
      }, null, 8, ["onClick", "label", "disabled"]),
      _createVNode(_component_Button, {
        onClick: _ctx.testingFieldValues,
        label: _ctx.$t('Squeeze.Training.Test'),
        disabled: (_ctx.tableTrainingValues.length < 1) || _ctx.loading
      }, null, 8, ["onClick", "label", "disabled"])
    ]),
    _createVNode(_component_DocumentFieldTrainingTable, {
      fieldTestingValues: _ctx.fieldTestingValues,
      tableTrainingValues: _ctx.tableTrainingValues,
      clickOnTestingButton: _ctx.clickOnTestingButton,
      loaded: _ctx.loading,
      documentClassFields: _ctx.documentClassFields,
      onDeleteTrainingRow: _ctx.deleteTrainingRow,
      onChangeTrainingRow: _ctx.changeTrainingRow,
      onOnMarkRegion: _ctx.onMarkRegion
    }, null, 8, ["fieldTestingValues", "tableTrainingValues", "clickOnTestingButton", "loaded", "documentClassFields", "onDeleteTrainingRow", "onChangeTrainingRow", "onOnMarkRegion"])
  ], 64))
}