
import {defineComponent, onMounted, reactive, ref} from 'vue';
import LocatorAttributes from "@/apps/administration/components/locators/LocatorAttributes.vue";
import LocatorAttributeForm from "@/apps/administration/components/locators/LocatorAttributeForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import BlockUI from 'primevue/blockui';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {DocumentLocator, LocatorSetting} from "@dex/squeeze-client-ts";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";

export default defineComponent({
	name: "LocatorAttributesSubView",
	components: {
		LocatorAttributes,
		LocatorAttributeForm,
		EntryDialog,
		DialogDelete,
		BlockUI,
	},
	props: {
		/** Current locator ID */
		locatorId: {
			type: Number,
			default: 0,
		},
	},
	emits: ['onLocatorChange'],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** ID of the current locator-type */
		const locatorTypeId = ref<number|null>(null);

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all locators **/
		const locatorSettings = ref<LocatorSetting[]>([]);

		/** One Entry of Locator Settings */
		const entryFieldAttribute = reactive<LocatorSetting>({});

		/** List of all valid locators-setting **/
		const supportedLocatorSettings = ref<LocatorSetting[]>([]);

		/** Should the loading be shown in the table? */
		const loading = ref<boolean>(false);

		/** Should the Edit-Dialog be shown? */
		const showEditDialog = ref<boolean>(false);

		/** Message To Show in Entry-Dialogs */
		const message = ref<string>('');

		/** Message-Type to set when showing a message (see: Message-Component in PrimeVue */
		const messageType = ref<string>('');

		/** Text of the header in Entry-Dialog */
		const headerText = ref<string>('');

		/** Show delete Dialog? */
		const showDeleteDialog = ref<boolean>(false);

		/** Should the display-name be shown as disabled? */
		const disableSetting = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);

		/** Gets the Locator-Attributes */
		const getLocatorAttributes = () => {
			loading.value = true;
			const handleErr = (msg: string, err: any) => ToastManager.showError(toast, t('Squeeze.General.Error'), t(msg) + ": " + err);

			// Get Supported Locator Settings for current locator
			locatorService.getLocatorById(props.locatorId)
				.then((data: DocumentLocator) => {
					locatorService.getSupportedLocatorTypeSettings(data.locatorType!)
						.then((data: LocatorSetting[]) => {
							supportedLocatorSettings.value = data;
						}).catch(response => response.json().then ((err: { message: string }) => {
							handleErr('Squeeze.Locators.ErrorLoadLocatorsAttributes', err.message);
						}))
				})
				.catch(err => handleErr('Squeeze.Locators.ErrorLoadLocators', err))

			locatorService.getLocatorSettings(props.locatorId)
				.then((data: LocatorSetting[]) => {
					loading.value = false;
					locatorSettings.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					loading.value = false;
					handleErr('Squeeze.Locators.ErrorLoadLocatorsAttributes', err.message);
				}))
		}

		/** Opens the Delete-Dialog */
		const openDeleteDialog = (locatorSetting: LocatorSetting) => {
			Object.assign(entryFieldAttribute, locatorSetting);
			showDeleteDialog.value = true;
		}

		/**
		 * Opens the Edit-Dialog
		 * @param locatorSetting Locator-Attribute to show in the Edit-Dialog
		 */
		const openEditDialog = (locatorSetting: LocatorSetting) => {
			Object.assign(entryFieldAttribute, locatorSetting);
			entryFieldAttribute.locatorId = props.locatorId;
			if (entryFieldAttribute.setting && entryFieldAttribute.setting !== "") {
				disableSetting.value = true;
				headerText.value = t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.Locators.LocatorAttribute') });
			}
			else {
				disableSetting.value = false;
				headerText.value = t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.Locators.LocatorAttribute') });
			}
			showEditDialog.value = true;
		}

		/** Saves a Locator Attribute */
		const saveLocatorAttribute = () => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			showErrorMessage.value = false;
			loading.value = true;

			if (entryFieldAttribute && entryFieldAttribute.id) {
				locatorService.putLocatorSetting(props.locatorId, entryFieldAttribute.id, entryFieldAttribute)
					.then(() => {
						showEditDialog.value = false;
						getLocatorAttributes();
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.SaveError'), err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			} else {
				locatorService.postLocatorSetting(props.locatorId, entryFieldAttribute)
					.then(() => {
						showEditDialog.value = false;
						getLocatorAttributes();
						ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.General.CreateSuccess'));
					})
					.catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.SaveError'), err.message);
					}))
					.finally(() => {
						loading.value = false;
					})
			}
		}

		/** Deletes a Locator-Attribute */
		const deleteLocatorAttribute = () => {
			loading.value = true;
			if (entryFieldAttribute && entryFieldAttribute.id) {
				locatorService.deleteLocatorSetting(props.locatorId, entryFieldAttribute.id, entryFieldAttribute)
					.then(() => {
						getLocatorAttributes();
						showDeleteDialog.value = false;
					}).catch(response => response.json().then ((err: { message: string }) => {
						ToastManager.showError(toast, t('Squeeze.General.ErrorDelete'), t('Squeeze.General.DeleteError') + ": " + err.message);
					})).finally(() => {
						loading.value = false;
					});
			}
		}

		/** Triggered on update of attribute-form */
		const onUpdate = (data: LocatorSetting, valid: boolean) => {
			isInvalid.value = valid;
			Object.assign(entryFieldAttribute, data);
		}

		onMounted(() =>{
			getLocatorAttributes();
		})

		return {
			t,
			toast,
			locatorTypeId,
			locatorSettings,
			entryFieldAttribute,
			supportedLocatorSettings,
			loading,
			showEditDialog,
			message,
			messageType,
			headerText,
			showDeleteDialog,
			disableSetting,
			showErrorMessage,
			isInvalid,
			getLocatorAttributes,
			openDeleteDialog,
			openEditDialog,
			saveLocatorAttribute,
			deleteLocatorAttribute,
			onUpdate,
		};
	},
});

