
import {defineComponent, ref} from 'vue';
import {ClientManager} from "@/singletons/ClientManager";

export default defineComponent({
	name: "ApiDocumentationView",
	setup() {
		/** Squeeze base path */
		const squeezeBasePath = ref<string>(ClientManager.getInstance().getSqueezeBasePath());

		return {squeezeBasePath};
	},
});

