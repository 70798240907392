import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-708a5c19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-p-2 p-ml-2 p-component" }
const _hoisted_2 = { class: "header p-component" }
const _hoisted_3 = { class: "p-grid p-jc-between p-p-2" }
const _hoisted_4 = { class: "p-col-4" }
const _hoisted_5 = { class: "p-col-8 p-text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _component_DialogDelete = _resolveComponent("DialogDelete")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('Squeeze.DocumentClasses.FieldsLayout')), 1),
      _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _withDirectives(_createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.DocumentClasses.Divider'),
                class: "p-button p-mr-2 p-mb-2",
                onClick: _ctx.addLineWidget,
                icon: "mdi mdi-alert-outline"
              }, null, 8, ["label", "onClick"]), [
                [_directive_tooltip, _ctx.$t('Squeeze.DocumentClasses.DividerCaution')]
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.General.Save'),
                class: "p-button p-mr-2 p-mb-2",
                icon: "mdi mdi-content-save-outline",
                onClick: _ctx.saveLayout
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_Button, {
                label: _ctx.$t('Squeeze.DocumentClasses.LayoutReset'),
                class: "p-button p-mb-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog = true)),
                style: {"background-color":"var(--error-color)"}
              }, null, 8, ["label"])
            ])
          ]),
          _createElementVNode("section", {
            class: _normalizeClass(["grid-stack", 'grid-stack-group-' + _ctx.fieldGroupId])
          }, null, 2)
        ]),
        _: 1
      }, 8, ["blocked"])
    ]),
    _createVNode(_component_DialogDelete, {
      showDialog: _ctx.showDialog,
      headerLabel: _ctx.$t('Squeeze.DocumentClasses.RestoreDefaultFieldsLayout'),
      messageLabel: _ctx.$t('Squeeze.DocumentClasses.MessageRestoreDefaultFieldsLayout'),
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false)),
      onOnConfirm: _ctx.resetLayout
    }, null, 8, ["showDialog", "headerLabel", "messageLabel", "onOnConfirm"])
  ], 64))
}