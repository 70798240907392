
import {defineComponent, onBeforeUnmount, onMounted, PropType, ref} from 'vue';
import { useI18n } from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {getSqueezeStepIconMap} from "@/apps/squeeze/App.vue";
import Timeline from "primevue/timeline";
import QueueTimelineElement from "@/apps/squeeze/components/QueueTimelineElement.vue";
import Skeleton from "primevue/skeleton";
import {DocumentClass, QueueStepDto} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

export default defineComponent({
	name: "QueueTimeline",
	components: {
		Timeline,
		QueueTimelineElement,
		Skeleton,
	},
	props: {
		allDocumentClasses: {
			type: Array as PropType<DocumentClass[]>,
			default: () => [],
		},
	},
	emits: ['onClickQueueStep'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** List of all document classes in QueueStep */
		const queueSteps = ref<QueueStepDto[]>([]);

		/** Indicates end of request */
		const loaded = ref<boolean>(false);

		/** Interval handle for polling */
		const pollingInterval = ref<number|undefined>(undefined);

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;

		/**
		 * Reloads data of view
		 * @param {boolean} polling
		 */
		const reloadData = (polling: boolean = false) => {
			// Load QueueSteps
			if (!polling) loaded.value = false;
			queueApi.getQueueSteps().then((steps: QueueStepDto[]) => {
				queueSteps.value = steps;
			}).catch(err => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err.statusText);
			}).finally(() => {
				if(!polling) loaded.value = true;
			});
		}

		/**
		 * Click on queue step (emitted)
		 * @param {string} stepName
		 * @param {string} status
		 */
		const onClickQueueStep = (stepName: string, status: string) => {
			emit('onClickQueueStep', stepName, status);
		}

		/** On page mounted */
		onMounted(() =>{
			getSqueezeStepIconMap().forEach((icon: string, step: string) => {
				queueSteps.value.push({
					"name": step,
				} as any);
			});

			loaded.value = true;
			reloadData();
			pollingInterval.value = setInterval(reloadData, 5000, true);
		})

		/** Is triggered before a Component unmounts. Available since Vue 3.0 */
		onBeforeUnmount(() => {
			// Clear timer handler to stop polling
			clearInterval(pollingInterval.value);
		})

		return {
			t,
			toast,
			queueSteps,
			loaded,
			pollingInterval,
			reloadData,
			onClickQueueStep,
		};
	},
});

