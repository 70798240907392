
import {defineComponent, onMounted, PropType, reactive} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Badge from 'primevue/badge';
import Tooltip from "primevue/tooltip";
import {Job, ScriptDto} from "@dex/squeeze-client-ts";
import {useSqueezeStore} from "@/apps/squeeze/store";
import router from "@/router";
import {ClientManager} from "@/singletons/ClientManager";
import {TableListSettings, TableSettings} from "@/util/TableSettings";
import {useRoute} from "vue-router";

export default defineComponent({
	name: "JobManagementList",
	components: {
		DataTable, Column, Badge,
	},
	props: {
		jobs: {
			type: Array as PropType<Job[]>,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		allScripts: {
			type: Array as PropType<ScriptDto[]>,
			default: () => [],
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	emits: ["onClickDelete", "onEntrySelect"],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Current table list pagination */
		const tableListPagination = reactive<TableListSettings>({});

		/**
		 * Opens the Delete-Dialog
		 * @param row The row to delete
		 */
		const openDeleteDialog = (row: Job) => {
			emit("onClickDelete", row);
		}

		/** Triggered when the New Entry Button is clicked */
		const onNewEntryClick = () => {
			emit("onEntrySelect", {
				id: undefined,
				name: '',
				cronExpression: '',
				scriptId: '',
			})
		}

		/**
		 * Triggered when a row is clicked to edit
		 * @param row The row to edit
		 */
		const openEditDialog = (row: Job) => {
			if(!store.state.scopes.sqzJobsManage && store.state.scopes.sqzJobsRead) {
				return
			}
			emit("onEntrySelect", row)
		}

		/**
		 * Get the Name of a Script
		 * @param currentJob
		 */
		const getScriptName = (currentJob: Job) => {
			const job = props.allScripts.find(script => script.id === currentJob.scriptId);

			if (job) {
				return job.name;
			}

			return t('Squeeze.System.ScriptNotFound');
		}

		/** Go to the cronTab guru, when badge clicked */
		const goToCronGuru = () => {
			window.open('https://crontab.guru/');
		}

		/** Triggered to show the Job Runs
		 * @param {Job} jobData
		 */
		const openJobRuns = (jobData: Job) => {
			router.push({ name: 'JobRunsView', params: { jobId: String(jobData.id) }});
		}

		onMounted(() => {
			tableListPagination.pageSize = TableSettings.getTableListPagination(store, route.name);
		});

		/** Triggered when page is changed */
		const onChangePage = (event: { rows: number }) => {
			tableListPagination.pageSize = event.rows;
			TableSettings.saveTableListPagination(t, toast, store, userApi, route.name, event.rows);
		}

		return {
			t, toast, store, tableListPagination, openDeleteDialog, onNewEntryClick, openEditDialog, getScriptName, goToCronGuru, openJobRuns, onChangePage,
		};
	},
});

