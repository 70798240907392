
import {computed, defineComponent, onMounted, PropType, reactive, ref, watch} from "vue";
import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import {
	DocumentField, DocumentTableColumn,
	FieldLookupFilter,
	MasterDataColumn
} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {showDropdownOverlay} from "@/util/StylesHelper";

export default defineComponent({
	name: "LookupFiltersForm",
	components: {
		Dropdown,
	},
	props: {
		lookupFilter: {
			type: Object as PropType<FieldLookupFilter>,
			required: true,
		},
		documentClassFields: {
			type: Array as PropType<DocumentField[]>,
			default: () => [],
		},
		masterDataTableColumns: {
			type: Array as PropType<MasterDataColumn[]>,
			default: () => [],
		},
		showErrorMessage: {
			type: Boolean,
		},
		tableColumns: {
			type: Array as PropType<DocumentTableColumn[]>,
			default: () => [],
			required: true,
		},
		showTableRows: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref(false);

		/** Current Object of all input-fields */
		const value = reactive<FieldLookupFilter>({
			valueFieldId: undefined,
			id: undefined,
			masterDataColumnId: undefined,
			rowBasedFilter: false,
		});

		/** List of active booleans */
		const operands = computed(() => {
			return [
				{
					id: "eq",
					name: t('Squeeze.DocumentClasses.Equal'),
				},
				{
					id: "cn",
					name: t('Squeeze.DocumentClasses.Contains'),
				},
			]
		});

		/** List of active booleans */
		const fieldTypes = computed(() => {
			return [
				{
					id: false,
					name: t('Squeeze.DocumentClasses.Head'),
				},
				{
					id: true,
					name: t('Squeeze.DocumentClasses.Position'),
				},
			]
		});

		/** Determines the required rules for validation */
		const rules = {
			masterDataColumnId: { required },
			valueFieldId: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.lookupFilter);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.lookupFilter, () => {
			Object.assign(value, props.lookupFilter);
		})

		/** Triggered on change of any field */
		const update = async () => {
			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		}
		/** Triggered on change of row base filter */
		const onChangeRowBaseFilter = async () => {
			value.valueFieldId = undefined;
			await update();
		}

		return {
			t,
			toast,
			loading,
			value,
			props,
			v$,
			operands,
			fieldTypes,
			InputText,
			Dropdown,
			update,
			onChangeRowBaseFilter,
			showDropdownOverlay,
		}
	},
});

