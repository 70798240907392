
import {defineComponent, PropType, ref} from "vue";
import Password from "primevue/password";
import Message from 'primevue/message';

export default defineComponent({
	name: "ResetPasswordForm",
	props: {
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	components: {
		Password, Message,
	},
	emit: ['onClickSend'],
	setup(props, {emit}) {
		/** Password of the user */
		const password = ref('');

		/** Repeat of password */
		const passwordRepeat = ref('');

		/** Should the error be shown? */
		const showErrorEqual = ref(false);

		/** Should the error message be shown? */
		const showMessage = ref(false);

		/** Should the error message for mandatory fields be shown? */
		const showErrorMandatory = ref(false);

		/**
		 * Checks if the the two passwords are valid
		 */
		const arePasswordsValid = () => {
			showErrorEqual.value = false;
			showErrorMandatory.value = false;
			if (password.value.length === 0 || passwordRepeat.value.length === 0) {
				showErrorMandatory.value = true;
				return false;
			}

			if (password.value !== passwordRepeat.value) {
				showErrorEqual.value = true;
				return false;
			}

			return true;
		}

		/** Triggered on change of password fields */
		const onChangeField = () => {
			if (showMessage.value && arePasswordsValid()) {
				showErrorEqual.value = false;
			}
		}

		/** Triggered on click of send */
		const onClickSend = () => {
			if (!arePasswordsValid()) {
				showMessage.value = true;
				return;
			}

			emit('onClickSend', password.value);
		}

		/**
		 * Triggered on keypress
		 * @param event
		 */
		const onKeyDown = (event: {key: string}) => {
			if (event.key === "Enter" && !showErrorEqual.value) {
				emit('onClickSend', password.value);
			}
		}

		return {password, passwordRepeat, showErrorEqual, showErrorMandatory,
			onChangeField, onClickSend, onKeyDown, arePasswordsValid};
	},
})
