
import {defineComponent, onMounted, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import LocatorList from "@/apps/administration/components/locators/LocatorList.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import {DocumentLocator, LocatorType} from "@dex/squeeze-client-ts";
import router from "@/router";
import {Clients, ConsoleLogger, LocatorConfig, LocatorExporter} from "@dex/squeeze-configframework";
import {copyToClipboard, downloadData} from "@/util/Export";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
	name: "LocatorsListView",
	methods: {copyToClipboard, downloadData},
	components: {
		ProgressBar,
		LocatorList,
		EntryDialog,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** Service for getting the locator-data */
		const locatorService = ClientManager.getInstance().squeeze.locator;

		/** List of all locators **/
		const locators = ref<DocumentLocator[]>([]);

		/** List of all locator types **/
		const locatorTypes = ref<LocatorType[]>([]);

		/** Is the page currently loading its data? */
		const loading = ref<boolean>(false);

		/** Exported all locator data as string  */
		const allLocatorData = ref<string>('');

		/** Is Export Dialog visible? */
		const showExportDialog = ref<boolean>(false);

		/** Is the Loading Process Bar visible? */
		const showProcessBar = ref<boolean>(false);

		const getLocators = () => {
			loading.value = true

			const handleErr = (msg: string, err: any) => ToastManager.showError(toast, t('Squeeze.General.Error'), t(msg) + ": " + err);

			const promiseAllLocators = locatorService.getAllLocators()
				.then(data => locators.value = data)
				.catch(response => response.json().then ((err: { message: string }) => {
					handleErr('Squeeze.Locators.ErrorLoadLocators', err.message)
				}))

			const promiseLocatorTypes = locatorService.getLocatorTypes()
				.then((data: LocatorType[]) => locatorTypes.value = data)
				.catch(response => response.json().then ((err: { message: string }) => {
					handleErr('Squeeze.Locators.ErrorLoadLocatorTypes', err.message)
				}))

			// Wait until promises are finished
			Promise.all([promiseAllLocators, promiseLocatorTypes]).then(() => loading.value = false);
		}

		/** Opens the LocatorSubView-Component */
		const openEntryDialog = () => {
			router.push({ name: 'LocatorSubView', params: { locatorId: 0}})
		}

		/**
		 * Deletes an entry
		 * @param rowData
		 */
		const deleteEntry = (rowData: DocumentLocator) => {
			if (!rowData) {
				return;
			}
			loading.value = true;
			locatorService.deleteLocatorById(rowData.id!)
				.then(() => {
					getLocators();
				}).catch((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.ErrorDelete'), t('Squeeze.General.DeleteError') + ": " + err.message);
				}).finally(() => {
					loading.value = false;
				});
		}

		/** Run export of all locators */
		const runExportOfAllLocators = async (exporter: LocatorExporter) => {
			showExportDialog.value = true;
			showProcessBar.value = true;
			loading.value = true;
			allLocatorData.value = "";

			try {
				const data: LocatorConfig[] = await exporter.run()
					.finally(() => {
						showProcessBar.value = false;
					});
				allLocatorData.value = JSON.stringify({locators: data}, null, 2);
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		}

		/** Export all locators */
		const exportAllLocators = async () => {
			/** Api-Clients */
			const clients: Clients = {
				documentClass: ClientManager.getInstance().squeeze.documentClass,
				masterData: ClientManager.getInstance().squeeze.masterData,
				locator: locatorService,
			};

			const locatorExporter = new LocatorExporter(clients, new ConsoleLogger());
			await runExportOfAllLocators(locatorExporter);
		}

		onMounted(() =>{
			getLocators();
		})

		return {
			t,
			toast,
			locators,
			locatorTypes,
			loading,
			allLocatorData,
			showExportDialog,
			showProcessBar,
			getLocators,
			openEntryDialog,
			deleteEntry,
			runExportOfAllLocators,
			exportAllLocators,
		};
	},
});

