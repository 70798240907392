
import {defineComponent, onBeforeMount, onMounted, PropType, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {DocumentField, DocumentTable, FormTrainingItemRegionAnchor} from "@dex/squeeze-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import FormPositionTrainingTable from "@/apps/squeeze/components/FormPositionTrainingTable.vue";

export default defineComponent({
	name: "FormTrainingItemIndicator",
	components: {
		InputText,
		Dropdown,
		Button,
		FormPositionTrainingTable,
	},
	props: {
		trainingKeyField: {
			type: Object as PropType<DocumentField>,
			default: () => ({}),
		},
		documentClassId: {
			type: Number,
			required: true,
		},
		tables: {
			type: Array as PropType<DocumentTable[]>,
			default: () => [],
		},
		indicatorOfFormTraining: {
			type: Object as PropType<FormTrainingItemRegionAnchor>,
			default: () => ({}),
		},
		booleanOptions: {
			type: Array as PropType<any[]>,
			default: () => [],
		},
	},
	emits: [
		'onFocusField',
		'onMarkRegion',
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Current training key field */
		const trainingKey = reactive<DocumentField>({});

		/** Current indicator */
		const indicator = reactive<FormTrainingItemRegionAnchor>({
			id: undefined,
			tableId: undefined,
			description: '',
			keyWordPattern: '',
			keyWordRegion: {
				page: 0,
				x0: 0,
				y0: 0,
				x1: 0,
				y1: 0,
			},
			ignoreWhiteSpaces: false,
		});

		/** Show Loading on load data */
		const loading = ref(false);

		/** All indicators */
		const allIndicators = ref<FormTrainingItemRegionAnchor[]>([]);

		/** Form Training API endpoint */
		const formTrainingApi = ClientManager.getInstance().squeeze.formTraining;

		/**
		 * Triggered when a field is focused
		 * @param {string} fieldName
		 */
		const onFocusField = (fieldName: string) => {
			emit("onFocusField", fieldName);
		}

		/** Reset indicator */
		const resetIndicator = () => {
			Object.assign(indicator, {
				id: undefined,
				tableId: undefined,
				description: '',
				keyWordPattern: '',
				keyWordRegion: {
					page: 0,
					x0: 0,
					y0: 0,
					x1: 0,
					y1: 0,
				},
				ignoreWhiteSpaces: false,
			});
		}

		/** Get all indicators */
		const getIndicators = () => {
			if (!trainingKey.value!.value) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Viewer.Error.NoCreditorField'));
				return;
			}

			loading.value = true;

			let promise;
			if (indicator.tableId) {
				promise = formTrainingApi.getFormTableItemIndicator(props.documentClassId, trainingKey.value!.value!, indicator.tableId);
			} else {
				promise = formTrainingApi.getFormTableItemIndicator(props.documentClassId, trainingKey.value!.value!);
			}
			promise
				.then(data => {
					allIndicators.value = data;
				}).catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/** Train or retrain an indicator */
		const trainIndicator = () => {
			loading.value = true;

			let promise;
			if (indicator.id) {
				promise = formTrainingApi.retrainFormTableItemIndicator(props.documentClassId, indicator.id, indicator, true);
			} else {
				promise = formTrainingApi.trainFormTableItemIndicator(props.documentClassId, indicator.tableId!, trainingKey.value!.value!, indicator, false);
			}

			promise
				.then(() => {
					getIndicators();
					resetIndicator();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Change an Indicator
		 * @param data
		 */
		const changeIndicator = (data: any) => {
			Object.assign(indicator, data);
		}

		/**
		 * Deletes an Indicator
		 * @param data
		 */
		const deleteIndicator = (data: any) => {
			loading.value = true;
			formTrainingApi.deleteFormTableItemIndicator(props.documentClassId, data.id)
				.then(() => {
					getIndicators();
					resetIndicator();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				});
		}

		/**
		 * Set marker regions by clicking a row
		 * @param event
		 */
		const onMarkRegion = (event: {originalEvent: PointerEvent; data: any; index: number}) => {
			const row = event.data;
			emit("onMarkRegion", [row.keyWordRegion]);
		}

		onMounted(() => {
			Object.assign(indicator, props.indicatorOfFormTraining);
			getIndicators();
			resetIndicator();
		})

		onBeforeMount(() => {
			Object.assign(trainingKey, props.trainingKeyField);
		})

		watch(() => props.indicatorOfFormTraining.keyWordPattern, () => {
			indicator.keyWordPattern = props.indicatorOfFormTraining.keyWordPattern;
			indicator.keyWordRegion = props.indicatorOfFormTraining.keyWordRegion;
		})

		return {
			t,
			toast,
			trainingKey,
			indicator,
			loading,
			allIndicators,
			onFocusField,
			getIndicators,
			trainIndicator,
			changeIndicator,
			deleteIndicator,
			onMarkRegion,
		}
	},
});
