import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5957a20f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "p-grid p-component nested-grid",
  style: {"justify-content":"center"}
}
const _hoisted_2 = {
  id: "left",
  class: "p-col-6"
}
const _hoisted_3 = { class: "p-grid p-flex-column" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "document-overlay" }
const _hoisted_7 = {
  id: "right",
  class: "p-col-6"
}
const _hoisted_8 = { class: "p-grid p-flex-column" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "document-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_BlockUI = _resolveComponent("BlockUI")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
          default: _withCtx(() => [
            _createVNode(_component_draggable, _mergeProps({
              class: "p-col group",
              modelValue: _ctx.thumbnails,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.thumbnails) = $event)),
              group: "images",
              itemKey: "name",
              key: "name",
              tag: "transition-group"
            }, _ctx.dragOptions, {
              "component-data": {
							tag: 'div',
							type: 'transition-group',
							name: !_ctx.drag ? 'flip-list' : null
						},
              onStart: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drag = true)),
              onEnd: _ctx.onDragEnd
            }), {
              item: _withCtx(({ element }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["document-card p-my-2 p-shadow-2", {
									'chosen': element.clicked,
									'dragging': _ctx.drag
								}]),
                  onClick: ($event: any) => (_ctx.clickThumbnail($event, element))
                }, [
                  _createElementVNode("img", {
                    class: "thumbnail",
                    src: 'data:image/png;base64, ' + element.model.base64,
                    alt: element.model.fileName
                  }, null, 8, _hoisted_5),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(element.model.id), 1)
                ], 10, _hoisted_4)
              ]),
              header: _withCtx(() => [
                _withDirectives(_createVNode(_component_Button, {
                  disabled: _ctx.thumbnails.filter(thumbnail => thumbnail.clicked).length === 0,
                  style: {"width":"70%"},
                  class: "p-button-md p-shadow-2 p-mb-4",
                  loading: _ctx.loading,
                  badge: _ctx.selectedThumbnails,
                  icon: "mdi mdi-skip-forward",
                  onClick: _ctx.movePages
                }, null, 8, ["disabled", "loading", "badge", "onClick"]), [
                  [_directive_tooltip, _ctx.$t('Squeeze.Validation.Dialogs.SplitDocument.Move')]
                ])
              ]),
              _: 1
            }, 16, ["modelValue", "component-data", "onEnd"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_BlockUI, { blocked: _ctx.loading }, {
          default: _withCtx(() => [
            _createVNode(_component_draggable, _mergeProps({
              class: "p-col group",
              modelValue: _ctx.listSelected,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.listSelected) = $event)),
              group: "images",
              itemKey: "name",
              key: "name",
              tag: "transition-group"
            }, _ctx.dragOptions, {
              "component-data": {
							tag: 'div',
							type: 'transition-group',
							name: !_ctx.drag ? 'flip-list' : null
						},
              onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = true)),
              onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drag = false))
            }), {
              item: _withCtx(({ element }) => [
                _createElementVNode("div", {
                  class: _normalizeClass(["document-card p-my-2 p-shadow-2", {
									'chosen': element.clicked,
									'dragging': _ctx.drag && element.clicked
								}]),
                  onClick: ($event: any) => (_ctx.clickThumbnail($event, element))
                }, [
                  _createElementVNode("img", {
                    class: "thumbnail",
                    src: 'data:image/png;base64, ' + element.model.base64,
                    alt: element.model.fileName
                  }, null, 8, _hoisted_10),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(element.model.id), 1)
                ], 10, _hoisted_9)
              ]),
              header: _withCtx(() => [
                _withDirectives(_createVNode(_component_Button, {
                  disabled: _ctx.listSelected.length === 0,
                  style: {"width":"70%"},
                  loading: _ctx.loading,
                  class: "p-button-md p-shadow-2 p-mb-4",
                  icon: "mdi mdi-file-move-outline",
                  onClick: _ctx.splitDocument
                }, null, 8, ["disabled", "loading", "onClick"]), [
                  [_directive_tooltip, _ctx.$t('Squeeze.Validation.Dialogs.SplitDocument.SplitSites')]
                ])
              ]),
              _: 1
            }, 16, ["modelValue", "component-data"])
          ]),
          _: 1
        }, 8, ["blocked"])
      ])
    ])
  ]))
}