
import { defineComponent } from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import OCRWords from "@/apps/squeeze/components/OCRWords.vue";
import OCRLines from "@/apps/squeeze/components/OCRLines.vue";

export default defineComponent({
	name: 'OcrView',
	components: {
		TabView,
		TabPanel,
		OCRWords,
		OCRLines,
	},
	props: {
		documentId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		'onMarkLines',
		'onMarkWord',
	],

	setup(props, {emit}) {
		/**
		 * Click a word to mark
		 * @param event
		 */
		const onMarkWord = (event: any) => {
			emit("onMarkWord", event);
		}

		/**
		 * Click a lines to mark
		 * @param event
		 */
		const onMarkLines = (event: any) => {
			emit("onMarkLines", event);
		}

		return {
			onMarkWord, onMarkLines,
		};
	},
});

