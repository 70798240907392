
import {computed, defineComponent, onMounted, PropType, reactive, watch} from "vue";
import InputText from "primevue/inputtext";
import {DocumentFieldGroup} from "@dex/squeeze-client-ts";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Dropdown from "primevue/dropdown";
import {useI18n} from "vue-i18n";
import {technicalValidation} from "@/util/ValidationHelper";

interface FieldGroupsTypes {
	id: number | undefined;
	description: string;
}

export default defineComponent({
	name: "FieldFormFieldGroups",
	components: {
		InputText, Dropdown,
	},
	props: {
		fieldEntry: {
			type: Object as PropType<DocumentFieldGroup>,
			default: () => ({}),
		},
		showErrorMessage: {
			type: Boolean,
		},
	},
	emits: ['update'],
	setup(props, {emit}) {
		const {t} = useI18n();

		/** Valid field-group types. Have to be set in created, otherwise i18n is not initialized */
		const fieldGroupsTypes = computed(() => {
			return [
				{
					id: 0,
					description: t('Squeeze.DocumentClasses.Head'),
				},
				{
					id: 1,
					description: t('Squeeze.DocumentClasses.Position'),
				},
			] as FieldGroupsTypes[]
		});

		/** Entry-Data of the field-group */
		const value = reactive<DocumentFieldGroup>({name: '', description: '', type: 0});

		/** Determines the required rules for validation */
		const rules = {
			name: { required, technicalValidation },
			description: { required },
		}

		/** Use Vuelidate */
		const v$ = useVuelidate(rules, value);

		onMounted(async () => {
			Object.assign(value, props.fieldEntry);

			// Emit Validated-Values onMounted. Otherwise there can be validation-errors when editing multiple entries
			await v$.value.$validate();
			emit("update", value, v$.value.$invalid);
		});

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(props.fieldEntry, () => {
			Object.assign(value, props.fieldEntry);
		})

		/** Triggered when input-field is changed */
		const update = () => {
			emit("update", value, v$.value.$invalid);
		}

		return {
			fieldGroupsTypes,
			value,
			props,
			v$,
			InputText,
			update,
		}
	},
});
