
/* eslint max-lines: off */
import {defineComponent, nextTick, onMounted, PropType, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import QueueEntryList from "@/apps/squeeze/components/QueueEntryList.vue";
import QueueTimeline from "@/apps/squeeze/components/QueueTimeline.vue";
import DocumentList from "@/apps/squeeze/components/DocumentList.vue";
import Skeleton from "primevue/skeleton";
import EntryDialog from "@/components/EntryDialog.vue";
import SearchForm from "@/apps/squeeze/components/SearchForm.vue";
import {Document, DocumentSearchFilterWidget, DocumentSearchRequestDto, ErrorDto, FieldValueFilterDto, PaginationDto, QueueEntry, WorkflowContext, WorkflowContextFilterDto} from "@dex/squeeze-client-ts";
import {defaultSort} from "@/util/SortingHelper";
import useSqueezeStore from "@/apps/squeeze/store";
import {ListDocument, ListDocumentField} from "@/apps/squeeze/interfaces/ListDocument";
import {FieldColumn} from "@/apps/squeeze/interfaces/FieldColumn";
import {DocumentFilterObject, DocumentSortObject, Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {TableSettings} from "@/util/TableSettings";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {RouteLocationRaw, useRoute} from "vue-router";
import router from "@/router";

export interface CustomSearchDto {
	id?: string;
	description: string;
	documentClass: number;
	search: DocumentSearchRequestDto;
	type: string;
	tile: string;
}

export interface SearchFormDto {
	actionType: string;
	description: string;
	searchId: number;
	global: boolean;
}

export default defineComponent({
	name: "DocumentListView",
	components: {
		QueueEntryList,
		QueueTimeline,
		DocumentList,
		Skeleton,
		EntryDialog,
		SearchForm,
	},
	props: {
		/** ID of document class */
		documentClassId: {
			type: Number,
			required: true,
		},
		/** Search request for document search filtering */
		searchRequest: {
			type: Object as PropType<DocumentSearchRequestDto>,
			default: () => ({}),
		},
		/** Defines the sort order of the table */
		tableSortStart: {
			type: Array as PropType<string[]>,
			default: () => [defaultSort],
		},
		/** Current Pagination prop from route */
		pagination: {
			type: Object as PropType<PaginationDto>,
			required: true,
		},
		isUploadShown: {
			type: Boolean,
			default: false,
		},
		/** Currently active search */
		searchId: {
			type: Number,
			default: 0,
		},
	},
	emits: ["onRowSelect"],
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const route = useRoute();

		/** Vuex Store */
		const store = useSqueezeStore();

		/** Context of the List ('validation' or 'search' */
		const workflowContext = ref<string>('');

		/** Name of document class */
		const documentClassName =ref<string>("");

		/** Documents to list */
		const documents = ref<ListDocument[]>([]);

		/** List of fields as columns array for datatable */
		const fieldColumns = ref<FieldColumn[]>([]);

		/** List of all QueueEntries in QueueStep Validation */
		const queueEntries = ref<QueueEntry[]>([]);

		/** List with all saves searches */
		const allSearches = ref<DocumentSearchFilterWidget[]>([]);

		/** Search request for document search filtering */
		const searchFilterRequest = reactive<DocumentSearchRequestDto>({});

		/** Indicates end of request */
		const loaded = ref<boolean>(false);

		/** Loading for the entry dialog */
		const loading = ref<boolean>(false);

		/** Indicates end of mounted */
		const initialLoaded = ref<boolean>(false);

		/** Indicates that queue entries are loaded instead of searching for documents via the ES search endpoint */
		const usingQueueEntryFallback = ref<boolean>(false);

		/** Has the table been fully loaded at least once? */
		const elasticLoadedSuccess = ref<boolean>(false);

		/** Should  the Dialog for Search Saving be shown? */
		const showSaveSearch = ref<boolean>(false);

		/** Should  the Dialog for Search Selecting be shown? */
		const showSelectSearch = ref<boolean>(false);

		/** Currently-Selected row */
		const selection = reactive<any>(null);

		/** Workflow Filter Standard for validation */
		const standardWorkflowFilter = ref<any[]>([
			{
				fieldName: "queueStep",
				comp: "eq",
				searchValue: "validation",
				fieldType: "text",
			},
		]);

		/** Info / Options of pagination */
		const paginationOption = reactive<PaginationDto>({
			pageSize: 25,
			page: 0,
			total: 0,
		});

		/** Filters of Document list */
		const filters = reactive<DocumentFilterObject>({
			"id": {value: null, matchMode: FilterMatchMode.EQUALS},
			"queueStatus": {value: null, matchMode: FilterMatchMode.EQUALS},
			"queueStep": {value: null, matchMode: FilterMatchMode.EQUALS},
			"queueErrorText": {value: null, matchMode: FilterMatchMode.EQUALS},
			"queueCreateTs": {value: null, matchMode: FilterMatchMode.EQUALS},
		});

		/** Defines the sort order of the table */
		const tableSort = ref<string[]>([
			defaultSort,
		]);

		/** Currently fulltext-string */
		const fullText = ref<string>('');

		/** Search Init */
		const searchSave = reactive<SearchFormDto>({
			description: '',
			actionType: 'new',
			searchId: 0,
			global: false,
		});

		/** Triggered the valid of form */
		const isInvalid = ref<boolean>(true);
		const showErrorMessage = ref<boolean>(false);

		/** User API endpoint */
		const userApi = ClientManager.getInstance().squeeze.user;

		/** Search API endpoint */
		const searchApi = ClientManager.getInstance().squeeze.search;

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Queue API endpoint */
		const queueApi = ClientManager.getInstance().squeeze.queue;

		/** DocumentClass API endpoint */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/**
		 * Triggered when a row is selected in documentList
		 * @param {Document} document
		 */
		const onRowSelectDocumentList = (document: Document) => {
			const route: RouteLocationRaw = {
				name: 'ValidateEntry',
				params: {
					documentId: document.id as any,
					searchRequest: JSON.stringify(searchFilterRequest),
					pagination: JSON.stringify(paginationOption),
					tableSortStart: JSON.stringify(tableSort.value),
					searchId: props.searchId,
				}};
			router.push(route);
		}

		/**
		 * Triggered when a row is selected in queueEntryList
		 * @param {QueueEntry} document
		 */
		const onRowSelectQueueEntryList = (document: QueueEntry) => {
			const route: RouteLocationRaw = {
				name: 'ValidateEntry',
				params: {
					documentId: document.documentId as any,
					searchRequest: JSON.stringify(searchFilterRequest),
					pagination: JSON.stringify(paginationOption),
					tableSortStart: JSON.stringify(tableSort.value),
					searchId: props.searchId,
				}};
			router.push(route);
		}

		/** Encode query element for router */
		const encodeFilterQuery = () => {
			const query: {[key: string]: string} = {};
			if (searchFilterRequest.workflowContextFilters && searchFilterRequest.workflowContextFilters.length > 0) {
				query.wf = encodeURI(JSON.stringify(searchFilterRequest.workflowContextFilters));
			}

			if (searchFilterRequest.fieldFilters && searchFilterRequest.fieldFilters.length > 0) {
				query.f = encodeURI(JSON.stringify(searchFilterRequest.fieldFilters));
			}

			return query;
		}

		/** Sets available filters of search request for Vue to update */
		const setDataTableFilter = () => {
			const searchRequestFilters: {[type: string]: FieldValueFilterDto[] | WorkflowContextFilterDto[] | undefined} = {
				"fields": searchFilterRequest.fieldFilters,
				"wf": searchFilterRequest.workflowContextFilters,
			};

			for(const filterType in searchRequestFilters) {
				const isField = filterType === "fields";
				const requestFilters = searchRequestFilters[filterType];
				if (requestFilters) {
					// TODO: TELL TYPESCRIPT TYPE OF FILTER
					requestFilters.forEach((filter: any) => {
						const id = isField ? '' + filter.fieldId : filter.fieldName;
						const listFilter = {value: filter.searchValue, matchMode: FilterMatchMode.CONTAINS};

						if(!isField && filter.fieldName === "queueStatus") {
							listFilter.value = listFilter.value.toUpperCase();
						}

						switch (filter.comp) {
						case "eq": listFilter.matchMode = FilterMatchMode.EQUALS; break;
						case "bw": listFilter.matchMode = FilterMatchMode.STARTS_WITH; break;
						case "ew": listFilter.matchMode = FilterMatchMode.ENDS_WITH; break;
						default: break;
						}

						filters[id] = listFilter;
					});
				}
			}

			let routeName = "DocumentList";

			if (route.name === "DocumentListValidation" || route.name === "DocumentListValidationWithSearch") {
				routeName = "DocumentListValidationWithSearch";
			}

			// SET FILTER QUERY
			router.replace({name: routeName, params: {
				documentClassId: props.documentClassId,
				searchRequest: encodeURIComponent(JSON.stringify(searchFilterRequest)),
				searchId: props.searchId,
			}});
		}

		/** Loads all queue entries of a specified document class in queue step "Validation" */
		const loadQueueEntries = async () => {
			const result = await queueApi.getQueueStepEntries("Validation", undefined, undefined, undefined, undefined, undefined, props.documentClassId, undefined, undefined, undefined, undefined, paginationOption.page, paginationOption.pageSize)
			const entries = result.elements;
			Object.assign(paginationOption,result.pagination);

			if (entries && entries.length > 0) {
				queueEntries.value = entries;
			} else {
				throw new Error("Missing entries in documents class!")
			}
		}

		/**
		 * Checks if an operator is a valid elastic operator
		 * @param {string} searchValue
		 */
		const containsValidElasticOperator = (searchValue: string) => {
			return searchValue.includes("=") || searchValue.includes("<") || searchValue.includes(">") || searchValue.includes("*") || searchValue.includes('"');
		}

		/** Loads all documents of a specified document class in queue step */
		const loadDocuments = async () => {
			searchFilterRequest.fieldFilters?.forEach(filter => {
				// If the field is of type "amount"/"date" or the search value already includes <, >, = or *, nothing should be added
				if (filter.fieldType !== "amount" && filter.fieldType !== "date" && !containsValidElasticOperator(filter.searchValue)) {
					filter.searchValue = "*" + filter.searchValue + "*";
				}
			})

			searchFilterRequest.workflowContextFilters?.forEach(filter => {
				if (filter.fieldName === "queueErrorText") {
					if (filter.fieldType !== "amount" && filter.fieldType !== "date" && !containsValidElasticOperator(filter.searchValue)) {
						filter.searchValue = "*" + filter.searchValue + "*";
					}
				}
			})

			searchFilterRequest.fulltextFilters = [];
			if (fullText.value) {
				if (!containsValidElasticOperator(fullText.value)) {
					fullText.value = "*" + fullText.value + "*";
				}

				searchFilterRequest.fulltextFilters.push({
					searchValue: fullText.value,
					comp: 'raw',
				})
			}

			const result = await documentApi.searchDocuments(props.documentClassId, searchFilterRequest, 'index', paginationOption.page, paginationOption.pageSize, tableSort.value);
			const resultDocuments = result.elements as Document[];
			const pagination = result.pagination;
			if (pagination) Object.assign(paginationOption, pagination);
			documents.value = [];

			resultDocuments.forEach((document: any) => {
				const workflowContext: WorkflowContext = {
					step: document.queueStep,
					status: document.queueStatus.toUpperCase(),
					errorText: document.queueErrorText,
				}

				const listDocument: ListDocument = {
					id: document.id as any,
					createdAt: document.queueCreateTs,
					workflowContext: workflowContext,
					fields: {},
					exports: [],
				};

				if (document.exportStates) {
					listDocument.exports = document.exportStates;
				}

				// Map fields to current document-class
				for (const key in document.fields) {
					const field = document.fields[key];
					const column = fieldColumns.value.find(field => field.id === Number(key));

					if (column && column.id) {
						const value = field.value.text;

						const listField: ListDocumentField = {
							id: column.id,
							name: column.name,
							value: value,
							dataType: column.type,
						};
						listDocument.fields[key] = listField;
					}
				}

				documents.value.push(listDocument);
			});

			setDataTableFilter();
		}

		/** Reloads QueueStepEntries for step Validation */
		const reloadData = async () => {
			loaded.value = false;

			// Load documents
			try {
				await loadDocuments();
				usingQueueEntryFallback.value = false;
				elasticLoadedSuccess.value = true;
			} catch (err) {
				// Only use fallback on first load. Otherwise it's all fine
				if (elasticLoadedSuccess.value === true) {
					err.json().then ((err: any) => {
						ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + t('Squeeze.Queue.General.SearchInvalid'));
						//ToastManager.showError(this.$toast, this.$t('Squeeze.General.Error'), this.$t('Squeeze.General.Error') + ": " + err.message);
					})
				} else{
					ToastManager.showInfo(toast, t('Toasts.Title.Info'), t('Squeeze.Queue.QueueEntryFallback.Info'));
					// todo: Send to sentry
					try {
						usingQueueEntryFallback.value = true;
						await loadQueueEntries();
					} catch (err) {
						ToastManager.showError(toast, t('Squeeze.General.Error'), String(err));
					}
				}
			}

			loaded.value = true;
		}

		/**
		 * Triggered when page is changed
		 * @param event
		 */
		const onChangePage = (event: { rows: number }) => {
			TableSettings.saveTableListPagination(t, toast, store, userApi, "DocumentListView", event.rows);
		}

		/**
		 * Triggered when the next page should be loaded
		 * @param event
		 */
		const onPage = (event: { page: number; rows: number; first: number }) => {
			// Set current page
			paginationOption.page = event.page;
			paginationOption.pageSize = event.rows;

			// If there are more than 10.000 entries, show an error
			if (event.first >= 10000 || (event.first + event.rows >= 10000)) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.Queue.General.MaxNumberReached'));
				nextTick(() => {
					paginationOption.page = 0;
					reloadData();
				})
				return;
			}

			reloadData();
			onChangePage(event);
		}

		/**
		 * Triggered on sort of the table (with multi-sort activated)
		 * @param {DocumentSortObject[]} sortColumns
		 */
		const onSort = async (sortColumns: DocumentSortObject[]) => {
			tableSort.value = [defaultSort];

			// if there is no sort, use default sort
			if (sortColumns.length === 0) {
				await reloadData();
				return;
			}

			const sortArray = sortColumns.map(sortColumn => {
				const sortOrder = sortColumn.order === -1 ? "desc": "asc";

				// Special handling for sorting special fields
				if (sortColumn.field === "id") {
					return "id:" + sortOrder;
				}
				else if (sortColumn.field === "create_ts") {
					return "queueCreateTs:" + sortOrder;
				}
				else if (sortColumn.field === "errorText") {
					return "queueErrorText:" + sortOrder;
				}
				else if (sortColumn.field == "status") {
					return "queueStatus:" + sortOrder;
				}

				// Get Column id for sort
				const column = fieldColumns.value.find(column => column.name === sortColumn.field);
				if (column) {
					return 'fields.' + column.id + '.value.' + column.type.toLowerCase() + ':' + sortOrder;
				}
			})

			if (sortArray) {
				tableSort.value = sortArray as string[];
			}

			await reloadData();
		}

		/**
		 * Get compare operator
		 * @param {string} filterMatchMode
		 */
		const getCompareOperator = (filterMatchMode: string) => {
			let comp = filterMatchMode;
			switch(filterMatchMode) {
			// We currently ignore all comparators and always use "raw". This results in the backend sending our
			// search request as-is to Elasticsearch (except for minimal escaping) and therefore resulting in better search results.

			// case FilterMatchMode.CONTAINS: comp = "like"; break;
			// case FilterMatchMode.STARTS_WITH: comp = "bw"; break;
			// case FilterMatchMode.ENDS_WITH: comp = "ew"; break;
			// case FilterMatchMode.EQUALS: comp = "eq"; break;
			default: comp = "raw"; break;
			}

			return comp;
		}

		/**
		 * Map field search value
		 * @param {string} fieldType
		 * @param value
		 */
		const mapFieldSearchValue = (fieldType: string, value: unknown) => {
			switch (fieldType.toLowerCase()) {
			case "amount":
				if (typeof value == "string") {
					return value.replace(/([.,])(?=\d{3})/g, '').replaceAll(",", ".") // todo: respect localization
				} else {
					return value;
				}
			default:
				return value;
			}
		}

		/**
		 * Triggered when a filter has been entered
		 * @param {DocumentFilterObject} tableFilters
		 */
		const onFilter = async (tableFilters: DocumentFilterObject) => {
			searchFilterRequest.fulltextFilters = [];
			searchFilterRequest.fieldFilters = [];
			searchFilterRequest.workflowContextFilters = standardWorkflowFilter.value;

			Object.assign(filters, tableFilters);
			const fieldFilters: FieldValueFilterDto[] = [];
			const workflowContextFilter: WorkflowContextFilterDto[] = [];

			for (const id in tableFilters) {
				const filterId = Number(id);
				const isField = !isNaN(filterId);

				const filter: Filter = tableFilters[id];
				if (filter && filter.value != null && filter.value != "") {
					paginationOption.page = 0;

					const comp = getCompareOperator(filter.matchMode!);

					if (comp != "") {
						if (!isField) {
							let fieldTypeContextFilter = 'text';
							if (id === "queueCreateTs") {
								fieldTypeContextFilter = 'date';
							}
							// WF CONTEXT
							workflowContextFilter.push({
								fieldName: id,
								searchValue: filter.value.toLowerCase(),
								comp: comp as string,
								fieldType: fieldTypeContextFilter,
							});
						} else {
							// FIELD
							const column = fieldColumns.value.find((column) => {
								return column.id && column.id === filterId;
							});

							if (column) {
								fieldFilters.push({
									fieldId: filterId,
									searchValue: mapFieldSearchValue(column.type, filter.value) as string,
									comp: (comp as unknown) as FieldValueFilterDto.CompEnum,
									fieldType: column.type.toLowerCase(),
								})
							}
						}
					}
				}
			}

			searchFilterRequest.fieldFilters = fieldFilters;
			searchFilterRequest.workflowContextFilters = workflowContextFilter;
			await reloadData();
		}

		/**
		 * Load searches
		 * @param {boolean} showFirstElement
		 */
		const loadSearches = (showFirstElement: boolean = false) => {
			// Do not load tiles if feature is disabled
			if (!store.state.featureSet.savedDocumentSearches) {
				return;
			}

			searchApi.getSearchWidgets().then((data) => {
				allSearches.value = data;
				if (route.name === "DocumentListValidation" || route.name === "DocumentListValidationWithSearch") {
					allSearches.value = allSearches.value.filter(search => search.relatedSubject === "validation" && search.documentClassId === props.documentClassId);
				} else {
					allSearches.value = allSearches.value.filter(search => search.relatedSubject !== "validation"  && search.documentClassId === props.documentClassId);
				}

				// Sort by description
				allSearches.value.sort((a,b) => {
					if (a.description! > b.description!) {
						return 1;
					}
					return -1;
				});

				if (showFirstElement) {
					const firstEntry = allSearches.value[0];
					if (firstEntry) {
						searchSave.searchId = firstEntry.id!
						searchSave.description = firstEntry.description!;
						searchSave.global = firstEntry.global!;
					} else {
						searchSave.global = false;
						searchSave.actionType = 'new';
						searchSave.description = '';
						isInvalid.value = true;
					}
				}
			}).catch(response => response.json().then ((err: ErrorDto) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			}))
		}

		/**
		 * Save search
		 * @param {boolean} keepDialogOpen
		 */
		const saveSearch = (keepDialogOpen: boolean) => {
			if (isInvalid.value) {
				showErrorMessage.value = true;
				return;
			}

			isInvalid.value = false;
			showErrorMessage.value = false;
			//let fullSearchSearch: any = {};

			const body: DocumentSearchFilterWidget = {
				searchRequestJson: JSON.stringify(searchFilterRequest),
				documentClassId: props.documentClassId,
				description: searchSave.description,
				global: searchSave.global,
			}

			// Always set global to false, if user is not admin
			if (!store.state.isAdminUser) {
				body.global = false;
			}

			if (route.name === "DocumentListValidation" || route.name === "DocumentListValidationWithSearch") {
				body.relatedSubject = "validation";
			} else {
				body.relatedSubject = "other";
			}

			loading.value = true;
			if (searchSave.actionType === 'new') {
				searchApi.createSearchWidget(body).then(() => {
					showSaveSearch.value = false;
				}).catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadSearches();
					loading.value = false;
				})
			} else if (searchSave.actionType === 'change') {
				body.id = searchSave.searchId;
				searchApi.updateSearchWidget(searchSave.searchId, body).then(() => {
					showSaveSearch.value = false;
				}).catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadSearches();
					loading.value = false;
				})
			} else if (searchSave.actionType === 'delete') {
				searchApi.deleteSearchWidget(searchSave.searchId).then(() => {
					if (!keepDialogOpen) {
						showSaveSearch.value = false;
					}
				}).catch(response => response.json().then ((err: ErrorDto) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadSearches(keepDialogOpen);
					loading.value = false;
				})
			}
		}

		/**
		 * Triggered on update of attribute-form
		 * @param data
		 * @param {boolean} invalid
		 */
		const onUpdate = (data: any, invalid: boolean) => {
			isInvalid.value = invalid;
			Object.assign(searchSave, data);
		}

		/** Clears the current search */
		const clearSearch = async () => {
			fieldColumns.value.forEach(field => {
				filters["" + field.id] = {value: null, matchMode: "raw"};
			})

			filters["id"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			filters["queueStatus"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			filters["queueStep"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			filters["queueCreateTs"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			filters["queueErrorText"] = {value: null, matchMode: FilterMatchMode.EQUALS};
			fullText.value = "";
			searchFilterRequest.fulltextFilters = [];
			searchFilterRequest.fieldFilters = [];

			if (route.name !== "DocumentListValidation" && route.name !== "DocumentListValidationWithSearch") {
				searchFilterRequest.workflowContextFilters = [];
			} else {
				searchFilterRequest.workflowContextFilters = standardWorkflowFilter.value;
			}

			await reloadData();
		}

		/** Triggers a search */
		const triggerSearch = async () => {
			showSelectSearch.value = false;
			const search = allSearches.value.find(searchEntry => searchEntry.id === searchSave.searchId);
			if (search) {
				loaded.value = false;
				let routeName = "DocumentList";

				if (route.name === "DocumentListValidation" || route.name === "DocumentListValidationWithSearch") {
					routeName = "DocumentListValidationWithSearch";
				}

				await router.replace({name: routeName, params: {
					documentClassId: props.documentClassId,
					searchRequest: encodeURIComponent(search.searchRequestJson as string),
					searchId: search.id!,
				}});
				fieldColumns.value.forEach(field => {
					filters["" + field.id] = {value: null, matchMode: "raw"};
				})

				filters["id"] = {value: null, matchMode: FilterMatchMode.EQUALS};
				filters["queueStatus"] = {value: null, matchMode: FilterMatchMode.EQUALS};
				filters["queueStep"] = {value: null, matchMode: FilterMatchMode.EQUALS};
				filters["queueErrorText"] = {value: null, matchMode: FilterMatchMode.EQUALS};
				filters["createdAt"] = {value: null, matchMode: FilterMatchMode.EQUALS};

				// set the search filter
				Object.assign(searchFilterRequest, JSON.parse(search.searchRequestJson as string));

				if (searchFilterRequest && searchFilterRequest.fulltextFilters && searchFilterRequest.fulltextFilters[0]) {
					const fullTextFilter = searchFilterRequest.fulltextFilters[0];
					if (fullTextFilter && fullTextFilter.searchValue) {
						fullText.value = fullTextFilter.searchValue;
					}
					else {
						fullText.value = "";
					}
				} else {
					fullText.value = "";
				}

				await reloadData();
			}
		}

		/**
		 * Triggered when a search ist selected
		 * @param {SearchFormDto} data
		 */
		const onSearchSelect = (data: SearchFormDto) => {
			Object.assign(searchSave, data);
			triggerSearch();
		}

		/** Opens the Search */
		const openSaveSearch = () => {
			showSaveSearch.value = true;
			searchSave.global = false;
			searchSave.actionType = 'new';
			searchSave.description = '';
			if (props.searchId !== 0) {
				const savedSearch = allSearches.value.find(search => search.id === props.searchId);

				if (savedSearch) {
					// Wenn eine Suche persönlich ist, darf diese immer editiert werden. Wenn sie global ist, dann nur
					// von Admin-Nutzern
					if (!savedSearch.global ||(savedSearch.global && store.state.isAdminUser)) {
						searchSave.actionType = "change";
						searchSave.searchId = props.searchId;
						searchSave.description = savedSearch.description!;
						searchSave.global = savedSearch.global!;
					}
				}
			}
		}

		/** On view ready */
		onMounted(async () => {
			// Initialize pagination info / options
			if (props.pagination) {
				paginationOption.pageSize = TableSettings.getTableListPagination(store, "DocumentListView");
				paginationOption.page = props.pagination.page;
				paginationOption.total = props.pagination.total;
			}
			tableSort.value = props.tableSortStart;

			// load fields of the documentClasses
			await documentClassApi.getAllDocumentClassFields(props.documentClassId)
				.then(fields => {
					// Sort by fieldgroup
					fields.sort((field1, field2) => {
						if (field1!.fieldGroupId! > field2!.fieldGroupId!) {
							return 1;
						}
						if (field1!.fieldGroupId! < field2!.fieldGroupId!) {
							return -1;
						}
						return 0;
					})

					fields.forEach(field => {
						if (field.hidden !== true) {
							fieldColumns.value.push({
								id: field.id,
								name: field.name as string,
								header: field.description as string,
								type: field.dataType as string,
							});
							filters['' + field.id] = {value: null, matchMode: "raw"};
						}
					});
				})
				.catch(reason => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), reason);
				})

			Object.assign(searchFilterRequest, props.searchRequest)
			if (searchFilterRequest && searchFilterRequest.fulltextFilters && searchFilterRequest.fulltextFilters[0]) {
				const fullTextFilter = searchFilterRequest.fulltextFilters[0];
				if (fullTextFilter && fullTextFilter.searchValue) {
					fullText.value = fullTextFilter.searchValue;
					tableSort.value = [""];
				}
			}

			await reloadData();
			loadSearches();
			initialLoaded.value = true;
		})

		return {
			t,
			toast,
			store,
			workflowContext,
			documentClassName,
			documents,
			fieldColumns,
			queueEntries,
			allSearches,
			searchFilterRequest,
			loaded,
			loading,
			initialLoaded,
			usingQueueEntryFallback,
			elasticLoadedSuccess,
			showSaveSearch,
			showSelectSearch,
			selection,
			standardWorkflowFilter,
			paginationOption,
			filters,
			tableSort,
			fullText,
			searchSave,
			isInvalid,
			showErrorMessage,
			onRowSelectDocumentList,
			onRowSelectQueueEntryList,
			encodeFilterQuery,
			setDataTableFilter,
			loadQueueEntries,
			containsValidElasticOperator,
			loadDocuments,
			reloadData,
			onChangePage,
			onPage,
			onSort,
			getCompareOperator,
			mapFieldSearchValue,
			onFilter,
			loadSearches,
			saveSearch,
			onUpdate,
			clearSearch,
			triggerSearch,
			onSearchSelect,
			openSaveSearch,
		};
	},
});

