
import {computed, defineComponent, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import MasterDataColumns from "@/apps/administration/components/masterdata/tabs/MasterDataColumns.vue";
import {MasterDataColumn} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import MasterDataColumnsForm from "@/apps/administration/components/masterdata/tabs/MasterDataColumnsForm.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import BlockUI from 'primevue/blockui';

export default defineComponent({
	name: "MasterDataColumnsView",
	components: {
		MasterDataColumns, DialogDelete, MasterDataColumnsForm, EntryDialog, BlockUI,
	},
	props: {
		masterDataTableId: {
			type: Number,
			default: 0,
			required: true,
		},
		permissions: {
			type: Object,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Component of the current form */
		const masterDataColumnForm = ref<InstanceType<typeof MasterDataColumnsForm>>();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Column invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the locator-data */
		const masterDataService = ClientManager.getInstance().squeeze.masterData;

		/** List of all Columns */
		const masterDataColumns = ref<MasterDataColumn[]>([]);

		/** Should the Entry-Dialog for Columns be shown? */
		const showDialog = ref<boolean>(false);

		/** Should the reset dialog be shown? */
		const showResetDialog = ref<boolean>(false);

		/** Should the loading be shown in the Reset dialog? */
		const loadingResetDialog = ref<boolean>(false);

		/** Currently active Column */
		const masterDataColumn = reactive<MasterDataColumn>({
			name: '',
			description: '',
			sourceColumn: '',
		})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Is Substitution changed? */
		const changeMasterDataColumn = ref<boolean>(false);

		/** Triggered on update of attribute-form */
		const onUpdate = (data: MasterDataColumn, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(masterDataColumn, data)
		}

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (masterDataColumn.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.MasterData.Column') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.MasterData.Column') });
		});

		/**
		 * Triggered when an entry should be shown
		 * @param row
		 */
		const onEntrySelect = (row: MasterDataColumn) => {
			Object.assign(masterDataColumn, row);
			showDialog.value = true;
		}

		/**
		 * Reloads the table
		 */
		const reloadData = async () => {
			loading.value = true;
			await masterDataService.getMasterDataTableColumns(props.masterDataTableId)
				.then(data => {
					masterDataColumns.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Get Type of current locator on load
		 */
		onMounted(() =>{
			reloadData();
		})

		/** Saves a column */
		const saveMasterDataColumn = (keepDialogOpen: boolean = false, event: any = null) => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}

			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			let promise = null;

			if (!masterDataColumn.id) {
				promise = masterDataService.postMasterDataTableColumn(props.masterDataTableId, masterDataColumn);
			} else {
				promise = masterDataService.putMasterDataTableColumn(props.masterDataTableId, masterDataColumn.id, masterDataColumn);
			}

			promise.then(async () => {
				if (!keepDialogOpen) {
					showDialog.value = false;
				} else {
					// Empty values and focus first input-field
					masterDataColumn.name = '';
					masterDataColumn.description = '';
					masterDataColumn.sourceColumn = "";
					masterDataColumnForm.value?.$el.querySelector('input').focus()
					isValidationInvalid.value = true;
				}

				await reloadData();
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				loadingDialog.value = false;
				loading.value = false;
				changeMasterDataColumn.value = false;

				if (event) {
					// Notify any listeners in case they need to wait for the completion of this request
					document.dispatchEvent(new CustomEvent('reloadDone', { detail: event }));
				}
			})
		}

		/**
		 * Opens the Delete Dialog
		 * @param row Row to delete
		 */
		const openDeleteDialog = (row: MasterDataColumn) => {
			deleteDialog.value = true;
			Object.assign(masterDataColumn, row);
		}

		/**
		 * Deletes a Column
		 */
		const deleteEntry = () => {
			if (masterDataColumn && masterDataColumn.id) {
				loading.value = true;

				masterDataService.deleteMasterDataTableColumn(props.masterDataTableId, masterDataColumn.id).then(() => {
					reloadData();
				}).catch(response => response.json().then ((err: any) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/** Triggers the reset of the table */
		const resetTable = () => {
			loadingResetDialog.value = true;
			if (props.masterDataTableId !== 0) {
				masterDataService.putMasterDataTableSchema(props.masterDataTableId).then(() => {
					showResetDialog.value = false;
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.MasterData.ResetSuccess'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingResetDialog.value = false;
				})
			}
		}

		/**
		 * Triggered when an input field changed
		 * @param event
		 * @param isInvalid
		 */
		const onChangeInputField = (event: any, isInvalid: boolean) => {
			changeMasterDataColumn.value = true;
			isValidationInvalid.value = isInvalid;
			Object.assign(masterDataColumn, event.newData);
			saveMasterDataColumn(false, event);
		}

		return {
			loading,
			isValidationInvalid,
			showValidationMessage,
			loadingDialog,
			showDialog,
			headerText,
			masterDataColumn,
			masterDataColumns,
			deleteDialog,
			showResetDialog,
			loadingResetDialog,
			changeMasterDataColumn,
			deleteEntry,
			openDeleteDialog,
			onUpdate,
			onEntrySelect,
			saveMasterDataColumn,
			resetTable,
			onChangeInputField,
		};
	},
});

