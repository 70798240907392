
import {
	computed,
	defineComponent, onMounted, reactive, ref
} from 'vue';
import {useI18n} from "vue-i18n";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useToast} from "primevue/usetoast";
import {DocumentClass, RoleFilter} from "@dex/squeeze-client-ts";
import DialogDelete from "@/components/DialogDelete.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import Dropdown from "primevue/dropdown";
import BlockUI from 'primevue/blockui';
import FilterListForm from "@/apps/administration/components/roles/FilterListForm.vue";

export interface RoleFilterWithClassIndex extends RoleFilter{
	documentClassIndex?: number;
}

export default defineComponent({
	name: "FilterListView",
	components: {
		DialogDelete, EntryDialog, Dropdown, FilterListForm, BlockUI,
	},
	props: {
		roleId: {
			type: Number,
			default: 0,
			required: true,
		},
	},
	emits: [
		"onListChange",
	],
	setup(props, {emit}) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading in table? */
		const loading = ref(false);

		/** Show Loading on Save */
		const loadingDialog = ref(false);

		/** Is the Form of the Value invalid? */
		const isValidationInvalid = ref(true);

		/** Show error validation-messages in form? */
		const showValidationMessage = ref(false);

		/** Service for getting the role-data */
		const roleService = ClientManager.getInstance().squeeze.role;

		/** Service for getting the all documentClasses */
		const documentClassService = ClientManager.getInstance().squeeze.documentClass;

		/** Should the Entry-Dialog for value be shown? */
		const showDialog = ref<boolean>(false);

		/** If of the currently active value-list item */
		const filterListEntryDropdown = ref<RoleFilter>({});

		/** All values from value-list */
		const fullFilterList = ref<RoleFilter[]>([]);

		/** Currently active value-list item */
		const filterListEntry = reactive<RoleFilterWithClassIndex>({})

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** Array with the document classes */
		const documentClasses = ref<DocumentClass[]>([]);

		/** Text of the header in Entry-Dialog */
		const headerText = computed(() => {
			if (filterListEntry.id) {
				return t('Squeeze.General.ChangeEntry', { entryName: t('Squeeze.UserManagement.RuleFilter') });
			}

			return t('Squeeze.General.CreateEntry', { entryName: t('Squeeze.UserManagement.RuleFilter') });
		});

		/** Trigged on update of value list attribute */
		const onUpdate = (data: RoleFilter, isInvalid: boolean) => {
			isValidationInvalid.value = isInvalid;
			Object.assign(filterListEntry, data)
		}

		/** Reloads the table */
		const reloadData = () => {
			loading.value = true;
			roleService.getRoleFilters(props.roleId)
				.then(data => {
					if (data[0] && !filterListEntryDropdown.value.id) {
						filterListEntryDropdown.value = data[0];
					}

					data = data.sort((a, b) => a.documentClassId! < b.documentClassId! ? -1 : a.documentClassId! > b.documentClassId! ? 1 : 0);
					const classData = data as RoleFilterWithClassIndex[];

					// show index within documentclass-entries
					let documentClassIndex = 1;
					let currentDocumentClass = 0;
					classData.forEach(filter => {
						if (currentDocumentClass !== filter.documentClassId) {
							documentClassIndex = 1;
						}

						filter.documentClassIndex = documentClassIndex;
						currentDocumentClass = filter.documentClassId!;
						documentClassIndex++;
					})

					// Order by document-class
					fullFilterList.value = classData;
					emit("onListChange", filterListEntryDropdown.value);
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			loading.value = true;
			documentClassService.getAllDocumentClasses()
				.then(data => {
					documentClasses.value = data;
				})
				.catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		onMounted(async () =>{
			await getAllDocumentClasses();
			await reloadData();
		})

		/** Initializes the values for a new entry */
		const openNewEntry = () => {
			filterListEntry.id = undefined;
			filterListEntry.documentClassId = undefined;
			filterListEntry.fieldConditions = [];
			showDialog.value = true;
		}

		/** Saves a value */
		const saveValueListItem = () => {
			if (isValidationInvalid.value) {
				showValidationMessage.value = true;
				return;
			}
			showValidationMessage.value = false;
			loadingDialog.value = true;
			loading.value = true;

			if (!filterListEntry.id) {
				roleService.postRoleFilter(props.roleId, filterListEntry).then(() => {
					showDialog.value = false;
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loadingDialog.value = false;
					loading.value = false;
				})
			}
		}

		/**
		 * Triggered on change of attribute-form
		 * @param row
		 * @param isInvalid
		 */
		const onChangeValueList = () => {
			emit("onListChange", filterListEntryDropdown.value);
		}

		/** Deletes a value-list-entry */
		const deleteEntry = () => {
			if (filterListEntryDropdown.value.id) {
				loading.value = true;
				roleService.deleteRoleFilter(props.roleId, filterListEntryDropdown.value.id).then(() => {
					filterListEntryDropdown.value = {};
					reloadData();
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
			}
		}

		/**
		 * Gets the Description of a Document Class
		 * @param documentClassId
		 */
		const getDocumentClassDescription = (documentClassToFind: RoleFilterWithClassIndex): string => {
			const documentClass = documentClasses.value.find(documentClass => documentClass.id === documentClassToFind.documentClassId);

			//const index = fullFilterList.value.findIndex(filter => filter.id === documentClassToFind.id);

			if (documentClass) {
				return documentClass.description + " (" + (documentClassToFind.documentClassIndex + ")");
			}

			return String(documentClassToFind.documentClassId);
		}

		/**
		 * Gets the Document Classes that are not already in the list
		 * @param allDocumentClasses
		 */
		const filterDocumentClasses = (allDocumentClasses: DocumentClass[]) => {
			return allDocumentClasses;
		}

		return {loading, isValidationInvalid, showValidationMessage, loadingDialog, showDialog,
			headerText, deleteDialog, filterListEntry, fullFilterList, filterListEntryDropdown,
			documentClasses, filterDocumentClasses,
			openNewEntry, onUpdate, getDocumentClassDescription,
			deleteEntry, onChangeValueList, saveValueListItem};
	},
});

