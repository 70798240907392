
import {defineComponent, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ToastManager} from "@/util/ToastManager";
import {ClientManager} from "@/singletons/ClientManager";
import {ServerTime} from "@dex/squeeze-client-ts";
import Message from "primevue/message";

export default defineComponent({
	name: "ServerTimeMessage",
	components: {
		Message,
	},
	setup() {
		const {t} = useI18n();
		const toast = useToast();

		/** System API endpoint */
		const systemApi = ClientManager.getInstance().squeeze.system;

		/** Current server time */
		const serverTime = reactive<ServerTime>({
			date: '',
			time: '',
			timezone: '',
		});

		/** Interval handle for polling */
		const pollingInterval = ref<number|undefined>(undefined);

		/** Get current server time */
		const getServerTime = () => {
			systemApi.getServerTime()
				.then((data: ServerTime) => {
					Object.assign(serverTime, data);
				}).catch(response => response.json().then ((error: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + error.message);
				}))
		}

		onMounted(() => {
			getServerTime();
			pollingInterval.value = setInterval(getServerTime, 100000, true);
		})

		/** Is triggered before a Component unmounts */
		onBeforeUnmount(() => {
			// Clear timer handler to stop polling
			clearInterval(pollingInterval.value);
		})

		return {
			t,
			toast,
			serverTime,
			pollingInterval,
			getServerTime,
		};
	},
});

