
import {defineComponent, onMounted, reactive, ref} from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from "primevue/usetoast";
import BlockUI from 'primevue/blockui';
import ProcessTimeline from "@/apps/system-administration/components/ProcessTimeline.vue";
import ProcessItemAdminUser from "@/apps/system-administration/components/processItems/ProcessItemAdminUser.vue";
import ProcessItemTemplates from "@/apps/system-administration/components/processItems/ProcessItemTemplates.vue";
import ProcessItemSummary from "@/apps/system-administration/components/processItems/ProcessItemSummary.vue";
import router from "@/router";
import {useConfirm} from "primevue/useconfirm";
import {CreateTenantRequest} from "@dex/squeeze-system-client-ts";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";

interface ProcessItem {
	status: string;
	icon: string;
	active: boolean;
}

export interface TemplateItem {
	key: string;
	label: string;
	checked: boolean;
	children?: TemplateItem[];
	type?: string;
}

export default defineComponent({
	name: "ClientProcessView",
	components: {
		BlockUI,
		ProcessItemSummary,
		ProcessItemTemplates,
		ProcessItemAdminUser,
		ProcessTimeline,
	},
	props: {
		tenant: {
			type: String,
		},
		tenantExists: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();
		const confirm = useConfirm();

		/** Tenants API */
		const tenantApi = ClientManager.getInstance().squeezeSystem.tenants;

		/** Is loading? */
		const loading = ref<boolean>(false);

		/** Triggered when (all) field values are invalid */
		//const showErrorMessage = ref<boolean>(false);

		/** Triggered the valid of process forms */
		const isValidationInvalid = ref<boolean>(true);

		/** Array with all process items */
		const processItems = ref<ProcessItem[]>([
			{status: 'Admin-User', icon: 'mdi mdi-numeric-1', active: true},
			{status: 'Templates', icon: 'mdi mdi-numeric-2', active: false},
			{status: 'Summary', icon: 'mdi mdi-numeric-3', active: false},
		]);

		/** All data to create a tenant */
		const tenantSetup = reactive<CreateTenantRequest>({
			tenant: '',
			solutions: {
				invoice: {
					install: true,
					testDocument: true,
				},
			},
			user: {
				email: '',
				login: '',
				password: '',
				firstName: '',
				lastName: '',
			},
		})

		/** All AdminUser data */
		const adminUser = reactive<any>({
			login: '',
			firstname: '',
			lastname: '',
			email: '',
			password: '',
			passwordRepeat: '',
		});

		/** Array with templates */
		const allTemplates = ref<any[]>([]);

		onMounted(() => {
			tenantSetup.tenant = props.tenant as string;
		})

		/** Create the tenant */
		const createTenant = () => {
			loading.value = true;
			tenantApi.tenantCreate(tenantSetup)
				.then(() => {
					ToastManager.showSuccess(toast, t("System-Administration.Clients.SuccessCreateClient"));
					window.location.replace(window.location.protocol + '//' + tenantSetup.tenant + ':' + window.location.port + '/ui/app/login');
				})
				.catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
				.finally(() => {
					loading.value = false;
				})
		}

		/**
		 * Triggered on click to go to the previous OR next processItem
		 * @param {number} itemIndex index of previous OR next current item
		 */
		const goToPreviousOrNextStep = (itemIndex: number) => {
			if (itemIndex >= processItems.value.length) {
				createTenant();
			} else {
				processItems.value.forEach((item: ProcessItem) => {
					item.active = false;
					processItems.value[itemIndex].active = true;
				})
			}
		}

		/** Cancel the Process */
		const onCancelProcess = () => {
			confirm.require({
				message: t('System-Administration.General.AbortConfirm'),
				header: t('System-Administration.General.Confirmation'),
				icon: 'mdi mdi-alert-outline',
				acceptLabel: t('System-Administration.General.Yes'),
				rejectLabel: t('System-Administration.General.No'),
				accept: () => {
					router.push({ name: 'SetupFormView'});
				},
			});
		}

		/**
		 * Triggered on update the client
		 * @param data Data of AdminUser
		 * @param isInvalid
		 */
		const onUpdateAdminUser = (data: any, isInvalid: boolean) => {
			Object.assign(adminUser, data);
			tenantSetup.user.email = data.email;
			tenantSetup.user.login = data.email; // set email as login
			tenantSetup.user.password = data.password;
			tenantSetup.user.firstName = data.firstname;
			tenantSetup.user.lastName = data.lastname;
			isValidationInvalid.value = isInvalid;
		}

		/**
		 * Triggered on update the templates
		 * @param templates Data of Templates
		 */
		const onUpdateTemplates = (templates: any[]) => {
			allTemplates.value = templates;
			templates.forEach((template: any) => {
				switch (template) {
				case template.key === '0' && template.checked:
					tenantSetup.solutions!.invoice!.install = template.checked;
					tenantSetup.solutions!.invoice!.testDocument = template.children[0].checked;
					break;
				}
			})
		}

		return {
			t,
			toast,
			loading,
			isValidationInvalid,
			processItems,
			tenantSetup,
			adminUser,
			allTemplates,
			createTenant,
			goToPreviousOrNextStep,
			onCancelProcess,
			onUpdateAdminUser,
			onUpdateTemplates,
		}
	},
})
