
import Card from 'primevue/card';
import Tooltip from "primevue/tooltip";
import useSqueezeStore from "@/apps/squeeze/store";
import {defineComponent, onMounted, ref, watch} from "vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {defaultSort} from "@/util/SortingHelper";

export default defineComponent({
	name: "QueueNavigatorCard",
	components: {
		Card,
	},
	props: {
		className: {
			type: String,
			required: true,
		},
		currentCount: {
			type: Number,
			default: -1,
		},
		errorCount: {
			type: Number,
			default: -1,
		},
		docClassId: {
			type: Number,
			required: false,
		},
		searchRequestJson: {
			type: String,
			required: false,
		},
	},
	directives: {
		'tooltip': Tooltip,
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Store */
		const store = useSqueezeStore();

		/** Current count of documents in a tile */
		const count = ref<number>(-1);

		/** Document API endpoint */
		const documentApi = ClientManager.getInstance().squeeze.document;

		/** Check global fontSize to fix the margin-top of counts
		 * Because we use the card, the count cannot be displayed in any other way and the font size must be observed.
		 */
		const checkFontSize = () => {
			const currentFontSize = store.state.userSettings.viewSettings.fontSize;
			if (currentFontSize === 0.7) {
				document.documentElement.style.setProperty('--element-margin-top', '-9rem');
			} else if (currentFontSize === 0.725) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.75rem');
			} else if (currentFontSize === 0.75) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.5rem');
			} else if (currentFontSize === 0.775) {
				document.documentElement.style.setProperty('--element-margin-top', '-8.25rem');
			} else if (currentFontSize === 0.8) {
				document.documentElement.style.setProperty('--element-margin-top', '-8rem');
			} else if (currentFontSize === 0.825) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.75rem');
			} else if (currentFontSize === 0.85) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.5rem');
			} else if (currentFontSize === 0.875) {
				document.documentElement.style.setProperty('--element-margin-top', '-7.25rem');
			} else {
				document.documentElement.style.setProperty('--element-margin-top', '-7rem');
			}
		}

		checkFontSize();

		/** Watch prop at set value object, because props are not allowed to be mutated */
		watch(() => store.state.userSettings.viewSettings.fontSize, () => {
			checkFontSize();
		})

		/**
		 * Get current count of documents in a search tile
		 * @param docClassId Current doc id of a tile
		 * @param searchRequest Current search request of a tile
		 */
		const getCurrentCount = (docClassId: number, searchRequest: any) => {
			documentApi.searchDocuments(docClassId, JSON.parse(searchRequest), 'index', 0, 25, [defaultSort])
				.then(result => {
					if (result && result.pagination.total) {
						count.value = result.pagination.total;
					}
					else if (result && result.elements && result.elements.length > 0) {
						count.value = result.elements.length;
					}
				}).catch((response: any) => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
				}))
		}

		onMounted(() => {
			// check if docClassId and searchRequestJson is not undefined
			if (props.docClassId && props.searchRequestJson) {
				getCurrentCount(props.docClassId, props.searchRequestJson);
				return;
			}

			// check if currentCount exists
			if (props.currentCount) {
				count.value = props.currentCount;
				return;
			} else {
				// set default
				count.value = -1;
			}
		})

		return {
			count,
		}
	},
});

/*
@Options({

	watch: {
		'store.state.userSettings.viewSettings.fontSize': function() {
			this.checkFontSize();
		},
	},
})*/

