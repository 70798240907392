import {ClientManager} from "@/singletons/ClientManager";
import {Licenses} from "@/apis/dexp/Types";

/**
 * LicenceClient is a crude HTTP client used to communicate with the DEXP license service.
 */
export class LicenseClient {

	/** Defines the default route to Freeze */
	public licenceUrl: string = "";

	public constructor() {
		this.licenceUrl = "https://public-02.freeze.one/apis/licenseservice/v0/licenses";
	}

	/**
	 * Gets all EAS Stores
	 */
	public getAllLicences = async (): Promise<Licenses> => {
		const response = await ClientManager.getInstance().licenceFetch(this.licenceUrl)

		if ((response.status !== 200 && response.status !== 204)) {
			const responseJSON = await response.text();
			throw responseJSON;
		}

		return await response.json();
	}

	private static instance: LicenseClient;

	public static getInstance() {
		if(this.instance == null) {
			this.instance = new LicenseClient();
		}

		return this.instance;
	}

}
