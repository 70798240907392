
import {computed, defineComponent, PropType, ref} from 'vue';
import InputText from "primevue/inputtext";
import {Filter} from "@/apps/squeeze/interfaces/DocumentSearch";
import {FilterMatchMode} from "primevue/api";
import {DocumentSearchRequestDto, FulltextFilterDto} from "@dex/squeeze-client-ts";
import {FieldValueFilterDto} from "@dex/squeeze-client-ts";

/**
 * Defines a field that can be searched in
 */
interface SearchField {
	id: number;
	name: string;
	type: string;
}

/**
 * Defines a search mask that can be displayed
 */
export interface SearchMask {
	id: number;
	fields: Array<SearchField>;
}

type SearchRequest = DocumentSearchRequestDto;

export default defineComponent({
	name: 'DocumentSearchMask',
	props: {
		searchMask: {
			type: Object as PropType<SearchMask>,
			required: true,
		},
		maxFieldHeight: {
			type: String,
			required: false,
			default: "",
		},
	},
	components: {
		InputText,
	},
	emits: [
		"submit",
	],

	setup(props, {emit}) {
		/** Fulltext string */
		const fullText = ref('');

		const fields = computed(() => {
			return props.searchMask.fields.map(field => {
				const filter: Filter = {
					value: "",
					matchMode: FilterMatchMode.CONTAINS,
				}
				return Object.assign({}, field, {filter});
			});
		})

		const searchRequest = computed<SearchRequest>(() => {
			const fullTextArray: FulltextFilterDto[] = [];
			if (fullText.value) {
				fullTextArray.push({
					searchValue: "*" + fullText.value + "*",
					comp: 'raw',
				})
			}
			return {
				workflowContextFilters: [],
				fulltextFilters: fullTextArray,
				fieldFilters: fields.value.map(field => {
					const target: FieldValueFilterDto = {
						fieldId: field.id,
						searchValue: field.filter.value,
						comp: "raw" as any, // FIXME: Using the proper enum here somehow breaks our ts build.
						fieldType: field.type.toLowerCase(),
					}
					return target;
				}).filter(f => f.searchValue !== ""),
			}
		});

		const submit = () => {
			emit("submit", searchRequest.value);
		}

		const canSubmit = computed(() => {
			return fields.value.length > 0;
		});

		return {
			fields,
			searchRequest,
			submit,
			canSubmit,
			fullText,
		}
	},
});

