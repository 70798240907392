
import { defineComponent } from 'vue';
import {Handle, HandleConnectableFunc, Position} from '@vue-flow/core';

export default defineComponent({
	name: 'DataNode',
	components: {
		Handle,
	},
	props: {
		label: {
			type: String,
		},
	},
	setup() {
		const handleConnectable: HandleConnectableFunc = (node, connectedEdges) => {
			// only allow connections if the node has less than one connection
			// check if the connection is not a tree connection
			connectedEdges = connectedEdges.filter(connectedEdge => connectedEdge.data.type !== "treeConnection");
			return connectedEdges.length < 1;
		}

		return {
			Position,
			handleConnectable,
		};
	},
});

