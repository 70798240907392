
import { defineComponent } from 'vue';
import DashboardTile from "@/components/DashboardTile.vue";
import DocumentReportExportedDocuments from "@/apps/squeeze/components/DocumentReportExportedDocuments.vue";
import DocumentReportExports from "@/apps/squeeze/components/DocumentReportExports.vue";
import DocumentReportOrigin from "@/apps/squeeze/components/DocumentReportOrigin.vue";
import DocumentReportProcessed from "@/apps/squeeze/components/DocumentReportProcessed.vue";
import DocumentReportAutovalidation from "@/apps/squeeze/components/DocumentReportAutovalidation.vue";
import useSqueezeStore from "@/apps/squeeze/store";
import DocumentReportFieldSelectionQuality from "@/apps/squeeze/components/DocumentReportFieldSelectionQuality.vue";

export default defineComponent({
	name: "Reports",
	components: {
		DocumentReportFieldSelectionQuality,
		DocumentReportAutovalidation,
		DocumentReportProcessed,
		DocumentReportOrigin,
		DocumentReportExports,
		DocumentReportExportedDocuments,
		DashboardTile,
	},
	setup() {
		/** Vuex Store */
		const store = useSqueezeStore();

		return {
			store,
		};
	},
});

