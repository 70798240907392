<script >
export default {
	props: {
		greeting: String,
	},
	data() {
		return {}
	},
}
</script>

<template>
	<div class="debug">
		<h1>{{ greeting }}</h1>
	</div>
</template>

<style scoped>
div.debug {
	margin: 1rem;
	padding: 1rem;
}
</style>
