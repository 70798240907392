
import {defineComponent, onBeforeMount, onMounted, reactive, ref} from 'vue';
import {useI18n} from "vue-i18n";
import {useToast} from "primevue/usetoast";
import {ClientManager} from "@/singletons/ClientManager";
import ButtonGroup from 'primevue/buttongroup';
import MultiSelect from 'primevue/multiselect';
import Calendar from "primevue/calendar";
import {Chart, ChartDatasetCustomTypesPerDataset, ChartTypeRegistry, TooltipItem} from 'chart.js';
import moment from "moment";
import {DocumentClass, SimpleReport} from "@dex/squeeze-client-ts";
import {ToastManager} from "@/util/ToastManager";
import {showDropdownOverlay} from "@/util/StylesHelper";
import {ColorGenerator} from "@/util/ColorGenerator";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';

export default defineComponent({
	name: "DocumentReportAutovalidation",
	methods: {showDropdownOverlay},
	components: {
		IconField,
		InputIcon,
		InputText,
		Calendar,
		MultiSelect,
		ButtonGroup,
	},
	setup() {
		const {t, locale} = useI18n();
		const toast = useToast();

		/** List of all DocumentClasses */
		const allDocumentClasses = ref<DocumentClass[]>([]);

		/** Currently selected Document-Classes by ID */
		const selectedDocumentClasses = ref<number[]>([]);

		/** Filter for creditor by creditor number */
		const creditorIds = ref<string>('');

		/** Selected date */
		const selectedDate = ref<string[]>([]);

		/** Chart data */
		const chartData = reactive<any>({});

		/** Chart labels */
		const chartLabels = ref<string[]>([]);

		/** Date placeholder */
		const datePlaceholder = ref<string>('');

		/** Current color of client */
		const currentClientColor = ref<string>('');

		/** All Chart data*/
		const allReportData = reactive<SimpleReport>({});

		/** Service for Document Class API */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** Service for report API */
		const reportApi = ClientManager.getInstance().squeeze.report;

		/**
		 * Get tooltip label for chart
		 * @param context
		 */
		const getTooltipLabel = (context: TooltipItem<keyof ChartTypeRegistry>) => {
			if (context.dataIndex === 0) {
				return context.parsed.y + ' / ' + allReportData.validPercentage + '%';
			} else if (context.dataIndex === 1) {
				return context.parsed.y + ' / ' + allReportData.invalidPercentage + '%';
			}
			return String(context.parsed.y);
		}

		/** Get current chart datasets */
		const createData = () => {
			const data = [];
			let tempData = {};
			const dataEntries = Object.entries(chartData.value);
			const hexToHslColor = ColorGenerator.hexToHSL(currentClientColor.value);
			const hslColors = ColorGenerator.generateHslColors(dataEntries.length, hexToHslColor);

			let colorIndex = 0;
			for (const [key, value] of dataEntries) {
				const color = hslColors[colorIndex];
				colorIndex++;

				const dataFromChartOrigin: any = {value}.value;
				tempData = {
					label: {key}.key,
					data: dataFromChartOrigin[0]['data'],
					borderWidth: 3,
					borderColor: color,
					backgroundColor: color,
				}
				data.push(tempData);
			}
			return data;
		}

		/** Create the chart */
		const createChart = () => {
			const canvas = document.getElementById('autovalidationReportChart') as HTMLCanvasElement;
			const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
			currentClientColor.value = window.getComputedStyle(canvas).getPropertyValue('--dex-secondary-dark-color');

			Chart.defaults.plugins.tooltip.callbacks.label = (tooltipItem: TooltipItem<keyof ChartTypeRegistry>) => getTooltipLabel(tooltipItem);

			new Chart(ctx, {
				type: 'bar',
				data: {
					labels: chartLabels.value,
					datasets: createData() as ChartDatasetCustomTypesPerDataset[],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true,
						},
					},
				},
			});
		}

		/**
		 * Get Chart Data
		 * @param startDate
		 * @param endDate
		 */
		const getChartData = async (startDate: moment.Moment, endDate: moment.Moment) => {
			try {
				datePlaceholder.value = startDate.format('DD.MM.YYYY') + ' - ' + endDate.format('DD.MM.YYYY');
				const reportData = await reportApi.getSimpleExtractionReport(
					selectedDocumentClasses.value.toString(),
					creditorIds.value,
					startDate.format(),
					endDate.format()
				);

				// Set all report data
				Object.assign(allReportData, reportData);

				// Build labels for x axis
				chartLabels.value = [t('Squeeze.Reports.Autovalidation'), t('Squeeze.Reports.ManuallyCorrected')];

				// Build import data
				const dataLabelString: string = t('Squeeze.Reports.TotalValidations') +  ' ' + reportData.numberOfProcessedDocuments;
				chartData.value = {
					[dataLabelString]: [
						{
							"data": [
								reportData.numberOfValidDocuments,
								reportData.numberOfInvalidDocuments,
							],
						},
					],
				};

				// Build Chart
				const presentChart = Chart.getChart("autovalidationReportChart");
				if (presentChart) {
					presentChart.destroy();
					createChart();
				} else {
					createChart();
				}
			} catch (err: any) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), err);
			}
		}

		/** Clear filters */
		const clearFilters = () => {
			selectedDate.value = [];
			selectedDocumentClasses.value = [];
			creditorIds.value = '';
			getChartData(moment('01.01.', 'DD MM'), moment());
		}

		/** Triggered to filter */
		const filter = () => {
			if (selectedDate.value[1]) {
				getChartData(moment(selectedDate.value[0], 'LLLL'), moment(selectedDate.value[1], 'LLLL'));
			} else {
				getChartData(moment('01.01.', 'DD MM'), moment());
			}
		}

		/** Get all DocumentClasses */
		const getAllDocumentClasses = () => {
			documentClassApi.getAllDocumentClasses()
				.then((documentClasses) => {
					allDocumentClasses.value = documentClasses;
				})
				.catch((error: {message: string}) => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error.message);
				})
		}

		/**
		 * Triggered when the tooltip is shown
		 * @param values
		 */
		const mapTooltipValues = (values: string[]) => {
			return values
				.map((value: string) => allDocumentClasses.value.find(docClass => docClass.id === Number(value))?.name)
				.join(", ");
		}

		/**
		 * Get option label of selected document class
		 * @param options
		 */
		const getOptionLabel = (options: number[]) => {
			let label = '';
			options.forEach((option: number, index: number) => {
				if (index <= 2) {
					const currentDocClass = allDocumentClasses.value.find(docClass => docClass.id === option);
					if (currentDocClass) {
						label += currentDocClass.name;
						if (options.length > 1 && index < options.length - 1) {
							label += ', ';
						}
					}
				} else if (index === 3) {
					label += '...';
				}
			})

			return label;
		}

		onMounted(() => {
			getAllDocumentClasses();
		})

		onBeforeMount(async () =>{
			await getChartData(moment('01.01.', 'DD MM'), moment());
		})

		return {
			t,
			locale,
			toast,
			allDocumentClasses,
			selectedDocumentClasses,
			creditorIds,
			selectedDate,
			chartData,
			chartLabels,
			datePlaceholder,
			allReportData,
			clearFilters,
			filter,
			getTooltipLabel,
			mapTooltipValues,
			getOptionLabel,
		};
	},
});

